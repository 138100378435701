import React from "react";
import storage from "../../../../Storage";
import Engine from "../Engine";
import Avatar from "react-avatar";
import socket from "../../../../Socket";
import {encode} from "../../../../Helper";

class JackpotTopThree extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: storage.getKey('name'),
            token: storage.getKey('token'),
            jackpot_id: 1, //todo
            engine: Engine,
            loading: true,
            top_threes: []
        };
    }

    componentDidMount() {
        let {engine} = this.state;

        socket.emit('JACKPOT_TOP_3', encode({jackpot_id: this.state.jackpot_id}));
        socket.on('JACKPOT_TOP_3', data => this.jackpotTopThree(data));
    }

    jackpotTopThree(data) {
        console.log('[temp] jackpot top 3:', data);
        this.setState({loading: false, top_threes: (data)});
    }

    render() {
        const list = this.state.top_threes.map((player, i) =>
            <TopThree key={i} number={i + 1} player={player}/>
        );

        return (
            <>
                {
                    this.state.loading ?
                        <div className="text-center">
                            <div className="spinner-grow text-white my-3" role="status"/>
                        </div>
                        :
                        <table className="table margin-10 jackpot-winner-table font-12">
                            <tr>
                                <th className="text-left header-color" colSpan="3">Jackpot Top 3 Leader Board</th>
                            </tr>
                            <tr className="header-row text-white">
                                <th style={{width: '15%'}}>Rank</th>
                                <th>Player</th>
                                <th>Crash Point</th>
                            </tr>
                            {list}
                        </table>
                }
            </>
        );
    }
}

class TopThree extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {player, number} = this.props;

        let username = player.name;
        let avatar = player.avatar; //todo
        let cashout = player.cashout;

        return (
            <tr className="content-row">
                <td><Avatar name={number + 'Holder'} size='18' textSizeRatio='2' round style={{marginRight: "5px"}}/></td>
                <td className="header-color text-weight-300 font-11">
                    <Avatar name={username} size='18' textSizeRatio='2' round style={{marginRight: "5px"}}/>
                    {username}
                </td>
                <td>{cashout}</td>
            </tr>
        );
    }
}

export default JackpotTopThree;