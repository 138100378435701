import React, { Component } from "react";
import { Modal, Row, Col, Tabs, Tab } from "react-bootstrap";
import storage from "../../../../Storage";
import { sendNotfication, Event, isMobile, wait } from "../../../../Helper";
import Wallet from "../Wallet";
import { NavLink } from "react-router-dom";
import CancelIcon from "../../../../Static/images/cancel.svg";
import UserAvatar from "../../../../Static/images/Avatar/User-60.svg";
import General from "../../User/Setting/General";
import Security from "../../User/Setting/Security";
import LinearProgress from "@mui/material/LinearProgress";
import {
  ChatClick,
  NotifClicker,
  StatsClick,
  UpdateHomeIcon,
  VipClick,
  WalletClick,
} from "../ClickService";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import { showWarningToast } from "../../../../toastNotifications";

const label = { inputProps: { "aria-label": "Switch demo" } };

class PrivacySettings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      general: true,
      username: storage.getKey("name"),
      ghostMode: false,
      hideGamingData: false,
      hideProfile: false,
      hideOther: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleGhostModeChanged = this.handleGhostModeChanged.bind(this);
    this.handleGamingDataChange = this.handleGamingDataChange.bind(this);
    this.handleHideProfileChange = this.handleHideProfileChange.bind(this);
  }

  componentDidMount() {
    Event.on("show_user_account", this.handleShow);
  }

  handleClose() {
    this.setState({ show: false, effect: "delayAn zoomOut" });
    wait(500).then(() => {
      this.setState({ general: true });
    });
  }
  handleGhostModeChanged(e) {
    this.setState({
      ghostMode: !this.state.ghostMode,
      hideGamingData: !this.state.ghostMode,
      hideProfile: !this.state.ghostMode,
      hideOther: !this.state.ghostMode,
    });
  }
  handleShow() {
    if (storage.getKey("token") === null) {
      showWarningToast("Please Login to view your details");
      return;
    }

    this.setState({ show: true, effect: "delayAn pulse" });
    NotifClicker();
    StatsClick();
    WalletClick();
    ChatClick();
    VipClick();
    UpdateHomeIcon();
  }

  changePage = (e) => {
    this.setState({ general: !this.state.general });
  };
  handleGamingDataChange(e) {
    this.setState({ hideGamingData: !this.state.hideGamingData });
    wait(100).then(() => {
        this.handleGhostMode()
    });
  }
  handleHideProfileChange(e) {
    this.setState({ hideProfile: !this.state.hideProfile });
    wait(100).then(() => {
        this.handleGhostMode()
    });
  }
  handleHideOtherChange(e) {
    const state = !this.state.hideOther;
    this.setState({ hideOther: state });
    wait(100).then(() => {
        this.handleGhostMode()
    });
  }

  handleGhostMode(){
    const {hideOther,hideProfile,hideGamingData } = this.state;
    if(hideOther && hideGamingData && hideProfile ){
        this.setState({ ghostMode: true });
    }else{
        this.setState({ ghostMode: false });
    }
  }
  render() {
    let { t } = this.props;
    return (
      <>
        <Grid spacing={1} container className="top-1rem privacy-row">
          <Grid xs={10} item>
            <p className="padding-0 margin-0 text-gray font-700 font-11">
              Ghost Mode
            </p>
            <p className="padding-0 margin-0 text-gray font-400 font-10">
              Activate All
            </p>
          </Grid>
          <Grid xs={2}>
            <Switch
              {...label}
              checked={this.state.ghostMode}
              onChange={this.handleGhostModeChanged}
            />
          </Grid>
        </Grid>
        <Grid spacing={1} container className="top-1rem privacy-row">
          <Grid xs={10} item>
            <p className="padding-0 margin-0 text-gray font-700 font-11">
              Hide my gaming data on profile
            </p>
            <p className="padding-0 margin-0 text-gray font-400 font-10">
              Even if hidden, your avatar and username are always visible in
              chatrooms.
            </p>
          </Grid>
          <Grid xs={2}>
            <Switch
              {...label}
              checked={this.state.hideGamingData}
              onChange={(e) => this.handleGamingDataChange(e)}
            />
          </Grid>
        </Grid>
        <Grid spacing={1} container className="top-1rem privacy-row">
          <Grid xs={10} item>
            <p className="padding-0 margin-0 text-gray font-700 font-11">
              Hide my gaming data on profile
            </p>
            <p className="padding-0 margin-0 text-gray font-400 font-10">
              Even if hidden, your avatar and username are always visible in
              chatrooms.
            </p>
          </Grid>
          <Grid xs={2}>
            <Switch
              {...label}
              checked={this.state.hideProfile}
              onChange={(e) => this.handleHideProfileChange(e)}
            />
          </Grid>
        </Grid>
        <Grid spacing={1} container className="top-1rem privacy-row">
          <Grid xs={10} item>
            <p
              className="padding-0 margin-0 text-gray font-700 font-11"
              //  onChange={this.handleHideOtherChange}
            >
              Hide my gaming data on profile
            </p>
            <p className="padding-0 margin-0 text-gray font-400 font-10">
              Even if hidden, your avatar and username are always visible in
              chatrooms.
            </p>
          </Grid>
          <Grid xs={2}>
            <Switch
              {...label}
              onChange={(e) => this.handleHideOtherChange(e)}
              checked={this.state.hideOther}
            />
          </Grid>
        </Grid>
      </>
    );
  }
}

export default PrivacySettings;
