import React, { Component } from "react";
import { Modal, Tabs, Tab } from "react-bootstrap";
import storage from "../../../../Storage";
import { Event, wait, encode, decode } from "../../../../Helper";
import { NavLink } from "react-router-dom";
import LinearProgress from "@mui/material/LinearProgress";
import {
  ChatClick,
  NotifClicker,
  StatsClick,
  UpdateHomeIcon,
  UpdateHomeIconActive,
  VipClick,
  WalletClick,
} from "../ClickService";
import Grid from "@mui/material/Grid";
import AccountInfo from "./Account";
import PrivacySettings from "./Privacy";
import VerifyAccount from "./Verify";
import Avatar1 from "../../../../Static/images/avatars/avatar1.svg";
import Avatar2 from "../../../../Static/images/avatars/avatar2.svg";
import Avatar3 from "../../../../Static/images/avatars/avatar3.svg";
import Avatar4 from "../../../../Static/images/avatars/avatar4.svg";
import Avatar5 from "../../../../Static/images/avatars/avatar5.svg";
import Avatar6 from "../../../../Static/images/avatars/avatar6.svg";
import {
  showErrorToast,
  showSuccessToast,
  showWarningToast,
} from "../../../../toastNotifications";
import socket from "../../../../Socket";
import C from "../../../../Constant";

class UserAccount extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      general: true,
      username: storage.getKey("name"),
      accountDisabled: false,
      privacyDisabled: false,
      verifyDisabled: false,
      editAccount: false,
      currentPage: "home",
      avatar: Avatar2,
      token: storage.getKey("token"),
      loading: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggleAccount = this.toggleAccount.bind(this);
    this.togglePrivacy = this.togglePrivacy.bind(this);
    this.toggleVerify = this.togglePrivacy.bind(this);
    this.toggleEditAccount = this.toggleEditAccount.bind(this);
    this.toggleModalContent = this.toggleModalContent.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;
    Event.on("show_user_account", this.handleShow);
    socket.on(C.SAVE_AVATAR, (data) => this.avatarResponseHandler(data));
    socket.on(C.EDIT_ACCOUNT, (data) =>
      this.usernameResponseHandler(decode(data))
    );
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  avatarResponseHandler(data) {
    if (this._isMounted) {
      if (data.status === true) {
        storage.setKey("avatar", this.state.avatar);
        showSuccessToast("Avatar Updated Successfully", {});
      } else {
        showErrorToast(data.error ? data.error : "Error Updating avatar");
      }
      this.toggleModalContent('editName');
      this.setState({ loading: false });
    }
    return;
  }
  usernameResponseHandler(data) {
    if (this._isMounted) {
      if (data.status === true) {
        storage.setKey("name", this.state.username);
        showSuccessToast("Username Updated Successfully", {});
      } else {
        showErrorToast(data.error ? data.error : "Error Updating Username");
      }
      this.setState({ loading: false });
      this.toggleModalContent('editName');
    }
    return;
  }
  handleClose() {
    this.setState({ show: false, effect: "delayAn zoomOut" });
    UpdateHomeIconActive();
    wait(500).then(() => {
      this.setState({ general: true });
    });
  }

  handleShow() {
    if (storage.getKey("token") === null) {
      showWarningToast("Please Login to view your details");
      return;
    }

    this.setState({ show: true, effect: "delayAn pulse" });
    NotifClicker();
    StatsClick();
    WalletClick();
    ChatClick();
    VipClick();
    UpdateHomeIcon();
  }

  toggleAccount(status) {
    this.setState({ accountDisabled: status });
  }

  togglePrivacy(status) {
    this.setState({ privacyDisabled: status });
  }

  toggleVerify(status) {
    this.setState({ verifyDisabled: status });
  }

  toggleEditAccount() {
    this.setState({ editAccount: !this.state.editAccount });
  }

  toggleModalContent(page) {
    this.setState({ currentPage: page });
  }

  toggleAvatar(selectedAvatar) {
    this.setState({ avatar: selectedAvatar });
  }
  saveAvatar() {
    this.setState({ loading: true });
    wait(500).then(() => {
      socket.emit(
        C.SAVE_AVATAR,
        encode({
          token: this.state.token,
          avatar: this.state.avatar,
        })
      );
    });
  }
  changePage = (e) => {
    e.preventDefault();
    this.setState({ general: !this.state.general });
  };
  handleUsernameChange = (e) => {
    e.preventDefault();
    this.setState({ username: e.target.value });
  };
  updateUsername = (e) => {
    e.preventDefault();
    this.setState({ loading: true });
    wait(500).then(() => {
      socket.emit(
        C.EDIT_ACCOUNT,
        encode({
          token: this.state.token,
          username: this.state.username,
        })
      );
    });
  };

  render() {
    let { t } = this.props;
    let { accountDisabled, privacyDisabled, verifyDisabled, currentPage } =
      this.state;

    return (
      <>
        {!this.props.show && (
          <NavLink
            to={"#"}
            className="nav-link text-left header-color"
            onClick={(e) => this.handleShow(e)}
          >
            <i className={"text-white fa fa-cog"}></i>
            {"  Settings"}
          </NavLink>
        )}

        <Modal
          size="md"
          centered={true}
          backdrop={"static"}
          show={this.state.show}
          onHide={this.handleClose}
          onExited={this.handleClose}
          aria-labelledby="setting-lg-modal"
          className={"animated " + this.state.effect}
        >
          {currentPage === "home" && (
            <>
              <Modal.Header>
                <i className={"fa fa-cog bottom-menu-icon"} />
                &nbsp;&nbsp; Settings
                <button
                  type="button"
                  id="close-settings"
                  className="close p-2"
                  onClick={this.handleClose}
                >
                  <i className="fa fa-close text-orange font-18 font-300" />
                </button>
              </Modal.Header>

              <Modal.Body>
                {this.state.loading ? (
                  <div className="text-center">
                    <div
                      className="spinner-grow text-white my-3"
                      role="status"
                    />
                  </div>
                ) : (
                  <>
                    <div className="user-card-info top-2rem">
                      <Grid spacing={1} container className="top-10 align-c">
                        <Grid xs={3} item>
                          <img
                            src={Avatar2}
                            alt="user-icon"
                            height="60px"
                            width="60px"
                          />
                        </Grid>
                        <Grid xs={7} item>
                          <h5 className="text-white font-16 font-700">
                            {storage.getKey("name")}
                          </h5>
                          <p className="text-gray margin-0">
                            User ID: {storage.getKey("user_id")}
                          </p>
                        </Grid>
                        <Grid
                          xs={2}
                          className="flex-column-grid"
                          item
                          onClick={() => this.toggleModalContent("editName")}
                        >
                          <i className="fa fa-edit" />
                          <p className="margin-0 text-white">Edit</p>
                        </Grid>
                      </Grid>
                      <Grid
                        spacing={1}
                        container
                        className="padding-2rem align-c"
                      >
                        <Grid xs={10} item>
                          <LinearProgress
                            color="primary"
                            height="8"
                            value="50"
                            className="user-info-progress"
                            variant="determinate"
                          />
                        </Grid>
                        <Grid xs={2} item>
                          <p className="margin-0 text-white ">20%</p>
                        </Grid>
                      </Grid>
                    </div>
                    <div className="padding-2rem">
                      <Tabs
                        fill
                        defaultActiveKey="account"
                        id="uncontrolled-tab-bet"
                        className="tabs-container "
                      >
                        <Tab
                          tabClassName={"w-100"}
                          disabled={accountDisabled}
                          eventKey="account"
                          title="Account"
                        >
                          <AccountInfo
                            toggleAccount={this.toggleAccount}
                            togglePrivacy={this.togglePrivacy}
                            toggleVerify={this.toggleVerify}
                          />
                        </Tab>
                        <Tab
                          tabClassName={"w-100"}
                          disabled={privacyDisabled}
                          eventKey="privacy"
                          title="Privacy"
                        >
                          <PrivacySettings
                            togglePrivacy={this.togglePrivacy}
                            toggleAccount={this.toggleAccount}
                            toggleVerify={this.toggleVerify}
                          />
                        </Tab>
                        <Tab
                          tabClassName={"w-100"}
                          disabled={verifyDisabled}
                          eventKey="verify"
                          title="Verify"
                        >
                          <VerifyAccount
                            togglePrivacy={this.togglePrivacy}
                            toggleAccount={this.toggleAccount}
                            toggleVerify={this.toggleVerify}
                          />
                        </Tab>
                      </Tabs>
                    </div>
                  </>
                )}
              </Modal.Body>
            </>
          )}
          {currentPage === "editName" && (
            <>
              <Modal.Header>
                <i
                  className={"fa fa-arrow-left bottom-menu-icon"}
                  onClick={() => this.toggleModalContent("home")}
                />
                &nbsp;&nbsp; Edit Username
                <button
                  type="button"
                  id="close-settings"
                  className="close p-2"
                  onClick={this.handleClose}
                >
                  <i className="fa fa-close text-orange font-18 font-300" />
                </button>
              </Modal.Header>
              <Modal.Body>
                {this.state.loading ? (
                  <div className="text-center">
                    <div
                      className="spinner-grow text-white my-3"
                      role="status"
                    />
                  </div>
                ) : (
                  <>
                    <div className="top-2rem">
                      <Grid
                        spacing={1}
                        container
                        alignItems="center"
                        justifyContent="center"
                        className="top-10 align-c"
                      >
                        <Grid
                          xs={12}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex", flexDirection: "column" }}
                        >
                          <img
                            src={this.state.avatar}
                            alt="user-icon"
                            width="100px"
                          />
                          <button
                            onClick={() =>
                              this.toggleModalContent("editAvatar")
                            }
                            className="btn-edit-avatar"
                          >
                            Edit Your Avatar
                          </button>
                        </Grid>
                        <Grid
                          xs={12}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex" }}
                          className={"top-1rem"}
                        >
                          <input
                            style={{ width: "50%", textAlign: "center" }}
                            className="deposit-input"
                            value={this.state.username}
                            onChange={(e) => this.handleUsernameChange(e)}
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex" }}
                          className="top-1rem"
                        >
                          <button
                            style={{ width: "50%" }}
                            className="green-btn"
                            onClick={(e) => this.updateUsername(e)}
                          >
                            Update Username
                          </button>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </Modal.Body>
            </>
          )}
          {currentPage === "editAvatar" && (
            <>
              <Modal.Header>
                <i
                  className={"fa fa-arrow-left bottom-menu-icon"}
                  onClick={() => this.toggleModalContent("editName")}
                />
                &nbsp;&nbsp; Edit Avatar
                <button
                  type="button"
                  id="close-settings"
                  className="close p-2"
                  onClick={this.handleClose}
                >
                  <i className="fa fa-close text-orange font-18 font-300" />
                </button>
              </Modal.Header>
              <Modal.Body>
                {this.state.loading ? (
                  <div className="text-center">
                    <div
                      className="spinner-grow text-white my-3"
                      role="status"
                    />
                  </div>
                ) : (
                  <>
                    <div className="top-2rem">
                      <Grid
                        spacing={1}
                        container
                        alignItems="center"
                        justifyContent="center"
                        className="top-10 align-c"
                      >
                        <Grid
                          xs={12}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex", flexDirection: "column" }}
                          className="avatar-edit-bg"
                        >
                          <img
                            src={this.state.avatar}
                            alt="user-icon"
                            width="100px"
                            height="100px"
                          />
                        </Grid>
                        <Grid xs={12} item style={{ padding: "0px 20px" }}>
                          Choose your avatar!
                        </Grid>
                        <Grid
                          xs={2}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex" }}
                        >
                          <img
                            src={Avatar1}
                            alt="user-icon"
                            onClick={() => this.toggleAvatar(Avatar1)}
                            width="30px"
                            height="30px"
                          />
                        </Grid>
                        <Grid
                          xs={2}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex" }}
                        >
                          <img
                            src={Avatar2}
                            onClick={() => this.toggleAvatar(Avatar2)}
                            alt="user-icon"
                            width="30px"
                            height="30px"
                          />
                        </Grid>
                        <Grid
                          xs={2}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex" }}
                        >
                          <img
                            src={Avatar3}
                            onClick={() => this.toggleAvatar(Avatar3)}
                            alt="user-icon"
                            width="30px"
                            height="30px"
                          />
                        </Grid>
                        <Grid
                          xs={2}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex" }}
                        >
                          <img
                            src={Avatar4}
                            onClick={() => this.toggleAvatar(Avatar4)}
                            alt="user-icon"
                            width="30px"
                          />
                        </Grid>
                        <Grid
                          xs={2}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex" }}
                        >
                          <img
                            src={Avatar5}
                            onClick={() => this.toggleAvatar(Avatar5)}
                            alt="user-icon"
                            width="30px"
                          />
                        </Grid>
                        <Grid
                          xs={2}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex" }}
                        >
                          <img
                            src={Avatar6}
                            onClick={() => this.toggleAvatar(Avatar6)}
                            alt="user-icon"
                            width="30px"
                          />
                        </Grid>
                        <Grid
                          xs={12}
                          alignItems="center"
                          justifyContent="center"
                          item
                          style={{ display: "flex" }}
                          className="top-1rem"
                        >
                          <button
                            style={{ width: "50%" }}
                            className="green-btn"
                            onClick={(e) => this.saveAvatar()}
                          >
                            Submit
                          </button>
                        </Grid>
                      </Grid>
                    </div>
                  </>
                )}
              </Modal.Body>
            </>
          )}
        </Modal>
      </>
    );
  }
}

export default UserAccount;
