import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Button, Modal, Row, Col } from "react-bootstrap";
import storage from "../../../../../Storage";
import Engine from "../../Engine";
import { gameCoin } from "../../../../../actions/gameCoin";
import { setWallet } from "../../../../../actions/gameWallet";
import { setWinnerText, setMeToGame } from "../../../../../actions/crashGame";
import {
  Event,
  __,
  isValidNumber,
  forceSatoshiFormat,
  wait,
  Game,
} from "../../../../../Helper";
import Swal from "sweetalert2";
import { showInfoToast } from "../../../../../toastNotifications";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "swal-all-in",
  },
  buttonsStyling: false,
});

class Trenball extends Component {
  _isMounted = false;

  constructor(props) {
    super(props);
    this.state = {
      engine: null,
      buttonRed: "Bet Red",
      buttonGreen: "Bet Green",
      buttonMoon: "Bet Moon",
      buttonTypeRed: "btn-bet",
      buttonTypeGreen: "btn-bet",
      buttonTypeMoon: "btn-bet",
      inputDisabled: false,
      amountDisabled: false,
      buttonProgress: null,
      gameStatus: null,
      clicked: false,
      added: false,
      holding: false,
      payout: "",
      amount: storage.getKey("tren_amount")
        ? storage.getKey("tren_amount")
        : 100.0,
      token: storage.getKey("token") ? storage.getKey("token") : null,
      whichButton: "",
      trRunning: false,
      betRedTxt: false,
      betGreenTxt: false,
      betMoonTxt: false,
    };
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleBet = this.handleBet.bind(this);
    this.setBet = this.setBet.bind(this);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
    this.toggleBtnRedTxt = this.toggleBtnRedTxt.bind(this);
    this.toggleBtnGreenTxt = this.toggleBtnGreenTxt.bind(this);
    this.toggleBtnMoonTxt = this.toggleBtnMoonTxt.bind(this);
  }
  componentWillMount() {
    this._isMounted = true;
  }

  componentDidMount() {
    if (this._isMounted) {
      const engine = Engine;

      this.setState({ engine: engine });

      //Load Coin From Redux
      this.props.gameCoin();

      document.addEventListener("mousedown", this.handleClickOutside);

      // Game Event
      engine.trigger.on("game_status", (data) => this.checkStatus(data));

      engine.trigger.on("waiting_crash", () => this.checkWaitingGame());
      engine.trigger.on("busted_crash", () => this.checkBustedGame());
      engine.trigger.on("started_crash", () => this.checkStartedGame());

      // User Event
      engine.trigger.on("play_crash", (data) => this.handlePlay(data));
      engine.trigger.on("finish_crash", (data) => this.handleFinish(data));

      //Error
      engine.trigger.on("error_crash", (data) => this.handleError(data));

      // Stop manual bet
      engine.trigger.on("stop_playing", () => this.stopManual());

      //Events on auto bet
      engine.trigger.on("auto_bet", (data) => this.handleAuto(data));
    }
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      //amount
      let amount = this.wrapperRef.current.value;

      amount = Math.max(10.0, parseFloat(amount));
      if (amount > 3000) {
        //amount = 3000; //maxbet - 3000 | remember to also set in the input
      }

      this.setState({ amount });

      if (amount === "NaN") {
        amount = 10.0;
      }
      this.setState({ amount });
    }
  }

  toggleBtnRedTxt() {
    if (this.state.betRedTxt === true) {
      this.setState({ betRedTxt: false });
    } else {
      this.setState({ betRedTxt: true, betGreenTxt: false, betMoonTxt: false });
    }
  }

  toggleBtnGreenTxt() {
    if (this.state.betGreenTxt === true) {
      this.setState({ betGreenTxt: false });
    } else {
      this.setState({ betRedTxt: false, betGreenTxt: true, betMoonTxt: false });
    }
  }

  toggleBtnMoonTxt() {
    if (this.state.betMoonTxt === true) {
      this.setState({ betMoonTxt: false });
    } else {
      this.setState({ betMoonTxt: true, betGreenTxt: false, betRedTxt: false });
    }
  }

  handleInputChange(event) {
    let target = event.target;
    let value = target.value;
    let name = target.name;

    if (name === "amount") {
      if (!isValidNumber(value)) return;

      storage.setKey("tren_amount", value);
    }

    this.setState({ [name]: value });
  }

  setButtonTexts(newText, buttonClass) {
    switch (this.state.whichButton) {
      case "red":
        this.setState({ buttonRed: newText, buttonTypeRed: buttonClass });
        break;
      case "green":
        this.setState({ buttonGreen: newText, buttonTypeGreen: buttonClass });
        break;
      case "moon":
        this.setState({ buttonMoon: newText, buttonTypeMoon: buttonClass });
        break;
      default:
    }
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  stopManual() {
    let disabled = this.state.trRunning ? true : false;
    this.props.toggleClassic(disabled);

    this.setButtonTexts(
      "Bet " + this.capitalizeFirstLetter(this.state.whichButton)
    );
    this.setState({
      started: false,
      inputDisabled: disabled,
      amountDisabled: false,
    });
  }

  checkStatus(data) {
    if (this._isMounted) {
      //console.log("game status, data:", data);

      switch (data.status) {
        case "waiting":
          this.checkWaitingGame();
          break;
        case "started":
          wait(1000).then(() => {
            this.checkStartedGame(data);
          });
          break;
        case "busted":
          this.checkBustedGame();
          break;
        default:
      }
    }
  }

  handleError(data) {
    if (this._isMounted) {
      this.props.setMeToGame(false);

      let disabled = this.state.trRunning ? true : false;
      this.setState({
        holding: false,
        added: false,
        inputDisabled: disabled,
        amountDisabled: false,
      });
      clearInterval(this.state.buttonProgress);
      this.setDefaultButton();

      if (!__.isUndefined(data.code)) {
        if (data.code === "credit") this.props.setWallet(true, data.uid);
      }

      this.setState({ clicked: false });
      showInfoToast(data.message);
    }
  }

  setDefaultButton = () => {
    if (this._isMounted) {
      clearInterval(this.state.buttonProgress);

      let disabled = this.state.trRunning ? true : false;
      this.props.toggleClassic(disabled);

      this.setButtonTexts(
        "Bet " + this.capitalizeFirstLetter(this.state.whichButton),
        "btn-bet"
      );
      this.setState({
        added: false,
        holding: false,
        inputDisabled: disabled,
        amountDisabled: false,
      });
    }
  };

  setWaitingButton = () => {
    if (this._isMounted) {
      this.props.toggleClassic(true);

      this.setButtonTexts(
        "Betting...",
        "btn-bet-success-crash text-black btn-p no-shadow"
      );
      this.setState({ added: true, inputDisabled: true, amountDisabled: true });
    }
  };

  setOutButton = () => {
    if (this._isMounted) {
      this.props.toggleClassic(true);

      let disabled = this.state.trRunning ? true : false;

      this.setButtonTexts("Cancel", "btn-bet-success");
      this.setState({ inputDisabled: disabled, amountDisabled: true });
    }
  };

  setBet() {
    if (this._isMounted) {
      this.setState({ clicked: true });
      let { engine, amount, payout, token, whichButton } = this.state;
      engine.coin = this.props.coin;
      engine.token = token;
      engine.amount = amount;
      engine.payout = payout;
      engine.type = "trenball";
      engine.button = whichButton;
      engine.play();
    }
  }

  cashOut() {
    if (this._isMounted) {
      let { engine } = this.state;
      engine.finish(Game["current_amount"]);
    }
  }

  handlePlay(data) {
    if (this._isMounted) {
      if (data.token === this.state.token) {
        this.props.setMeToGame(true);
      }
    }
  }

  handleFinish(data) {
    if (this._isMounted) {
      if (data.token === this.state.token) {
        clearInterval(this.state.buttonProgress);
        this.props.setWinnerText("  You Cashed Out @" + data.current / 100);
        this.props.setMeToGame(false);
        this.setDefaultButton();
      }
    }
  }

  checkWaitingGame() {
    if (this._isMounted) {
      this.props.setWinnerText("");
      //auto
      this.checkAutoRunning();

      this.setState({ gameStatus: "waiting" });

      if (this.state.holding) {
        this.setState({ holding: false });
        this.placeBet();
      }
    }
  }
  
    checkStartedGame(data = null) {
        if (this._isMounted) {
          let { im_in_game } = this.props;
          let { engine } = this.state;
    
          let coin = engine.coin;
          let amount = engine.amount;
    
          this.setState({ gameStatus: "started" });
    
          if (im_in_game === true || this.state.clicked) {
            this.props.toggleClassic(true);
    
            this.setState({
              inputDisabled: true,
              amountDisabled: true,
              clicked: false,
            });
            let self = this;
            this.setState({
              buttonProgress: setInterval(
                function () {
                  let current_amount = Game["current_amount"];
    
                  self.setState({ inputDisabled: true });
                  this.setButtonTexts(
                    "Wait: " + current_amount,
                    "btn-bet-success-crash"
                  );
                }.bind(this),
                50
              ),
            });
          }
        }
      }

      checkBustedGame() {
        if (this._isMounted) {
          this.setState({ gameStatus: "busted", clicked: false });
    
          let { im_in_game } = this.props;
    
          if (!this.state.holding) {
            this.props.setMeToGame(false);
            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();
          }
    
          if (im_in_game) {
            this.props.setMeToGame(false);
            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();
          }
        }
      }
    


      placeBet() {
        if (this._isMounted) {
          let { engine } = this.state;
    
          engine.isHolding = false;
          this.setWaitingButton();
          this.setBet();
        }
      }
  holdBet() {
    if (this._isMounted) {
      let { engine } = this.state;
      engine.isHolding = true;
      this.setState({ holding: true });
      this.setOutButton();
    }
  }

  handleBet(e) {
    if (this._isMounted) {
      e.preventDefault();

      let { amount, payout, holding, gameStatus, token } = this.state;
      let { im_in_game } = this.props;

      // Check User
      if (!token) {
        return Event.emit("showAuthModal", true);
      }

      // Check is Correct Bet
      if (!this.isCorrectBet()) {
        return false;
      }

      if (amount < 10) {
        return false;
      }

      if (payout !== "") {
        this.setState({ payout: (payout * 1).toFixed(2) });
      }

      // Check Game Status to Play
      switch (gameStatus) {
        case "waiting":
          this.placeBet();
          break;

        case "busted":
          if (holding) {
            this.setDefaultButton();
          } else {
            this.holdBet();
          }
          break;

        case "started":
          if (im_in_game) {
            this.cashOut();
          } else if (holding) {
            this.setDefaultButton();
          } else {
            this.holdBet();
          }
          break;
        default:
      }
    }
  }

  setAllIn = () => {
    let max = this.props.credit;
    if (max === null) return;
    if (max === "NaN") return;

    max = Math.max(max, 0); //maxbet - 3000

    this.setState({ amount: max.toFixed(2) });
    storage.setKey("tren_amount", max);
  };

  setMin = (e) => {
    e.preventDefault();
    this.setState({ amount: "10.00" });
    storage.setKey("tren_amount", 10.0);
  };

  multi = (e) => {
    let max = this.state.amount * 2;
    this.setState({ amount: forceSatoshiFormat(max) });
    storage.setKey("tren_amount", max);
  };

  divide = (e) => {
    let max = this.state.amount / 2;
    max = Math.max(max, 10);
    this.setState({ amount: forceSatoshiFormat(max) });
    storage.setKey("tren_amount", max);
  };

  runTrAuto = (e, button, payout) => {
    e.preventDefault();
    this.props.toggleClassic(!this.state.trRunning);

    //button
    this.setState({ whichButton: button, payout: payout });

    if (this.state.trRunning) {
      this.setState({
        trRunning: false,
        inputDisabled: false,
        amountDisabled: false,
      });
    } else {
      this.setState({
        trRunning: true,
        inputDisabled: true,
        amountDisabled: true,
      });
    }
  };

  checkAutoRunning = () => {
    if (this.state.trRunning) {
      this.setWaitingButton();
      let amount = this.state.amount;
      let payout = this.state.payout;
      let button = this.state.whichButton;

      this.handleAuto({ amount, payout, button });
    }
  };
  setMax = (e) => {
    e.preventDefault();
    let max = this.props.credit;
    if (max === null) return;
    if (max === "NaN") return;

    max = Math.max(max, 0); //maxbet - 3000

    this.setState({ amount: max.toFixed(2) });
    storage.setKey("tren_amount", max);
  };


  handleAuto = (data) => {
    if (this._isMounted) {
      let { amount, payout, button } = data;

      if (!payout) return alert("payout is empty.");
      if (!amount) return alert("amount is empty.");
      if (!button) return alert("button error.");

      this.setState({ amount: amount, payout: payout, button: button });

      if (this.state.gameStatus === "started") {
        this.holdBet();
      } else if (
        this.state.gameStatus === "waiting" ||
        this.state.gameStatus === "busted"
      ) {
        this.setBet();
      }
    }
  };

  ask(e) {
    Swal.fire({
      title: "Go All In",
      header: "Hey",
      text: "Are you sure you want to go all in? This will stake all the amount in your wallet.",
      showCancelButton: true,
      confirmButtonColor: "#1BB55C",
      showCloseButton: true,
      cancelButtonColor: "#263238!important",
      cancelButtonText: "No",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        this.setMax(e);
      }
    });
  }

  stake() {
    Swal.fire({
      title: "Stake",
      header: "Hey",
      text: "Stake means the amount you are betting from your wallet. The minimum amount is Ksh 100.",
      showCancelButton: false,
      showConfirmButton: false,
      showCloseButton: true,
    }).then((result) => {
      if (result.value) {
        // this.setMax();
      }
    });
  }

  render() {
    let {
      amount,
      inputDisabled,
      amountDisabled,
      buttonTypeRed,
      buttonTypeGreen,
      buttonTypeMoon,
      buttonRed,
      buttonGreen,
      buttonMoon,
      whichButton,
      trRunning,
    } = this.state;
    let [redHeader, moonHeader, greenHeader] = Array(3).fill("Auto");
    let [redBg, greenBg, moonBg] = Array(3).fill("#FFCF14");
    let redBody = "special-danger",
      greenBody = "special-green",
      moonBody = "special-yellow";
    let [redDisabled, greenDisabled, moonDisabled] = Array(3).fill(false);

    switch (whichButton) {
      case "red":
        redHeader = trRunning ? "Stop!" : redHeader;
        redBg = trRunning ? "#4FCF2F" : redBg;
        redBody = trRunning
          ? "spinner-border spinner-border-sm special-danger"
          : redBody;
        [greenDisabled, moonDisabled] = Array(2).fill(trRunning);
        break;
      case "green":
        greenHeader = trRunning ? "Stop!" : greenHeader;
        greenBg = trRunning ? "#4FCF2F" : greenBg;
        greenBody = trRunning
          ? "spinner-border spinner-border-sm special-danger"
          : greenBody;
        [redDisabled, moonDisabled] = Array(2).fill(trRunning);
        break;
      case "moon":
        moonHeader = trRunning ? "Stop!" : moonHeader;
        moonBg = trRunning ? "#4FCF2F" : moonBg;
        moonBody = trRunning
          ? "spinner-border spinner-border-sm special-danger"
          : moonBody;
        [greenDisabled, redDisabled] = Array(2).fill(trRunning);
        break;
    }

    //initial render
    if (amount === "NaN") {
      amount = 10.0;
    }

    return (
      <div>
        <form
          className="w-100 mt-1"
        >
          <div className={"row"}>
            <div className={"col-12"}>
              <div className={"form-group mb-1 bet-input payout"}>
                <p className="text-white margin-0">
                  Stake&nbsp;
                  <i
                    className="fa fa-info-circle text-gray font-10"
                    onClick={this.stake}
                  />
                </p>
                <div
                  className={"btn-group w-100 row margin-0 remove-margin"}
                  role="group"
                  aria-label="range group"
                >
                  <input
                    ref={this.wrapperRef}
                    style={{ borderRadius: "0.25rem 0 0 0.25rem" }}
                    disabled={amountDisabled}
                    type="number"
                    min={10}
                    step="0.01"
                    className="form-control bet-amount-input text-left col-6"
                    id="amount"
                    name="amount"
                    placeholder="10.00"
                    value={amount}
                    autoComplete={"off"}
                    onKeyUp={this.handleInputChange}
                    onChange={this.handleInputChange}
                  />
                  <button
                    disabled={amountDisabled}
                    type={"button"}
                    className={"btn btn-sm p-0 bet-container col-2"}
                    onClick={(e) => this.divide(e)}
                  >
                    <div className="bet-amount-bg">1&frasl;2</div>
                  </button>
                  <button
                    disabled={amountDisabled}
                    type={"button"}
                    className={"btn btn-sm p-0 bet-container col-2"}
                    onClick={(e) => this.multi(e)}
                  >
                    <div className="bet-amount-bg">2x</div>
                  </button>
                  <button
                    disabled={amountDisabled}
                    type={"button"}
                    className={"btn btn-sm p-0 pr-1 bet-container col-"}
                    onClick={(e) => this.ask(e)}
                  >
                    <div
                      className="bet-amount-bg"
                      style={{ border: "none" }}
                      onClick={(e) => this.ask(e)}
                    >
                      All In
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div className={"row"}>
            <div className={"col-12 mt-2"}>
              <table className={"table table-striped mb-0 main-special-table"}>
                <tr className="special-table">
                  <td>
                    <p className="font-10 margin-0">
                      If your crash point is less than 2 <br />
                      you will get
                      <span
                        style={{ fontSize: "0.9em" }}
                        className={"text-warning font-10"}
                      >
                        {" "}
                        1.70x
                      </span>{" "}
                      of your Bet &nbsp; <br />
                      <span className={"text-danger font-10"}>
                        Winning probability is 50.5% &nbsp;&nbsp;
                        <i
                          className="fa fa-info-circle font-12"
                          onClick={this.toggleBtnRedTxt}
                        />
                      </span>
                    </p>
                    {this.state.betRedTxt && (
                      <p className="font-10 top-10 margin-0">
                        If your crash point is less than 2 <br />
                        you will get
                        <span
                          style={{ fontSize: "0.9em" }}
                          className={"font-10"}
                        >
                          {" "}
                          1.70x
                        </span>{" "}
                        of your Bet &nbsp; <br />
                        <span className={"font-10"}>
                          Winning probability is 50.5% &nbsp;
                        </span>
                      </p>
                    )}
                  </td>

                  <td style={{ verticalAlign: "top" }}>
                    <Button
                      name={"red-auto"}
                      onClick={(e) => this.runTrAuto(e, "red", 170)}
                      className="special-red-btn font-12"
                      variant={"btn btn-block "}
                      disabled={redDisabled}
                      type={"button"}
                    >
                      {redHeader} {redBody === "special-danger" ? "Red" : ""}
                    </Button>
                  </td>
                </tr>
                <tr className="special-table">
                  <td>
                    <p className="font-10 margin-0">
                      If your crash equal to or more than 2<br />
                      you will get
                      <span
                        style={{ fontSize: "0.9em" }}
                        className={"text-warning font-10"}
                      >
                        {" "}
                        2x
                      </span>{" "}
                      of your Bet &nbsp; <br />
                      <span className={"text-green font-10"}>
                        Winning probability is 49.5% &nbsp;&nbsp;
                        <i
                          className="fa fa-info-circle font-12"
                          onClick={this.toggleBtnGreenTxt}
                        />
                      </span>
                    </p>
                    {this.state.betGreenTxt && (
                      <p className="font-10 top-10 margin-0">
                        If your crash point is less than 2 <br />
                        you will get
                        <span
                          style={{ fontSize: "0.9em" }}
                          className={"font-10"}
                        >
                          {" "}
                          1.70x
                        </span>{" "}
                        of your Bet &nbsp; <br />
                        <span className={" font-10"}>
                          Winning probability is 49.5% &nbsp;
                        </span>
                      </p>
                    )}
                  </td>

                  <td style={{ verticalAlign: "top" }}>
                    <Button
                      name={"green-auto"}
                      onClick={(e) => this.runTrAuto(e, "green", 200)}
                      className="special-green-btn font-12"
                      variant={"btn btn-block "}
                      disabled={greenDisabled}
                      type={"button"}
                    >
                      {greenHeader}{" "}
                      {greenBody === "special-green" ? "Green" : ""}
                    </Button>
                  </td>
                </tr>

                <tr className="top-10 special-table">
                  <td>
                    <p className="font-10 margin-0">
                      If your crash point is equal to or more than 10
                      <br />
                      you will get
                      <span
                        style={{ fontSize: "0.9em" }}
                        className={"text-warning font-10"}
                      >
                        {" "}
                        10x
                      </span>{" "}
                      of your Bet &nbsp; <br />
                      <span className={"text-warning font-10"}>
                        Winning probability is 9.9% &nbsp;&nbsp;
                        <i
                          className="fa fa-info-circle font-12"
                          onClick={this.toggleBtnMoonTxt}
                        />
                      </span>
                    </p>
                    {this.state.betMoonTxt && (
                      <p className="font-10 top-10 margin-0">
                        If your crash point is less than 2 <br />
                        you will get
                        <span
                          style={{ fontSize: "0.9em" }}
                          className={"text-warning font-10"}
                        >
                          {" "}
                          1.70x
                        </span>{" "}
                        of your Bet &nbsp; <br />
                        <span className={" font-10"}>
                          Winning probability is 50.5% &nbsp;
                        </span>
                      </p>
                    )}
                  </td>

                  <td style={{ verticalAlign: "top" }}>
                    <Button
                      name={"moon-auto"}
                      onClick={(e) => this.runTrAuto(e, "moon", 1000)}
                      variant={"btn btn-block " + buttonTypeMoon}
                      disabled={moonDisabled}
                      type={"button"}
                      className="special-moon-btn font-12"
                    >
                      {moonHeader} {moonBody === "special-yellow" ? "Moon" : ""}
                    </Button>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Trenball.propTypes = {
  coin: PropTypes.string,
  im_in_game: PropTypes.bool,
  credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
  coin: state.items.coin,
  im_in_game: state.items.im_in_game,
  credit: state.items.credit,
});

export default connect(mapStateToProps, {
  gameCoin,
  setWinnerText,
  setMeToGame,
  setWallet,
})(Trenball);
