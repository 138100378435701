import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import storage from "../../../../Storage";
import { decode, Event, fixDate, wait } from "../../../../Helper";
import { NavLink } from "react-router-dom";
import NotifIcon from "../../../../Static/images/Avatar/notif.svg";
import RainIcon from "../../../../Static/images/rain.svg";
import {
  ChatClick,
  SettingsClick,
  StatsClick,
  UpdateHomeIcon,
  UpdateHomeIconActive,
  VipClick,
  WalletClick,
} from "../ClickService";
import socket from "../../../../Socket";
import C from "../../../../Constant";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initState: 0,
      show: this.props.show ? this.props.show : false,
      notifications: [],
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    Event.on("show_notification", this.handleShow);
    socket.emit(C.NOTIFICATION);
    socket.on(C.NOTIFICATION, (data) => this.getNotification(decode(data)));
  }
  getNotification(data) {
    wait(1000).then(() => {
      this.setState({ loading: false });
    });

    data.forEach((news, i) => {
      this.setState((state) => ({
        notifications: [
          <OpenModal
            t={this.props.t}
            key={i}
            title={news.title}
            content={news.content}
            date={news.date}
          />,
          ...state.notifications,
        ],
      }));
    });
  }
  handleClose() {
    this.setState({ show: false, effect: "zoomOut" });
    UpdateHomeIconActive();
  }

  handleShow() {
    if (storage.getKey("token") === null) {
      this.showLogin();
    } else {
      ChatClick();
      WalletClick();
      SettingsClick();
      StatsClick();
      VipClick();
      UpdateHomeIcon();
      this.setState({ show: true, effect: "pulse" });
    }
  }

  showLogin() {
    Event.emit("show_login");
  }

  render() {
    return (
      <>
        {!this.props.show && (
          <NavLink
            to={"#"}
            className="nav-link bottom-nav-link"
            onClick={(e) => this.handleShow(e)}
          >
            <div
              className={
                "row d-flex flex-column justify-content-center align-items-center bottom-menu gray-text"
              }
            >
              <i className={"fa fa-bell bottom-menu-icon notification-icon"}>
                <span class="badge">{this.state.notifications.length}</span>
              </i>
              <div className={"bottom-tab-label font-9 font-9"}>
                Notifications
              </div>
            </div>
          </NavLink>
        )}

        <Modal
          size="md"
          centered={true}
          scrollable={false}
          show={this.state.show}
          onHide={this.handleClose}
          onExited={this.handleClose}
          aria-labelledby="wallet-md-modal"
          className={"animated " + this.state.effect}
        >
          <Modal.Header className="modal-heading">
            <i className={"fa fa-bell bottom-menu-icon"} />
            &nbsp;&nbsp; Notifications
            <button
              type="button"
              id="close-btn"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className="fa fa-close text-orange font-18 font-300" />
            </button>
          </Modal.Header>
          <Modal.Body className="notif-body padding-0 margin-30">
            <>
              {this.state.notifications.length === 0 && !this.state.loading && (
                <b className={"mt-1 d-block text-center"}>
                  <p> No Notification to show</p>
                </b>
              )}
              {this.state.loading && this.state.notifications.length > 0 ? (
                <b className={"mt-1 d-block text-center"}>
                  <div className="spinner-grow text-pink" role="status" />
                </b>
              ) : (
                this.state.notifications
              )}
            </>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

class OpenModal extends React.Component {
  send = (data) => {
    Event.emit("show_notify", this.props);
  };
  render() {
    let { title, date, content } = this.props;
    return (
      <>
        <button onClick={this.send} className="dropdown-item notify-item mb-2">
          <div className="notify-icon bg-success">
            {title} 
          </div>
          <p className="notify-details">
            {content}
            <small className="text-muted">{fixDate(date)}</small>
          </p>
        </button>
      </>
    );
  }
}
export default Notifications;
