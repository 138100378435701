import React, {Component} from 'react'
import storage from "../../../../Storage";
import MedalIcon from '../../../../Static/images/medal.svg'
import socket from "../../../../Socket";
import C from "../../../../Constant";
import {COIN_TOKEN, decode, encode, formatAmount} from "../../../../Helper";
import UserModal from "../../../Components/User/Stat/Modal";
import { StatsClick } from '../ClickService';
import { showWarningToast } from '../../../../toastNotifications';


class LeaderboardTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            initState: 0,
            show: this.props.show ? this.props.show : false,
            loading: true,
            players: [],
            game: "crash",
            myAllTimeProfit: 0,
            myCurrentPosition: 0,
        };

        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        socket.emit(C.TOP_WINNERS);
        socket.emit(
            C.MY_BETS,
            encode({ token: this.state.token, game: this.state.game })
          );
        socket.on(C.MY_BETS, (data) => this.calculateMyBetProfit(decode(data)));
        socket.on(C.TOP_WINNERS, data => this.makeList(data));
    }

    calculateMyBetProfit(arr) {
        if (typeof arr.history === "undefined") return;
        let profit = 0;
        arr.history.forEach((bet, i) => {
          profit += parseInt(bet.profit);
        });
        this.setState({
          myAllTimeProfit: profit,
        });
        console.log(profit);
      }
    makeList = (data) => {
       data = data.filter((item, pos, self) => self.findIndex(v => v.name === item.name) === pos);
        this.setState({ loading: false, players: (data) })
        this.setState({myCurrentPosition: data.findIndex(x => x.name === this.state.name) + 1});
    };

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    handleShow() {
        if (storage.getKey('token') === null) {
             showWarningToast('Please Login to use this option.');
             return;
        }

        this.setState({show: true, effect: 'pulse'});
    }

    closeModal(){
        StatsClick();
    }

    render() {
        const { t } = this.props;

        const list = this.state.players.map((player, i) =>
            <Players key={i} medal={i+1} player={player} />
        );

        return (
            <>
                {
                    this.state.loading ?
                        (<div className="text-center">
                            <div className="spinner-grow text-white my-3" role="status"/>
                        </div>)
                    :
                       ( <div>
                            <div className='row margin-10 stats-dark-card remove-margin'>
                                <div className='col-6' style={{padding: '0 9px 0 0px'}}>
                                    <div>
                                        <p className='font-10 margin-0  text-white'>My all time profit</p>
                                        <p className='font-15 text-white margin-0'>KES : <span className='text-green font-15'>{formatAmount(this.state.myAllTimeProfit)}</span></p>
                                    </div>
                                </div>
                                <div className='col-4' style={{padding: '0 9px 0 0px'}}>
                                    <div>
                                        <p className='font-10 margin-0  text-white'>Current position</p>
                                        <p className='font-15 text-white margin-0 '><span className='text-green font-15'>{this.state.myCurrentPosition}</span></p>
                                    </div>
                                </div>

                                <div className='col-2' >
                                    <img src={MedalIcon} alt='medal icon' className='text-center'/>
                                </div>
                            </div>

                            <div className='stats-dark-card margin-10'>
                            <div className='darker-card '>
                                <div className='row'>
                                    <p className='text-orange col-8 margin-0'>Top Earners</p>
                                    <div className='col-4'>
                                        
                                            <button className='orange-btn play-btn' onClick={this.closeModal}>
                                                Play Now
                                            </button>
                                    </div>
                                    
                                </div>
                            </div>

                            <div className="table-responsive mt-2" style={{height: '25vh',overflowY: 'scroll'}}>
                                <table className='table table-hover fix-table table-stats deposit'>
                                    <thead className='sticky-top'>
                                        <tr className="table-head">
                                            <th className="table-head" scope="col">#</th>
                                            <th className="table-head"  scope="col">Player</th>
                                            <th className="table-head"  scope="col">All Time Profit</th>
                                            <th className="table-head"  scope="col">Plays</th>
                                            <th className="table-head"  scope="col">Medal</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {list}
                                    </tbody>
                                </table>
                            </div>
                            </div>
                        </div>
                       )
                }
            </>
        );
    }
}

class Players extends React.Component{
    constructor(props){
        super(props);
    }
    render() {
        let { player, medal } = this.props;
        let num = medal;
        
        if(medal === 10){
            medal = 1
        }

        let coin = COIN_TOKEN;
        let username = player.name;
        let avatar = player.avatar;
        let profit_high = formatAmount(parseFloat(player.profit_high[coin]).toFixed(2));
        let plays = player.plays;

        return(
            <tr className='content-row'>
                <td>
                    {num}
                </td>
                <td>
                    <UserModal username={username} isWinner={false} />
                </td>
                <td>
                    KES {profit_high}
                </td>
                <td>
                    {plays}
                </td>
                <td>
                    <img className="rounded-circle thumb-xxs ffi" src={'/assets/images/badges/badge-' + medal + '.svg'} />
                </td>
            </tr>
        );
    }
}

export default LeaderboardTab;