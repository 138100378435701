import React, { Component } from "react";
import { Nav, NavItem } from "reactstrap";
import { NavLink, withRouter } from "react-router-dom";
import { Event, sendNotfication } from "../../../Helper";
import Engine from "../../Games/Crash/Engine";
import storage from "../../../Storage";
import Notifications from "../../Components/User/Notifications";
import ChatModal from "../../Components/User/Chat";
import UserAccount from "../../Components/User/UserAccount";
import Statistics from "../../Components/User/Statistics";
import Login from "../Auth/Login";
import Playa from "../../../Static/images/playa1.svg";
import { Link } from "react-router-dom";
import Profile from "../../Components/User/Profile";
import {
  ChatClick,
  NotifClicker,
  SettingsClick,
  StatsClick,
  UpdateHomeIconActive,
  VipClick,
  WalletClick,
  UpdateHomeIcon,
} from "../../Components/User/ClickService";
import { ReactComponent as ActiveHomeBtn } from "../../../Static/images/playa.svg";
import { ReactComponent as InactiveHomeBtn } from "../../../Static/images/playa.svg";

class Navigation extends Component {
  constructor(props) {
    super(props);
    this.state = { bonus: "0", profileDisplayed: false };
  }
  componentDidMount() {
    Engine.trigger.on("update_bonus", (data) => this.updateBonus(data));
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    this._isMounted = false;
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside(event) {
    UpdateHomeIconActive();
  }
  showDeposit() {
    if (storage.getKey("token") === null) {
      Event.emit("show_login");
    } else {
      Event.emit("show_deposit");
    }
  }

  showNotification() {
    Event.emit("show_notification");
  }

  showAccountInfo() {
    Event.emit("show_account_info");
  }

  updateBonus(data) {
    this.setState({ bonus: data });
  }

  closeModal() {
    NotifClicker();
    WalletClick();
    ChatClick();
    StatsClick();
    SettingsClick();
    VipClick();
    UpdateHomeIconActive();
  }

  showLogin() {
    UpdateHomeIcon();
    // console.log(!!this.state.profileDisplayed);
    // if (!!this.state.profileDisplayed) {
    //   UpdateHomeIcon();
    // }
    // this.setState({ profileDisplayed: !!this.state.profileDisplayed });

    Event.emit("show_login");
  }
  render() {
    let { bonus } = this.state;

    return (
      <div>
        {/*Bottom*/}
        <nav
          style={{ padding: "1rem 0.2rem 0.2rem 0.2rem" }}
          className={
            "navbar fixed-bottom navbar-light d-block d-lg-none bottom-tab-nav"
          }
          role={"navigation"}
        >
          <Nav style={{ background: "transparent" }} className={"w-100"}>
            {" "}
            {/*<div className={"d-flex flex-row justify-content-around w-100"}>*/}
            <NavItem
              style={{ width: "19%" }}
              className={"bottom-nav-buttons"}
              key={`tab-1`}
            >
              <NavLink className="nav-link bottom-nav-link" to={"#"}>
                <div
                  className={
                    "row d-flex flex-column justify-content-center align-items-center bottom-menu gray-text"
                  }
                  onClick={this.showDeposit}
                >
                  <i className={"fa fa-wallet bottom-menu-icon"} />
                  <div className={"bottom-tab-label font-9 font-9"}>Wallet</div>
                </div>
              </NavLink>
            </NavItem>
            <NavItem
              style={{ width: "21%" }}
              className={"bottom-nav-buttons"}
              key={`tab-2`}
            >
              <Notifications />
            </NavItem>
            <NavItem
              style={{ width: "19%" }}
              className={"bottom-nav-buttons"}
              key={`tab-3`}
              onClick={this.closeModal}
            >
              <NavLink to={"/"} className="nav-link bottom-nav-link">
                <div
                  className={
                    "row d-flex flex-column justify-content-center align-items-center bottom-menu gray-text"
                  }
                >
                  <ActiveHomeBtn
                    data-tip="home"
                    id="home-icon"
                    className="img-fluid fill-svg-orange mx-1 width-30"
                  />
                </div>
              </NavLink>
            </NavItem>
            <NavItem
              style={{ width: "21%" }}
              className={"bottom-nav-buttons"}
              key={`tab-4`}
            >
              <ChatModal />
            </NavItem>
            <NavItem
              style={{ width: "19%" }}
              className={"bottom-nav-buttons"}
              key={`tab-5`}
              onClick={this.showLogin}
            >
              <Profile />
            </NavItem>
          </Nav>
        </nav>
      </div>
    );
  }
}
export default Navigation;
