import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Card} from "react-bootstrap";
import storage from "../../../../Storage";
import Engine from "../Engine";
import {gameCoin} from "../../../../actions/gameCoin";
import {setWallet} from "../../../../actions/gameWallet";
import {Event, __, forceSatoshiFormat, wait, Game, formatAmount, decode, encode} from "../../../../Helper";
import socket from "../../../../Socket";
import {showErrorToast} from "../../../../toastNotifications";
import LinearProgress from "@mui/material/LinearProgress";
import JackpotTopThree from "./JackpotTopThree";
import JackpotMine from "./JackpotMine";
import C from "../../../../Constant";

class Jackpots extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: null,
            buttonText: 'Waiting Next Round',
            buttonClass: 'btn-bet',
            buttonDisabled: false,
            buttonProgress: null,
            gameStatus: null,
            register_at: 1,
            amount: 100,
            token: storage.getKey('token') ? storage.getKey('token') : null,
            jackpot: [],
            jackpot_id: 0,
            completion: 0,
            slot_message: 'Click the button above to Register your Crash',
        };

        this.handleBet = this.handleBet.bind(this);
        this.wrapperRef = React.createRef();
    }

    componentDidMount() {
        if (this._isMounted) {
            const engine = Engine

            this.setState({engine: engine});

            socket.emit('JACKPOTS', encode({token: this.state.token, game: this.state.game}));
            socket.on('JACKPOTS', data => this.doJackpot(decode(data)));
            socket.on('PLAY_JACKPOT', data => this.playedJackpot(decode(data)));

            //Load Coin From Redux
            this.props.gameCoin();

            // Game Event
            socket.on(C.BUSTED_CRASH, () => this.checkBustedGame());
            socket.on(C.STARTED_CRASH, () => this.checkStartedGame());

            //Error
            socket.on(C.ERROR_CRASH, data => this.handleError(data));
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleError(data) {
        if (this._isMounted) {
            this.setState({buttonDisabled: false});
            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();

            if (!__.isUndefined(data.code)) {
                if (data.code === 'credit') this.props.setWallet(true, data.uid);
            }

            showErrorToast(data.message);

            if (!__.isUndefined(data.code)) {
                if (data.code === 'version') {
                    wait(3000).then(() => {
                        window.location.reload();
                    });
                }
            }
        }
    }

    setDefaultButton = () => {
        if (this._isMounted) {
            clearInterval(this.state.buttonProgress);

            this.setState({buttonDisabled: true, buttonClass: 'btn-bet', buttonText: 'Waiting Next Round', slot_message: 'Waiting next round...'});
        }
    };

    checkStartedGame(data = null) {
        if (this._isMounted) {
            let {engine} = this.state;

            let amount = engine.amount

            this.setState({gameStatus: 'started', buttonDisabled: false, buttonClass: "btn-bet-success-crash", slot_message: 'Click the button above to Register your Crash'});

            let counter = 0;
            let self = this;
            this.state.buttonProgress = setInterval(function () {
                //let calc = amount * (Game['current_amount'] - 1);
                let current_amount = (Game['current_amount']);

                let win_amount = amount * current_amount;
                self.setState({buttonText: 'Register Jackpot @' + forceSatoshiFormat(current_amount) + 'x for 100/-', register_at: current_amount});
                counter++;
            }
                .bind(this), 50);
        }
    }

    checkBustedGame() {
        if (this._isMounted) {
            this.setState({gameStatus: 'busted'});

            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();
        }
    }

    placeBet() {
        if (this._isMounted) {
            let {engine, jackpot_id, token} = this.state;

            engine.token = token;
            engine.jackpot_id = jackpot_id;
            engine.playJackpot(Game["current_amount"]);
        }
    }

    handleBet(e) {
        if (this._isMounted) {
            e.preventDefault();

            let {gameStatus, token} = this.state;

            // Check User
            if (!token) {
                return Event.emit('showAuthModal', true);
            }

            // Check Game Status to Play
            switch (gameStatus) {
                case 'waiting':
                case 'busted':
                    console.log("game status: " + gameStatus + " | nothing should happen for now!");
                    break;
                case 'started':
                    this.placeBet();
                    break;
            }
        }
    }

    doJackpot(data) {

        console.log('[temp] jackpots: ', data);

        if (this._isMounted) {
            if (data.result) {
                let jd = data.result;
                this.setState({jackpot: jd, jackpot_id: jd.id, amount: jd.play_amount, completion: jd.completion});
            }
        }
    }

    playedJackpot(data) {
        if (this._isMounted) {
            console.log('played jackpot: ', data);
            this.setState({slot_message: data.message});

            //update jackpot stats
            socket.emit('JACKPOTS', encode({token: this.state.token, game: this.state.game}));
            socket.emit('JACKPOT_TOP_3', encode({jackpot_id: this.state.jackpot_id}));
            socket.emit('JACKPOT_MINE', encode({token: this.state.token, jackpot_id: this.state.jackpot_id}));
        }
    }

    render() {

        let {buttonText, buttonDisabled, buttonClass, slot_message, completion} = this.state;

        return (
            <Card className={"no-shadow mb-1 h-bet inner-dark-bg"}>
                <Card.Body className="bet-form p-0 no-shadow">
                    <>
                        <div className="jackpot-meter margin-10">
                            <div style={{padding: "10px 20px"}}>
                                <p className="font-14 text-left header-color text-orange">This is how to play the jackpot&nbsp;
                                    <i className="fa fa-info-circle font-12" onClick={this.info}/>
                                </p>
                                <div className="row remove-margin" style={{alignItems: "center"}}>
                                    <p className="margin-0 header-color font-11">Completion &nbsp;&nbsp;</p>
                                    <div className="col-6 padding-0">
                                        <LinearProgress color='primary' value={completion} className='jackpot-progress' variant="determinate"/>
                                    </div>
                                    <p className="margin-0 text-white font-10">&nbsp;&nbsp; <b>{completion}%</b></p>
                                </div>
                            </div>
                        </div>
                        <form className="w-100" onSubmit={(e) => {
                            this.handleBet(e)
                        }}>
                            <button style={{fontFamily: 'monospace'}} className={"orange-btn btn margin-10 " + buttonClass} disabled={buttonDisabled}>
                                {buttonText}
                            </button>
                        </form>
                        <div className={'col-12 p-0 text-center'}><span className={'text-success font-9'}>{slot_message}</span></div>

                        <JackpotTopThree/>

                        <hr className="jackpot-hr"/>

                        <JackpotMine/>

                    </>
                </Card.Body>
            </Card>
        );
    }
}

Jackpots.propTypes = {
    coin: PropTypes.string, credit: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin, credit: state.items.credit
});

export default connect(mapStateToProps, {gameCoin, setWallet})(Jackpots);