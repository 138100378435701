import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import Cookies from "js-cookie";
import Swal from 'sweetalert2';
import storage from "../../../Storage";
import {encode, isMobile} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";
import CancelIcon from '../../../Static/images/cancel.svg'


class Logout extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    ask() {
        Swal.fire({
            title: ' Log Out',
            header:'Hey',
            text: "Before you leave, Increase your chaces of wining by checking out our  game collection",
            showCancelButton: false,
            confirmButtonColor: '#FF4242',
            showCloseButton: true,
            cancelButtonColor: '#FF4242',
            confirmButtonText: 'Log Out',
        }).then((result) => {
            if (result.value) {
                socket.emit(C.LOGOUT_USER, encode({token: storage.getKey('token')}));
                localStorage.clear();
                Cookies.remove("session");
                Cookies.remove("auth");
                Cookies.remove("uid");
                window.location.replace('./');
            }
        });
    }

    render() {
        return (
            <>
                <NavLink to={"#"} className="nav-link text-left header-color" onClick={this.ask}>
                    <i className={"text-white fa fa-power-off"}></i>
                    {isMobile() ? ' Logout' : ''}
                </NavLink>
            </>
        );
    }
}

export default (Logout);