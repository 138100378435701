import React from "react";
import GameModal from "../../../Components/Game/Stat/Modal";
import storage from "../../../../Storage";
import Engine from "../Engine";
import {__, crashColor, isMobile} from "../../../../Helper";
import {Link} from "react-router-dom";
import HistoryIcon from '../../../../Static/images/history.svg'
import History from "../History";
import HistoryModal from "../HistoryModal";


class HistoryList extends React.Component {
    constructor (props){
        super(props);
        this.state = {
            games: [],
            name: storage.getKey('name'),
            token: storage.getKey('token'),
            game: this.props.game,
            gameRow: [],
            engine: Engine
        };
        this.openHistory = this.openHistory.bind(this);
    }

    componentDidMount() {
        let { engine } = this.state;
        engine.trigger.on("busted_crash", data => this.busted(data));
        engine.trigger.on("game_status", data => this.gameSync(data));
    }

    busted(data){ 

        let busted  = (data.amount/100).toFixed(2);
        let color = crashColor(busted);
        
        if(isMobile()){
            if(this.state.gameRow.length === 5){
                this.state.gameRow.shift();
            }
        }
        else {
            if(this.state.gameRow.length === 10){
                this.state.gameRow.shift();
            }
        }

        let add = this.state.gameRow.concat([
            <li> {/*className={color}*/}
                <GameModal color={color} title={busted + 'x'} game_id={data.game_id} font={12} />
            </li>
        ]);

        this.setState({ gameRow: add });
    }

    gameSync(list){ //all from db
        if(list.crashes === undefined) return;
        if(list.crashes.length === 0) return;

        //console.log("[Temp] game sync: ", list);

       __.reverse(list.crashes).forEach((array, i) => {
        //list.crashes.forEach((array, i) => {
            if(i < 10){
                let busted  = (array.amount/100).toFixed(2);
                let color = crashColor(busted);
                let row = <li key={i}> {/*className={color}*/}
                    <GameModal key={i} color={color} title={busted + 'x'} game_id={array.game_id} font={12} />
                </li>;
                this.setState(state => ({ gameRow: [row, ...state.gameRow] }));
            }
        });
    }
    
    openHistory (){
        Event.emit('open_crash_history');
    }
    
    render () {
        if(isMobile()){
            if(this.state.gameRow.length >=5)
                this.state.gameRow.length = 5;
        }
        else {
            if(this.state.gameRow.length >= 10)
                this.state.gameRow.length = 10;
        }
        return(
            <div className="nav font-14 font-weight-bold crash-history">
                <ul>
                    {this.state.gameRow}
                    <HistoryModal/>
                </ul>
            </div>
        );
    }
}

export default HistoryList;