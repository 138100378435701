import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Modal, Row, Col} from "react-bootstrap";
import storage from "../../../../../Storage";
import Engine from "../../Engine";
import {gameCoin} from "../../../../../actions/gameCoin";
import {setWallet} from "../../../../../actions/gameWallet";
import Swal from "sweetalert2";
import {setWinnerText, setMeToGame} from "../../../../../actions/crashGame";
import {
    Event,
    __,
    isValidNumber,
    forceSatoshiFormat,
    User,
    wait,
    Game,
    toComma,
    formatAmount,
} from "../../../../../Helper";
import winSound from "../../../../../Static/sounds/win.wav";
import {
    showErrorToast,
} from "../../../../../toastNotifications";

class ManualBet extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: null,
            buttonText: "Multiply (Next Round)",
            buttonType: "btn-bet",
            inputDisabled: false,
            amountDisabled: false,
            buttonProgress: null,
            gameStatus: null,
            clicked: false,
            added: false,
            holding: false,
            payout: storage.getKey("payout") ? storage.getKey("payout") : "",
            amount: storage.getKey("amount") ? storage.getKey("amount") : 10.0,
            target_profit: "",
            winning_chance: "",
            token: storage.getKey("token") ? storage.getKey("token") : null,
            hotkey: storage.getKey("hotkey") ? storage.getKey("hotkey") : "OFF",
            autoRunning: false,
            autoHeader: "Auto",
            autoBody: "text-danger",
            autoColor: "#304F67",
            probability: storage.getKey("probability")
                ? storage.getKey("probability")
                : "",
            cashoutAmount: "",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.setBet = this.setBet.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.setMaxCashout = this.setMaxCashout.bind(this);
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentDidMount() {
        if (this._isMounted) {
            const engine = Engine;

            this.setState({engine: engine});

            //Load Coin From Redux
            this.props.gameCoin();

            window.addEventListener("keypress", this.handleHotKey.bind(this));
            document.addEventListener("mousedown", this.handleClickOutside);

            // Game Event
            engine.trigger.on("game_status", (data) => this.checkStatus(data));

            engine.trigger.on("waiting_crash", () => this.checkWaitingGame());
            engine.trigger.on("busted_crash", () => this.checkBustedGame());
            engine.trigger.on("started_crash", () => this.checkStartedGame());

            // User Event
            engine.trigger.on("play_crash", (data) => this.handlePlay(data));
            engine.trigger.on("finish_crash", (data) => this.handleFinish(data));

            //Error
            engine.trigger.on("error_crash", (data) => this.handleError(data));

            // Stop manual bet
            engine.trigger.on("stop_playing", () => this.stopManual());

            //Events on auto bet
            //engine.trigger.on("auto_bet", data => this.handleAuto(data));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
        window.removeEventListener("keypress", this.handleHotKey.bind(this));
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    setTP(payout, amount) {
        let my_target_profit = parseFloat(payout * amount - amount).toFixed(2);
        if (my_target_profit === "NaN" || my_target_profit <= 0) {
            my_target_profit = "~";
        } else {
            my_target_profit = toComma(my_target_profit);
        }
        this.setState({target_profit: my_target_profit});
    }

    setWC(payout) {
        let my_wc = parseFloat(99 / payout).toFixed(2);
        if (my_wc === "NaN" || my_wc <= 0 || my_wc === "Infinity") {
            my_wc = "~";
        } else {
            my_wc += "%";
        }
        this.setState({winning_chance: my_wc});
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            //payout
            let value = parseFloat(this.state.payout);
            if (this.state.payout.length === 0 || value === "NaN") {
                //optional
            } else {
                if (value < 1.01) {
                    value = 1.01;
                }

                if (value > 1000000) {
                    value = 1000000;
                }
            }

            this.setState({payout: parseFloat(value).toFixed(2)});

            const prob = (99 / value).toFixed(2);
            this.setState({probability: prob});
            storage.setKey("probability", prob);
            //amount
            let amount = this.wrapperRef.current.value;

            amount = Math.max(10.0, parseFloat(amount));
            if (amount > 3000) {
                //amount = 3000; //maxbet - 3000 | remember to also set in the input
            }

            this.setState({amount});

            if (amount === "NaN") {
                amount = 10.0;
            }
            this.setState({amount});

            //set advice values
            this.setTP(value, amount);
            this.setWC(value);
        }
    }

    playAudio = () => {
        new Audio(winSound).play();
    };

    winner() {
        // toast("Here is your toast.");
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if (name === "amount") {
            if (!isValidNumber(value)) return;

            this.setTP(this.state.payout, value);
            storage.setKey("amount", value);
        }

        if (name === "payout") {
            if (value >= 999999) return;
            const prob = (99 / value).toFixed(2);
            this.setState({probability: prob});
            this.setTP(value, this.state.amount);
            this.setWC(value);
            storage.setKey("payout", value);
            storage.setKey("probability", prob);
        }
        if (name === "probability") {
            if (value > 98.02 || value < 0.01) return;
            const cashOut = (99 / value).toFixed(2);
            this.setWC(cashOut);
            this.setTP(cashOut, this.state.amount);
            this.setWC(cashOut);
            storage.setKey("payout", cashOut);
            this.setState({payout: cashOut});
            storage.setKey("probability", value);
        }

        this.setState({[name]: value});
    }

    stopManual() {
        if (this.state.autoRunning) {
            this.props.toggleSpecial(true);
        } else {
            this.props.toggleSpecial(false);
        }

        let nextText = this.state.autoRunning
            ? "Automatic..."
            : "Multiply (Next Round)";
        this.setState({
            started: false,
            buttonText: nextText,
            inputDisabled: false,
            amountDisabled: false,
        });
    }

    checkStatus(data) {
        if (this._isMounted) {
            switch (data.status) {
                case "waiting":
                    this.checkWaitingGame();
                    break;
                case "started":
                    wait(1000).then(() => {
                        this.checkStartedGame(data);
                    });
                    break;
                case "busted":
                    this.checkBustedGame();
                    break;
                default:
            }
        }
    }

    handleAuto = (data) => {
        if (this._isMounted) {
            let {amount, payout} = data;

            //console.log("handle auto: st -", this.state.gameStatus, " | data - ", data);

            if (!payout) return alert("payout is empty.");
            if (!amount) return alert("amount is empty.");

            this.setState({amount: amount, payout: payout});

            if (this.state.gameStatus === "started") {
                this.holdBet();
            } else if (
                this.state.gameStatus === "waiting" ||
                this.state.gameStatus === "busted"
            ) {
                this.setBet();
            }
        }
    };

    handleError(data) {
        if (this._isMounted) {
            this.props.setMeToGame(false);

            this.setState({
                holding: false,
                added: false,
                inputDisabled: false,
                amountDisabled: false,
            });
            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();

            if (!__.isUndefined(data.code)) {
                if (data.code === "credit") {
                    console.log("open wallet");
                    // this.props.setWallet(true, data.uid);
                }
            }

            this.setState({clicked: false});
            showErrorToast(data.message);

            if (!__.isUndefined(data.code)) {
                if (data.code === "version") {
                    wait(3000).then(() => {
                        window.location.reload();
                    });
                }
            }
        }
    }

    setDefaultButton = () => {
        if (this._isMounted) {
            clearInterval(this.state.buttonProgress);

            if (this.state.autoRunning) {
                this.props.toggleSpecial(true);
            } else {
                this.props.toggleSpecial(false);
            }

            let nextText = this.state.autoRunning
                ? "Automatic..."
                : "Multiply (Next Round)";
            this.setState({
                added: false,
                holding: false,
                inputDisabled: false,
                amountDisabled: false,
                buttonType: "btn-bet",
                buttonText: nextText,
            });
        }
    };

    setWaitingButton = () => {
        if (this._isMounted) {
            this.props.toggleSpecial(true);

            this.setState({
                added: true,
                inputDisabled: true,
                amountDisabled: true,
                buttonType: "btn-bet-success-crash text-white btn-p no-shadow",
                buttonText: "Please Wait...",
            });
        }
    };

    setOutButton = () => {
        if (this._isMounted) {
            this.props.toggleSpecial(true);

            this.setState({
                inputDisabled: false,
                amountDisabled: true,
                buttonType: "btn-orange-darkened",
                buttonText: "Cancel (Next Round)",
            });
        }
    };

    setBet() {
        if (this._isMounted) {
            this.setState({clicked: true});
            let {engine, amount, payout, token} = this.state;
            engine.coin = this.props.coin;
            engine.token = token;
            engine.amount = amount;
            engine.payout = payout === "" ? "" : parseInt(payout * 100);
            engine.type = "normal";
            engine.button = "normal";
            engine.play();
        }
    }

    cashOut() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.finish(Game["current_amount"]);
        }
    }

    handlePlay(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                this.props.setMeToGame(true);
            }
        }
    }

    handleFinish(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                //socket.emit(C.CREDIT, encode({ token: this.state.token }));
                clearInterval(this.state.buttonProgress);
                this.props.setWinnerText("  You Cashed Out @" + data.current / 100);
                this.props.setMeToGame(false);
                this.winPopup(data);
                this.playAudio();
                this.setDefaultButton();
            }
        }
    }

    winPopup(data) {
        Swal.fire({
            position: "top",
            customClass: 'swal-height',
            toast: false,
            html:
                '<b style="font-size:15px" class="text-orange">Congratulations!</b> ' +
                '<lottie-player src="https://assets3.lottiefiles.com/packages/lf20_7z1gt2bj/Coin2/Coin.json" background="transparent" speed="2"  style="height: 150px;"   loop  autoplay></lottie-player>' +
                `<p style="font-size:10px"><span class="text-orange">You won </span>Ksh ${formatAmount(
                    data.won
                )}</p>`,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: false,
            backdrop: false,
            timer: 4000,
            width: "100px",
            timerProgressBar: false,
        }).then((result) => {
            if (result.value) {
            }
        });
    }

    checkWaitingGame() {
        if (this._isMounted) {
            //auto
            this.checkAutoRunning();

            this.props.setWinnerText("");

            this.setState({gameStatus: "waiting"});

            if (this.state.holding) {
                this.setState({holding: false});
                this.placeBet();
            }
        }
    }

    checkStartedGame(data = null) {
        if (this._isMounted) {
            let {im_in_game} = this.props;
            let {engine} = this.state;

            let coin = engine.coin;
            let amount = engine.amount;

            this.setState({gameStatus: "started"});

            if (data != null) {
                //console.log("check started, players:", data.players.length);
                if (data.players.length > 0) {
                    data.players.forEach((the_player, i) => {
                        if (the_player.uid === 4153751612) {
                            //this.setState({clicked: true});
                        }
                        //console.log("check started, uid:", the_player.uid, ' >>', this.state.clicked);
                    });
                }
            }

            if (im_in_game === true || this.state.clicked) {
                this.props.toggleSpecial(true);

                this.setState({
                    inputDisabled: false,
                    amountDisabled: false,
                    buttonType: "btn-bet-success-crash",
                    clicked: false,
                });

                let counter = 0;
                let self = this;
                this.state.buttonProgress = setInterval(function () {
                    //let calc = amount * (Game['current_amount'] - 1);
                    let current_amount = Game["current_amount"];
                    if (current_amount >= 1.1) {
                        //self.setState({inputDisabled: false});
                    }

                    let calc = amount * current_amount; //Dan
                    self.setState({
                        buttonText:
                            "Multiply @" +
                            current_amount +
                            " = " +
                            forceSatoshiFormat(calc) +
                            " KES",
                    });
                    counter++;
                }, 50);
            }
        }
    }

    checkBustedGame() {
        if (this._isMounted) {
            this.setState({gameStatus: "busted", clicked: false});

            let {im_in_game} = this.props;

            if (!this.state.holding) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }

            if (im_in_game) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }
        }
    }

    isCorrectBet() {
        let {amount, payout} = this.state;

        if (!isValidNumber(amount)) return false;

        if (amount < 10) {
            return false;
        }
        //todo - payout check
        return true;
    }

    placeBet() {
        if (this._isMounted) {
            let {engine} = this.state;

            engine.isHolding = false;
            this.setWaitingButton();
            this.setBet();
        }
    }

    holdBet() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.isHolding = true;
            this.setState({holding: true});
            this.setOutButton();
        }
    }

    handleBet(e) {
        if (this._isMounted) {
            e.preventDefault();

            let {amount, payout, holding, gameStatus, token} = this.state;
            let {im_in_game} = this.props;

            // Check User
            if (!token) {
                return Event.emit("showAuthModal", true);
            }

            // Check is Correct Bet
            if (!this.isCorrectBet()) {
                return false;
            }

            if (amount < 10) {
                return false;
            }

            if (payout !== "") {
                this.setState({payout: (payout * 1).toFixed(2)});
            }

            // Check Game Status to Play
            switch (gameStatus) {
                case "waiting":
                    this.placeBet();
                    break;

                case "busted":
                    if (holding) {
                        this.setDefaultButton();
                    } else {
                        this.holdBet();
                    }
                    break;

                case "started":
                    if (im_in_game) {
                        this.cashOut();
                    } else if (holding) {
                        this.setDefaultButton();
                    } else {
                        this.holdBet();
                    }
                    break;
            }
        }
    }

    hotkeyChange() {
        if (this._isMounted) {
            if (this.state.hotkey === "OFF") {
                User["denied_hotkey"] = false;
                storage.setKey("hotkey", "ON");
                this.setState({hotkey: "ON"});
            } else {
                storage.setKey("hotkey", "OFF");
                this.setState({hotkey: "OFF"});
            }
        }
    }

    handleHotKey(e) {
        if (this._isMounted) {
            if (User["denied_hotkey"]) {
                User["denied_hotkey"] = false;
                storage.setKey("hotkey", "OFF");
                this.setState({hotkey: "OFF"});
            }

            if (storage.getKey("hotkey") === "ON") {
                let char = e.which || e.keyCode;
                if (char === 98) {
                    this.handleBet(e);
                }
                if (char === 102) {
                    let calc = parseFloat(this.state.payout * 2);
                    let max = Math.max(1.0, calc);
                    this.setState({payout: max.toFixed(2)});
                }
                if (char === 101) {
                    let calc = parseFloat(this.state.payout / 2);
                    let max = Math.max(1.0, calc);
                    this.setState({payout: max.toFixed(2)});
                }

                this.handleInputChange(e);
            }
        }
    }

    setAllIn = (e) => {
        if (e) {
            e.preventDefault();
        }

        let max = this.props.credit;
        if (max === null) return;
        if (max === "NaN") return;

        max = Math.max(max, 0); //maxbet - 3000

        this.setState({amount: max});
        this.setTP(this.state.payout, max);
        storage.setKey("amount", max);
    };

    ask(e) {
        Swal.fire({
            title: "Go All In",
            header: "Hey",
            text: "Are you sure you want to go all in? This will stake all the amount in your wallet.",
            showCancelButton: true,
            confirmButtonColor: "#1BB55C",
            showCloseButton: true,
            cancelButtonColor: "#263238!important",
            cancelButtonText: "No",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.value) {
                this.setAllIn(e);
            }
        });
    }

    autoMultiplaya() {
        Swal.fire({
            title: "Multiplaya",
            header: "Hey",
            text: "This is the your bet on the multiplaya factor when the game will crash or bust. ",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.value) {
                // this.setMax();
            }
        });
    }

    setMin = (e) => {
        e.preventDefault();
        this.setState({amount: "10.00"});
        this.setTP(this.state.payout, 10);
        storage.setKey("amount", 10.0);
    };

    multi = (e) => {
        let max = this.state.amount * 2;
        this.setState({amount: forceSatoshiFormat(max)});
        this.setTP(this.state.payout, max);
        storage.setKey("amount", max);
    };

    divide = (e) => {
        let max = this.state.amount / 2;
        max = Math.max(max, 10);
        this.setState({amount: forceSatoshiFormat(max)});
        this.setTP(this.state.payout, max);
        storage.setKey("amount", max);
    };

    runMyAuto = (e) => {
        this.props.toggleSpecial(!this.state.autoRunning);

        if (this.state.autoRunning) {
            this.setState({
                autoRunning: false,
                autoHeader: "Auto",
                autoBody: "text-danger",
                autoColor: "#304F67",
                buttonText: "BET (Next Round)",
            });
        } else {
            this.setState({
                autoRunning: true,
                autoHeader: "Stop!",
                autoBody: "spinner-border spinner-border-sm text-danger",
                autoColor: "#F49322",
                buttonText: "Automatic...",
            });
        }
    };

    checkAutoRunning = () => {
        if (this.state.autoRunning) {
            this.setWaitingButton();
            let amount = this.state.amount;
            let payout = this.state.payout;

            this.handleAuto({amount, payout});
        }
    };

    setMaxCashout() {
        let max = 1000000;
        this.setState({payout: max});
    }

    stake() {
        Swal.fire({
            title: "Stake",
            header: "Hey",
            html: "<p>Stake is the amount you wish to multiply.</p> <p> You can choose the amount you want to multiply on the next round and click “Multiply (Next Round)” to multiply your money.</p>After joining the round, you will see a “Multiply @” button. Pressing it will multiply your money at the current multiplaya and secure your winnings for that round.</p><p> Minimum stake: 10 KES</p>",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.value) {
                // this.setMax();
            }
        });
    }

    render() {
        let {
            amount,
            inputDisabled,
            amountDisabled,
            payout,
            buttonType,
            buttonText,
            hotkey,
            target_profit,
            winning_chance,
            probability,
            cashoutAmount,
            autoHeader,
            autoBody,
            autoColor,
        } = this.state;
        let {mobile, coin} = this.props;

        //initial render
        if (amount === "NaN") {
            amount = 10.0;
        }

        if (target_profit === "") {
            target_profit = parseFloat(amount * payout - amount).toFixed(2);
            if (target_profit === "NaN" || target_profit < "0") {
                target_profit = "~";
            } else {
                target_profit = toComma(target_profit);
            }
        }

        if (winning_chance === "") {
            winning_chance = parseFloat(99 / payout).toFixed(2);
            if (winning_chance == "NaN" || winning_chance == "Infinity") {
                winning_chance = "~";
            } else {
                winning_chance += "%";
            }
        }

        return (
            <div onKeyPress={(e) => this.handleHotKey(e)}>
                <form
                    className="w-100 mt-1"
                    onSubmit={(e) => {
                        this.handleBet(e);
                    }}
                >
                    <table style={{width: "100%"}}>
                        <tr>
                            <td>
                                <div className={"form-group rev mt-0 mb-0"}>
                                    <Button
                                        variant={"btn orange-btn btn-block " + buttonType}
                                        disabled={inputDisabled}
                                        type="submit"
                                    >
                                        {buttonText}
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </table>

                    <div className={"row top-10 remove-margin"}>
                        <div className={"col-12 padding-0"}>
                            <div className={"form-group mb-1 bet-input payout"}>
                                <p className="text-white margin-bottom-5">
                                    Stake&nbsp;
                                    <i
                                        className="fa fa-info-circle text-gray font-10"
                                        onClick={this.stake}
                                    />
                                </p>
                                <div
                                    className={`${
                                        amount < 10 ? "error-border" : ""
                                    }  btn-group justify-between w-100 row margin-0 remove-margin`}
                                    role="group"
                                    aria-label="range group"
                                >
                                    <input
                                        ref={this.wrapperRef}
                                        style={{'borderRadius': "0.25rem 0px 0px 0.25rem"}}
                                        disabled={amountDisabled}
                                        type="number"
                                        min={10}
                                        step="0.01"
                                        className="form-control bet-amount-input text-left col-6"
                                        id="amount"
                                        name="amount"
                                        placeholder="10.00"
                                        value={amount}
                                        autoComplete={"off"}
                                        onKeyUp={this.handleInputChange}
                                        onChange={this.handleInputChange}
                                    />
                                    <button
                                        disabled={amountDisabled}
                                        type={"button"}
                                        className={"btn btn-sm p-0 bet-container col-2"}
                                        onClick={(e) => this.divide(e)}
                                    >
                                        <div className="bet-amount-bg">1&frasl;2</div>
                                    </button>
                                    <button
                                        disabled={amountDisabled}
                                        type={"button"}
                                        className={"btn btn-sm p-0 bet-container col-2"}
                                        onClick={(e) => this.multi(e)}
                                    >
                                        <div className="bet-amount-bg">
                                            <span>2x</span>
                                        </div>
                                    </button>
                                    <button
                                        disabled={amountDisabled}
                                        type={"button"}
                                        className={"btn btn-sm p-0 pr-1 bet-container col-2"}
                                        onClick={(e) => this.ask(e)}
                                    >
                                        <div
                                            className="bet-amount-bg"
                                            style={{border: "none"}}
                                            onClick={(e) => this.ask(e)}
                                        >
                                            All In
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={"row top-10 remove-margin"}>
                        <div
                            className={"col-12 top-10 padding-0"}
                            style={{marginTop: "5px"}}
                        >
                            <div
                                className={"form-group mb-1 bet-input payout"}
                                style={{display: "flex"}}
                            >
                                <div style={{width: '50%'}}>
                                    <p className="text-white margin-bottom-5">
                                        Multiplaya&nbsp;
                                        <i
                                            className="fa fa-info-circle text-gray font-10"
                                            onClick={this.autoMultiplaya}
                                        />
                                    </p>
                                    <div
                                        className={" btn-group justify-between w-100 row margin-0 remove-margin"}
                                        role="group"
                                        aria-label="range group"
                                    >
                                        <input
                                            style={{borderRadius: "0.25rem"}}
                                            disabled={amountDisabled}
                                            type="number"
                                            className="form-control w-90 bet-amount-input text-left"
                                            id="payout"
                                            name="payout"
                                            max="99999"
                                            step="0.01"
                                            min="1.01"
                                            placeholder="2"
                                            value={payout}
                                            autoComplete={"off"}
                                            onKeyUp={this.handleInputChange}
                                            onChange={this.handleInputChange}
                                        />
                                    </div>
                                </div>

                                <div style={{width: '50%'}}>
                                    <p className="text-white margin-bottom-5">
                                        Win Chance (%)&nbsp;
                                        {/* <i className='fa fa-info-circle text-gray font-10' onClick={this.autoMultiplaya}/> */}
                                    </p>
                                    <div
                                        className={"btn-group w-100 row margin-0 remove-margin"}
                                        role="group"
                                        aria-label="range group"
                                    >
                                        <div className="d-flex w-100">
                                            <input
                                                style={{borderRadius: "0.25rem"}}
                                                disabled={amountDisabled}
                                                type="number"
                                                className="form-control w-90 bet-amount-input flex-end text-left"
                                                id="probability"
                                                name="probability"
                                                max="98.00"
                                                step="0.01"
                                                min="0.01"
                                                placeholder="2"
                                                value={probability}
                                                autoComplete={"off"}
                                                onKeyUp={this.handleInputChange}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <p className="text-white margin-bottom-5">
                                    Profit on win: &nbsp;
                                    <span
                                        className="w-90 text-left text-white text-success font-12"
                                        id="cashout"
                                    >
                    {target_profit}
                  </span>
                                </p>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        );
    }
}

class HotKey extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: "pulse",
        };
    }

    toggleModal = (e) => {
        if (e !== undefined) e.preventDefault();

        this.setState({
            show: !this.state.show,
            effect: !this.state.show ? "pulse" : "zoomOut",
        });
    };

    render() {
        return (
            <>
                <button
                    className={"btn btn-xs stc mt-1 pl-1"}
                    onClick={(e) => this.toggleModal(e)}
                >
                    <i className={"cp mdi mdi-information text-info font-15"}/>
                </button>
                <Modal
                    size="md"
                    centered={true}
                    backdrop="static"
                    show={this.state.show}
                    onHide={this.toggleModal}
                    aria-labelledby="help-lg-modal"
                    className={"animated " + this.state.effect}
                >
                    <Modal.Header>
                        Hot Keys
                        <button
                            type="button"
                            className="close p-1"
                            onClick={(e) => this.toggleModal(e)}
                        >
                            <i className={"mdi mdi-close"}/>
                        </button>
                    </Modal.Header>
                    <Modal.Body closeButton className="modal-helper text-center">
                        <Row className="shortcut-helper">
                            <Col sm="12">
                                <span className="badge badge-soft-danger">B</span>
                                <i className="mdi mdi-arrow-right align-middle"></i>
                                <span className="badge badge-soft-secondary"> BET</span>
                            </Col>
                            <Col sm="12" className="my-2">
                                <span className="badge badge-soft-danger"> E</span>
                                <i className="mdi mdi-arrow-right align-middle"></i>
                                <span className="badge badge-soft-secondary"> HALF PAYOUT</span>
                            </Col>
                            <Col sm="12">
                                <span className="badge badge-soft-danger"> F</span>
                                <i className="mdi mdi-arrow-right align-middle"></i>
                                <span className="badge badge-soft-secondary">
                  {" "}
                                    DOUBLE PAYOUT
                </span>
                            </Col>
                        </Row>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

ManualBet.propTypes = {
    coin: PropTypes.string,
    im_in_game: PropTypes.bool,
    credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
    coin: state.items.coin,
    im_in_game: state.items.im_in_game,
    credit: state.items.credit,
});

export default connect(mapStateToProps, {
    gameCoin,
    setWinnerText,
    setMeToGame,
    setWallet,
})(ManualBet);
