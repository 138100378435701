import React from "react";
import { Dropdown, NavItem } from "react-bootstrap";
import UserModal from "../../../Components/User/Stat/Modal";
import storage from "../../../../Storage";
import { defaultAvatar } from "../../../../Helper";
import Logout from "../../../Pages/Auth/Logout";
import UserAccount from "../UserAccount";
import Statistics from "../Statistics";
import { NavLink } from "react-router-dom";
import { Event, isMobile } from "../../../../Helper";
import VipSection from "../Vip";
import { showWarningToast } from "../../../../toastNotifications";
import { UpdateHomeIcon, UpdateHomeIconActive } from "../ClickService";

class Profile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      level: 1,
      name: storage.getKey("name"),
      phone: storage.getKey("phone"),
      avatar:
        storage.getKey("avatar") !== "false"
          ? storage.getKey("avatar")
          : defaultAvatar,
      token: storage.getKey("token"),
      showDropDown: "false",
    };
  }
  notLogged() {
    showWarningToast("Please Login to use this option.");
    return;
  }
  handleClick(e) {
    console.log("clicked");
    UpdateHomeIcon();
  }
  showDeposit() {
    Event.emit("show_deposit");
  }
  render() {
    const { t } = this.props;

    return (
      <Dropdown className="justify-center" drop="up" key="up">
        <Dropdown.Toggle variant="b btn-sm" id="dropdown-split-user">
          <NavItem
            onClick={() => this.handleClick}
            style={{ width: "19%" }}
            className={"bottom-nav-buttons"}
            key={`tab-5`}
          >
            <NavLink to={"/"}>
              <div
                className={
                  "row d-flex flex-column justify-content-center align-items-center gray-text bottom-items"
                }
              >
                <i className={"fa fa-user bottom-menu-icon"} />
                <div
                  className={"bottom-tab-label font-9 font-9"}
                  style={{ lineHeight: "15px" }}
                >
                  Profile
                </div>
              </div>
            </NavLink>
          </NavItem>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className={`${
            storage.getKey("token") === null ? "no-display" : ""
          } droper user-dropdown-detail`}
        >
          <div className="user-links text-weight-300">
            <Dropdown.Item
              as={"button"}
              className={"animated fadeIn prof-link"}
            >
              <NavLink
                to={"#"}
                className="nav-link text-left header-color"
                onClick={this.showDeposit}
              >
                <i className={"text-white fa fa-wallet"}></i>
                {"  Wallet"}
              </NavLink>
            </Dropdown.Item>
            <Dropdown.Item
              as={"button"}
              className={"animated fadeIn prof-link"}
            >
              <UserAccount t={t} />
            </Dropdown.Item>
            <Dropdown.Item
              as={"button"}
              className={"animated fadeIn prof-link"}
            >
              <VipSection t={t} />
            </Dropdown.Item>
            <Dropdown.Item
              as={"button"}
              className={"animated fadeIn prof-link"}
            >
              <Statistics t={t} />
            </Dropdown.Item>
            <Dropdown.Item
              as={"button"}
              className={"animated fadeIn prof-link"}
            >
              <Logout t={t} />
            </Dropdown.Item>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

class UserInfo extends React.Component {
  makeStars = (num) => {
    let stars = 5;
    let userLevel = stars - parseFloat(num);
    var g = [],
      o = [],
      f = [];
    for (var i = 0; i < userLevel; i++) {
      g.push(<i className={"mdi mdi-star font-15"} />);
    }
    for (var i = 0; i < num; i++) {
      o.push(<i className={"mdi mdi-star text-success font-20"} />);
    }
    f.push(o, g);
    return f;
  };
  render() {
    const { name, level, avatar, t, phone } = this.props;
    return (
      <>
        <div className="user-bar">
          {
            <UserModal
              phone={phone}
              t={t}
              username={name}
              menu={true}
              avatar={avatar}
            />
          }
          <span className="text-white">{name}</span>
          <br />
          <span>{this.makeStars(level)}</span>
        </div>
      </>
    );
  }
}

export default Profile;
