import React from "react";
import PropTypes from "prop-types";
import {Table} from "react-bootstrap";
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import {makeStyles, useTheme} from '@material-ui/core/styles';
import storage from "../../../Storage";
import UserModal from "../../Components/User/Stat/Modal";
import {__, isMobile, wait, formatAmount} from "../../../Helper";
import Engine from "./Engine";
import socket from "../../../Socket";
import PlayaIcon from '../../../Static/images/playa1.svg'
import Money from '../../../Static/images/money.svg'
import Dollar from '../../../Static/images/dollar.svg'
import Profit from '../../../Static/images/profit.svg'

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={0}>
                    <Typography component={'span'}>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};


function PlayersPanel(props) {
    let {game} = props;

    const engine = Engine;
    return (
        <AllBets engine={engine} game={game}/>
    );
}

class AllBets extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            players: [],
            winners: [],
            failed: [],
            isWinner: false
        };
    }

    onChange(event, players, winner) {
        this.setState({players: players, isWinner: winner})
    }

    onWinner(event, winners) {
        this.setState({winners: winners})
    }

    render() {
        const {players, winners} = this.state;
        return <Child engine={this.props.engine} onChange={this.onChange.bind(this, players)} onWinner={this.onWinner.bind(this, winners)}/>
    }
}

class Child extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            playersObject: [],
            winnersObject: []
        };
    }

    componentDidMount() {
        this._isMounted = true;
        if (this._isMounted) {
            let {engine} = this.props;

            wait(100).then(() => {
                engine.getPlayers();
            });

            engine.trigger.on("finish_crash", data => this.syncFinishPlayer(data));
            engine.trigger.on("play_crash", data => this.syncPlayPlayer(data));
            engine.trigger.on("busted_crash", data => this.busted(data));
            engine.trigger.on("waiting_crash", data => this.isWaiting(data));
            engine.trigger.on("game_status", data => this.gameSync(data));
            engine.trigger.on("game_players", data => this.gameSync(data));
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    syncPlayPlayer(data) {
        if (this._isMounted) {
            let add = this.state.playersObject.concat(data);
            this.setState({playersObject: add});
            this.props.onChange(this.state.playersObject)
        }
    }

    syncFinishPlayer(data) {
        if (this._isMounted) {
            let index = __.findIndex(this.state.playersObject, function (o) {
                return o.uid === data.uid;
            });
            this.state.playersObject.splice(index, 1);
            let add = this.state.winnersObject.concat(data);
            this.setState({winnersObject: add});
            this.props.onWinner(this.state.winnersObject)
        }
    }

    gameSync(data) {
        if (this._isMounted) {
            sortByAmount(data.players).forEach((item, i) => {
                this.state.playersObject.push(item);
            });
            this.props.onChange(this.state.playersObject);

            sortByCashout(data.winners).forEach((item, i) => {
                this.state.winnersObject.push(item);
            });
            this.props.onWinner(this.state.winnersObject, true);
        }
    }

    busted(data) {
        if (this._isMounted) {
            //this.setState({playersObject: [], winnersObject: []});
            this.state.playersObject.shift();
            this.state.winnersObject.shift();
            this.gameSync(data);
        }
    }

    isWaiting(data) {
        if (this._isMounted) {
            this.setState({playersObject: [], winnersObject: []});

            sortByAmount(data.players).forEach((item, i) => {
                this.state.playersObject.push(item);
            });
            this.props.onChange(this.state.playersObject);
        }
    }

    render() {
        return (
            <ShowUserTable
                engine={this.props.engine}
                players={this.state.playersObject}
                winners={this.state.winnersObject}
            />
        )
    }
}

class UsersTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (isMobile()) {
            this.setState({font: 'font-10'})
        }
    }

    render() {
        let playerRows = [];
        let winnerRows = [];
        let checkExists = [];
        let playing_now = (this.props.players.length + this.props.winners.length);
        this.props.engine.trigger.emit('playing_now', playing_now);

        if (this.props.players.length > 0) {
            this.props.players.forEach((currentPlayer, i) => {
                if (!checkExists.includes(currentPlayer.name)) {
                    checkExists.push(currentPlayer.name);
                    if (currentPlayer.session === 'crash')
                        playerRows.push(<UserRow engine={this.props.engine} currentPlayer={currentPlayer} key={i} isWinner={false} isFailed={false}/>);
                }
            });
        }

        if (this.props.winners.length > 0) {
            this.props.winners.forEach((currentPlayer, i) => {
                if (!checkExists.includes(currentPlayer.name)) {
                    checkExists.push(currentPlayer.name);
                    if (currentPlayer.session === 'crash')
                        winnerRows.push(<UserRow engine={this.props.engine} currentPlayer={currentPlayer} key={i} isWinner={true} isFailed={false}/>);
                }
            });
        }

        return (
            <>
                <div className={'table-responsive last-bets players-table cq num-style mb-0'} style={{height:"25vh"}}>
                    
                        <Table className={"game-table  mb-0 " + this.state.font} >
                            <thead className="sticky-top">
                                <tr>
                                    <th className={'text-white font-12 table-head'} style={{borderTopLeftRadius:"5px",paddingLeft:"10px"}}>
                                        Multiplayas
                                    </th>
                                    <th className="text-white font-12 table-head">
                                        @
                                    </th>
                                    <th style={{whiteSpace: 'nowrap'}} className={'text-white font-11 table-head'}>
                                        Stake
                                    </th>
                                    <th style={{whiteSpace: 'nowrap',borderTopRightRadius:"5px"}} className={'text-white font-11 table-head'}>
                                        Profit
                                    </th>
                                </tr>
                            </thead>
                            <tbody style={{textTransform: 'none'}}>
                            {playerRows}
                            {winnerRows}
                            </tbody>
                        </Table>
                   
                </div>
            </>
        );
    }
}

class ShowUserTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let size = 50;
        let players = sortByAmount(this.props.players);
        let winners = sortByCashout(this.props.winners);
        players.length = Math.min(players.length, size);
        winners.length = Math.min(winners.length, size);
        return (
            <UsersTable
                engine={this.props.engine}
                players={players}
                winners={winners}
            />
        );
    }
}


class UserRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        if (this.props.currentPlayer.uid === undefined || this.props.currentPlayer.name === undefined)
            return null;

        let profit = (this.props.currentPlayer.won) ? formatAmount(this.props.currentPlayer.won) : '-';

        let bonus_text = '';
        if(this.props.currentPlayer.bonus > 0) {
            bonus_text = ' +' + this.props.currentPlayer.bonus;
        }

        let self = '', type = 'white text-warning', cashout = '-';

        let bet_amount = this.props.currentPlayer.amount;
        let game_type = (this.props.currentPlayer.type === 'trenball') ? ' (JS)' : '';

        if (this.props.currentPlayer.token === storage.getKey('token')) {
            self = 'bg-soft-warning';
        }

        if (this.props.engine.gameStatus === 'busted' && profit === '-') {
            type = 'text-danger';
        }

        if (profit !== '-') {
            type = 'text-success';
            cashout = (this.props.currentPlayer.current / 100).toFixed(2) + 'x';
        }

        if (this.props.currentPlayer.won === 0) {
            type = 'text-danger';
            profit = 0;
            cashout = '2.00x';
        }

        return (
            <>
                
                    <tr key={this.props.currentPlayer.uid} className={type + ' ' + self + ' q-crash player-row text-center'}>
                        <td className="text-left font-11" style={{paddingLeft:"10px"}}>
                            <UserModal username={this.props.currentPlayer.name} queue={true} avatar={this.props.currentPlayer.avatar} isWinner={profit}/>
                        </td>
                        <td className="text-left font-11" size="20">
                            <img src={Money} alt="money icon" />
                            {cashout}
                        </td>
                        <td className="text-left font-11 num-style">
                            <img src={Dollar} alt="money icon" />
                            <span>{formatAmount(bet_amount, 0)}{bonus_text}{game_type}</span>
                        </td>
                        <td className="text-left font-11 num-style">
                            <img src={Profit} alt="money icon" />
                            <span style={{fontWeight: 'bold'}}>{profit}</span>
                        </td>
                    </tr>
                
            </>
        )
    }
}

function sortByAmount(input) {
    function r(c) {
        return c.amount ? -c.amount : null;
    }

    return __.sortBy(input, r);
}

function sortByCashout(input) {
    function r(c) {
        return c.current ? -c.current : null;
    }

    return __.sortBy(input, r);
}

export default PlayersPanel;