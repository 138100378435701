import React, {Component} from 'react';
import {decode, encode, wait} from "../../../Helper";
import socket from "../../../Socket";
import C from "../../../Constant";
import { showErrorToast, showSuccessToast } from '../../../toastNotifications';

class Forget extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: true,
            disabled: false,
            phone: ''
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentWillMount() {
        wait(500).then(() => {
            this.setState({loading: false});
        })
    }

    componentDidMount() {
        socket.on(C.RESET_PASSWORD, data => this.resetPassword(decode(data)));
    }

    handleShow(e) {
        this.props.clicked();
        this.setState({show: true});
    }

    handleClose() {
        this.setState({show: false});
    }

    resetPassword = (data) => {
        let response = data;
        const {t} = this.props;

        this.setState({disabled: false});

        if (response.status) {
             showSuccessToast("Check your phone for a new Password");
             return;
        } else {
             showErrorToast("Account not found! Help, call +254 20 790 0779");
             return;
        }
    };

    submitForm(e) {
        e.preventDefault();
        const {t} = this.props;

        this.setState({disabled: true});

        wait(700).then(() => {
            socket.emit(C.RESET_PASSWORD, encode({phone: this.state.phone}));
        })
    }

    back = () => {
        this.props.clicked();
    };

    render() {
        return (
            <>
                {this.state.loading ?
                    <>
                        <div className="text-center">
                            <div className="spinner-border text-orange my-3 user-loader" role="status"/>
                        </div>
                    </>
                    :
                    <>
                        <div className="px-3">
                            <div className="text-center auth-logo-text">
                                <p className="mt-2 text-white">Retrieve Your Password</p>
                            </div>
                            <form className="my-4" onSubmit={(e) => this.submitForm(e)}>
                                <div className="form-group text-center">
                                    <div className="form-group">
                                        <div className="input-group">
                                            <input 
                                                type="number" className="input-box" 
                                                name="phone" placeholder={"0722********"} 
                                                autocomplete={"off"} style={{height: 40}}
                                                onChange={e => this.setState({phone: e.target.value})}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="form-group mt-2 text-center">
                                    <button type="submit" className="btn orange-btn no-shadow btn-block" disabled={this.state.disabled}>
                                        Send Password
                                    </button>
                                    <p className="padding-2rem text-white" onClick={this.back}>
                                        <i className="mdi mdi-refresh"/> Back to Login
                                    </p>
                                </div>
                            </form>
                        </div>
                    </>
                }
            </>
        );
    }
}

export default Forget;