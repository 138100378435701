import React from "react";
import storage from "../../../Storage";
import socket from "../../../Socket";
import {__, encode, wait, decode} from "../../../Helper";
import C from "../../../Constant";
import { showErrorToast } from "../../../toastNotifications";

class Withdraw extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            loading: true,
            phone: storage.getKey('phone'),
            name: storage.getKey('name'),
            withdraw_to: 'wallet',
            with_to: 'Wallet',
            wait_message: 'Please wait...',
        };

        this.handleInputChange = this.handleInputChange.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        socket.on(C.EARNINGS_WITHDRAW, data => this.withdrawalResponse(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    handleInputChange = (event) => {
        let target = event.target;
        let value = target.value;
        let name = target.name;
        switch (name) {
            case 'amount':
                this.setState({amount: value});
                break;
            case 'withdraw_to':
                this.setState({withdraw_to: value});
                let w_to = (value === 'wallet') ? 'Wallet' : 'M-PESA';
                this.setState({with_to: w_to});
                break;
            default:
                break;
        }
    }

    //submit
    submit = (e) => {
        e.preventDefault();

        socket.emit(C.EARNINGS_WITHDRAW, encode({
            token: this.state.token,
            coin: 'kes',
            withdraw_to: this.state.with_to,
            amount: this.state.amount,
        }));

        wait(600).then(() => {
            this.setState({submitted: true});
        })
    }

    //response
    withdrawalResponse(data) {
        if (this._isMounted) {
            wait(1000).then(() => {
                if (!data) {
                    showErrorToast('Request failed!');
                }

                let message = __.toString(data.status);
                let reason = data.reason; //error or success
                this.setState({wait_message: message});

                if(reason === 'success') {
                    showErrorToast('Withdrawal from wallet successful!')
                    socket.emit(C.MY_AFF, encode({token: this.state.token}));
                    socket.emit(C.CREDIT, encode({token: this.state.token}));

                    this.setState({loading: false, submitted: true});
                    wait(3000).then(() => {
                        this.setState({submitted: false, wait_message: 'Please wait...'});
                    });
                } else {
                    showErrorToast('Withdrawal from wallet failed!')
                    wait(1500).then(() => {
                        this.setState({loading: false, submitted: false, wait_message: 'Please wait...'});
                    });
                }
            });
        }
    }

    render() {
        let {loading, with_to, earnings, wait_message} = this.state;
        let my_earn = (this.props.earnings > '0') ? this.props.earnings : '100';

        return (
            <>
                <div className={"row"}>
                    <div className={"col-sm-12"}>
                        <div className="form-group">
                            {
                                this.state.submitted ?
                                    <span className="text-white"><div className="spinner-border spinner-border-sm text-white" role="status"/> {wait_message}</span>
                                    :
                                    <form onSubmit={(e) => {this.submit(e)}}>
                                        <div className="input-group">
                                            <select style={{border: '3px solid white', borderRadius: '4px 0 0 4px', backgroundColor: 'bisque'}} className={"input-group-append"} name={"withdraw_to"} value={this.state.withdraw_to} onChange={this.handleInputChange}>
                                                <option value="wallet">Wallet</option>
                                                <option value="lime">M-PESA</option>
                                            </select>
                                            <input placeholder={"Enter amount e.g " + my_earn} type="text" name={'amount'} className={'form-control'} style={{background: "white", color: 'black'}} onChange={this.handleInputChange} required={true} autoComplete={'off'}/>
                                        </div>
                                        <br/>
                                        <button type="submit" className="btn btn-success-2 btn-block btn-md btn-block no-shadow"><i className={"fa fa fa-shopping-cart"}></i> Withdraw to <strong>{with_to}</strong></button>
                                    </form>
                            }
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Withdraw;