import React, { Component } from "react";
import { Tab, Tabs } from "react-bootstrap";
import storage from "../../../../Storage";
import { Event } from "../../../../Helper";
import { NavLink } from "react-router-dom";
import LeaderboardTab from "./Leaderboard";
import MyBetsTab from "./MyBets";
import {
  ChatClick,
  NotifClicker,
  SettingsClick,
  UpdateHomeIcon,
  UpdateHomeIconActive,
  VipClick,
  WalletClick,
} from "../ClickService";
import { showWarningToast } from "../../../../toastNotifications";
import { Modal } from "react-bootstrap";

class Statistics extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initState: 0,
      show: this.props.show ? this.props.show : false,
      classicDisabled: false,
      specialDisabled: false,
      jackpotDisabled: false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);

    this.toggleClassic = this.toggleClassic.bind(this);
    this.toggleSpecial = this.toggleSpecial.bind(this);
    this.toggleJackpot = this.toggleJackpot.bind(this);
  }

  componentDidMount() {
    Event.on("show_stats", this.handleShow);
  }

  handleClose() {
    this.setState({ show: false, effect: "zoomOut" });
    UpdateHomeIconActive();
  }

  handleShow() {
    if (storage.getKey("token") === null) {
      showWarningToast("Please Login to use this option.");
      return;
    }

    this.setState({ show: true, effect: "pulse" });
    NotifClicker();
    SettingsClick();
    ChatClick();
    WalletClick();
    VipClick();
    UpdateHomeIcon();
  }

  toggleClassic(status) {
    this.setState({ classicDisabled: status });
  }

  toggleSpecial(status) {
    this.setState({ specialDisabled: status });
  }

  toggleJackpot(status) {
    this.setState({ jackpotDisabled: status });
  }

  render() {
    let { classicDisabled, specialDisabled } = this.state;

    return (
      <>
        {!this.props.show && (
          <NavLink
            to={"#"}
            className="nav-link text-left header-color"
            onClick={(e) => this.handleShow(e)}
          >
            <i className={"fa fa-bar-chart text-white"} />
            {" Statistics"}
          </NavLink>
        )}

        <Modal
          size="md"
          centered={true}
          backdrop={"static"}
          show={this.state.show}
          onHide={this.handleClose}
          onExited={this.handleClose}
          aria-labelledby="setting-lg-modal"
          className={"stats-container animated " + this.state.effect}
        >
          <Modal.Header className="Header">
            Statistics
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <i className={"fa fa-close font-16 font-300 text-orange"} />
            </button>
          </Modal.Header>
          <Modal.Body>
            <div className="padding-2rem ">
              <Tabs
                fill
                defaultActiveKey="leaderboard"
                id="uncontrolled-tab-bet"
                style={{ padding: "5px" }}
                className="tabs-container table-card-container margin-10"
              >
                {/* <Tab tabClassName={'w-100'} disabled={classicDisabled} eventKey="manual" title="Happy Hour">
                                    <HappyHourTab/>
                                </Tab> */}
                <Tab
                  tabClassName={"w-100"}
                  disabled={specialDisabled}
                  eventKey="leaderboard"
                  title="Leaderboard"
                >
                  <LeaderboardTab />
                </Tab>
                <Tab
                  tabClassName={"w-100"}
                  disabled={specialDisabled}
                  eventKey="mybets"
                  title="My Bets"
                >
                  <MyBetsTab />
                </Tab>
              </Tabs>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Statistics;
