import React from "react";
import {Card, Tab, Tabs} from "react-bootstrap";
import ManualBet from './classic/ManualBet';
import ClassicAutoBet from "./classic/ClassicAutoBet";
import {isMobile, getUID} from "../../../../Helper";
import Engine from "../Engine";

class Classic extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bet: 'manual',
            uid: getUID,
            classicDisabled: false,
            specialDisabled: false,
            playing_now: 0
        };

        this.toggleClassic = this.toggleClassic.bind(this);
        this.toggleSpecial = this.toggleSpecial.bind(this);
    }

    componentDidMount() {
        const engine = Engine;

        if (isMobile()) {
            this.setState({padding: 'p-1', margin: 'mt-1'})
        }

        // engine.trigger.on("playing_now", (data) => this.setUsersPlaying(data));
    }

    toggleClassic(status) {
        this.setState({classicDisabled: status});
    }

    toggleSpecial(status) {
        this.setState({specialDisabled: status});
    }

    setUsersPlaying(data) {
        this.setState({playing_now: data});
    }

    render() {
        let {token, isLogged, mobile} = this.props;
        let {classicDisabled, specialDisabled, playing_now} = this.state;

        return (
            <Card className={"no-shadow mb-1 h-bet inner-dark-bg"}>
                <Card.Body className="bet-form p-0 no-shadow margin-bottom-3">
                    <>
                        <div className="top-10">
                            <Tabs fill defaultActiveKey="manual" id="uncontrolled-tab-bet" className="tabs-container  manual-auto-bar">
                                <Tab tabClassName={'w-100'} disabled={classicDisabled} eventKey="manual" title="Manual">
                                    <ManualBet toggleClassic={this.toggleClassic} toggleSpecial={this.toggleSpecial} token={token} isLogged={isLogged} mobile={mobile}/>
                                </Tab>
                                <Tab tabClassName={'w-100'} disabled={specialDisabled} eventKey="special" title="Auto">
                                    <ClassicAutoBet toggleClassic={this.toggleClassic} toggleSpecial={this.toggleSpecial} token={token} isLogged={isLogged} mobile={mobile}/>
                                </Tab>
                            </Tabs>
                        </div>
                    </>
                </Card.Body>
            </Card>
        );
    }
}

export default Classic;