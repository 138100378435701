import React from 'react'
import {Nav, NavItem} from 'reactstrap';
import {isMobile} from "../../../../Helper";
import Login from "../../Auth/Login";
import Register from "../../Auth/Register";
import PageLogo from '../../../../Static/images/logo.svg'

let show = true;

class Header extends React.Component {
    collapse = () => {
        show = !show;
    }

    render() {
        const {t} = this.props;
        return (
            <nav id={"topbar"} className="navbar navbar-expand-md navbar-light sticky-top mobile-topbar" role="navigation">
                <div className="container-fluid pl-0 pr-0">
                    <a className="navbar-brand"  href="/">
                        <span>
                            {isMobile() ?
                                <img src={PageLogo} className="mobile_logo"/> :
                                <img src={PageLogo} className="logo-sm"/>
                            }
                        </span>
                    </a>
                    <Nav className="ml-auto">
                        <NavItem className='register-btn'>
                            <Register t={t}/>
                        </NavItem>
                        <NavItem className="login-btn">
                            <Login t={t}/>
                        </NavItem>
                    </Nav>
                </div>
            </nav>
        );
    }
}

export default Header;