import React, {Component} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {Button, Tab, Tabs} from "react-bootstrap";
import storage from "../../../../../Storage";
import Engine from "../../Engine";
import {gameCoin} from "../../../../../actions/gameCoin";
import {setWallet} from "../../../../../actions/gameWallet";
import {setWinnerText, setMeToGame} from "../../../../../actions/crashGame";
import {
    Event,
    __,
    isValidNumber,
    forceSatoshiFormat,
    User,
    wait,
    Game,
    toComma,
    formatAmount, encode, APP_VERSION, decode,
} from "../../../../../Helper";
import socket from "../../../../../Socket";
import {Switch} from "@mui/material";
import Swal from "sweetalert2";

class ClassicAutoBet extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            engine: null,
            buttonText: "Start Autobet",
            buttonType: "btn-bet",
            inputDisabled: false,
            amountDisabled: false,
            buttonProgress: null,
            gameStatus: null,
            clicked: false,
            added: false,
            holding: false,
            payout: storage.getKey("payout") ? storage.getKey("payout") : 2.00,
            amount: storage.getKey("amount") ? storage.getKey("amount") : 10.0,
            number_of_bets: storage.getKey("number_of_bets") ? storage.getKey("number_of_bets") : 10,
            target_profit: "",
            winning_chance: "",
            token: storage.getKey("token") ? storage.getKey("token") : null,
            autoRunning: false,
            autoBody: "text-danger",
            autoColor: "#304F67",
            onWinResetDisabled: false,
            onWinIncreaseDisabled: false,
            advancedOn: false,
            probability: storage.getKey("probability") ? storage.getKey("probability") : "",
            cashoutAmount: "",
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleBet = this.handleBet.bind(this);
        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.toggleOnWinReset = this.toggleOnWinReset.bind(this);
        this.toggleOnWinIncrease = this.toggleOnWinIncrease.bind(this);
        this.toggleAdvanced = this.toggleAdvanced.bind(this);
    }

    componentDidMount() {
        if (this._isMounted) {
            const engine = Engine;

            this.setState({engine: engine});

            //Load Coin From Redux
            this.props.gameCoin();

            document.addEventListener("mousedown", this.handleClickOutside);

            // Game Event
            engine.trigger.on("game_status", (data) => this.checkStatus(data));

            engine.trigger.on("waiting_crash", () => this.checkWaitingGame());
            engine.trigger.on("busted_crash", () => this.checkBustedGame());
            engine.trigger.on("started_crash", () => this.checkStartedGame());

            // User Event
            engine.trigger.on("play_crash", (data) => this.handlePlay(data));
            engine.trigger.on("finish_crash", (data) => this.handleFinish(data));

            //Error
            engine.trigger.on("error_crash", (data) => this.handleError(data));

            // Stop manual bet
            engine.trigger.on("stop_playing", () => this.stopManual());

            //Events on auto bet
            engine.trigger.on("auto_bet", (data) => this.handleAuto(data));

            socket.on('AUTO_SIMPLE', (data) => this.playedSimple(decode(data)));
        }
    }

    componentWillMount() {
        this._isMounted = true;
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.removeEventListener("mousedown", this.handleClickOutside);
    }

    toggleAdvanced() {
        this.setState({advancedOn: !this.state.advancedOn});
    }

    //set target profit
    setTP(payout, amount) {
        let my_target_profit = parseFloat(payout * amount - amount).toFixed(2);
        if (my_target_profit === "NaN" || my_target_profit <= 0) {
            my_target_profit = "~";
        } else {
            my_target_profit = toComma(my_target_profit);
        }
        this.setState({target_profit: my_target_profit});
    }

    //set winning change
    setWC(payout) {
        let my_wc = parseFloat(99 / payout).toFixed(2);
        if (my_wc === "NaN" || my_wc <= 0 || my_wc === "Infinity") {
            my_wc = "~";
        } else {
            my_wc += "%";
        }
        this.setState({winning_chance: my_wc});
    }

    handleClickOutside(event) {
        if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
            let value;
            try {
                if (this.state.payout === "") {
                    value = 0.0;
                } else {
                    value = parseFloat(this.state.payout);
                }
            } catch (e) {
                value = 0.0;
            }

            if (value < 1.01) {
                value = 1.01;
            } else if (value > 1000000) {
                value = 1000000;
            }

            this.setState({payout: parseFloat(value).toFixed(2)});
            const prob = (99 / value).toFixed(2);
            this.setState({probability: prob});
            storage.setKey("probability", prob);
            //amount
            let amount = this.state.amount;
            try {
                if (amount === "") {
                    amount = 0.0;
                } else {
                    amount = parseFloat(amount);
                }
            } catch (e) {
                amount = 0.0;
            }
            amount = Math.max(10.0, parseFloat(amount));
            if (amount > 3000) {
                //amount = 3000; //maxbet - 3000 | remember to also set in the input
            }

            this.setState({amount});

            if (amount === "NaN") {
                amount = 10.0;
            }
            this.setState({amount});

            //set advice values
            this.setTP(value, amount);
            this.setWC(value);
        }
    }

    handleInputChange(event) {
        let target = event.target;
        let value = target.value;
        let name = target.name;

        if (name === "amount") {
            if (!isValidNumber(value)) return;

            this.setTP(this.state.payout, value);
            this.setState({amount: value});
            storage.setKey("amount", value);
        }

        if (name === "payout") {
            if (value >= 999999) return;

            const prob = (99 / value).toFixed(2);
            this.setState({probability: prob});
            storage.setKey("probability", prob);

            this.setTP(value, this.state.amount);
            this.setWC(value);
            storage.setKey("payout", value);
        }

        if (name === "probability") {
            if (value > 98.02 || value < 0.01) return;

            const cashOut = 99 / value;
            this.setWC(cashOut);
            this.setTP(cashOut, this.state.amount);
            this.setWC(cashOut);

            storage.setKey("probability", value);
            storage.setKey("payout", value);
        }

        if (name === 'number_of_bets') {
            storage.setKey("number_of_bets", value);
        }

        this.setState({[name]: value});
    }

    stopManual() {
        if (this.state.autoRunning) {
            this.props.toggleSpecial(true);
        } else {
            this.props.toggleSpecial(false);
        }

        let nextText = this.state.autoRunning ? "Automatic..." : "Start Autobet";
        this.setState({
            started: false,
            buttonText: nextText,
            inputDisabled: false,
            amountDisabled: false,
        });
    }

    checkStatus(data) {
        if (this._isMounted) {
            //console.log("game status, data:", data);

            switch (data.status) {
                case "waiting":
                    this.checkWaitingGame();
                    break;
                case "started":
                    wait(1000).then(() => {
                        this.checkStartedGame(data);
                    });
                    break;
                case "busted":
                    this.checkBustedGame();
                    break;
                default:
            }
        }
    }

    handleAuto = (data) => {
        if (this._isMounted) {
            let {amount, payout} = data;

            //console.log("handle auto: st -", this.state.gameStatus, " | data - ", data);

            if (!payout) return alert("payout is empty.");
            if (!amount) return alert("amount is empty.");

            this.setState({amount: amount, payout: payout});

            if (this.state.gameStatus === "started") {
                this.holdBet();
            } else if (this.state.gameStatus === "waiting" || this.state.gameStatus === "busted") {
            }
        }
    };

    handleError(data) {
        if (this._isMounted) {
            this.props.setMeToGame(false);

            this.setState({
                holding: false,
                added: false,
                inputDisabled: false,
                amountDisabled: false,
            });
            clearInterval(this.state.buttonProgress);
            this.setDefaultButton();

            if (!__.isUndefined(data.code)) {
                if (data.code === "credit") {
                    // this.props.setWallet(true, data.uid);
                }
            }

            // this.setState({clicked: false})
            // sendNotfication(data.message, 'danger', 'top-center');

            if (!__.isUndefined(data.code)) {
                if (data.code === "version") {
                    wait(3000).then(() => {
                        window.location.reload();
                    });
                }
            }
        }
    }

    setDefaultButton = () => {
        if (this._isMounted) {
            clearInterval(this.state.buttonProgress);

            if (this.state.autoRunning) {
                this.props.toggleSpecial(true);
            } else {
                this.props.toggleSpecial(false);
            }

            let nextText = this.state.autoRunning
                ? "Automatic..."
                : "Start Autobet";
            this.setState({
                added: false,
                holding: false,
                inputDisabled: false,
                amountDisabled: false,
                buttonType: "btn-bet",
                buttonText: nextText,
            });
        }
    };

    setWaitingButton = () => {
        if (this._isMounted) {
            this.props.toggleSpecial(true);

            this.setState({
                added: true,
                inputDisabled: true,
                amountDisabled: true,
                buttonType: "btn-bet-success-crash text-white btn-p no-shadow",
                buttonText: "Betting Automatically",
            });
        }
    };

    setOutButton = () => {
        if (this._isMounted) {
            this.props.toggleSpecial(true);

            this.setState({
                inputDisabled: false,
                amountDisabled: true,
                buttonType: "btn-bet-success",
                buttonText: "CANCEL Auto Bet",
            });
        }
    };

    cashOut() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.finish(Game["current_amount"]);
        }
    }

    handlePlay(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                this.props.setMeToGame(true);
            }
        }
    }

    handleFinish(data) {
        if (this._isMounted) {
            if (data.token === this.state.token) {
                clearInterval(this.state.buttonProgress);
                this.props.setWinnerText("  You Cashed Out @" + data.current / 100);
                this.props.setMeToGame(false);
                this.setDefaultButton();
                this.setState({autoRunning: false});
            }
        }
    }

    checkWaitingGame() {
        if (this._isMounted) {
            //auto
            this.checkAutoRunning();

            this.props.setWinnerText("");

            this.setState({gameStatus: "waiting"});

            if (this.state.holding) {
                this.setState({holding: false});
            }
        }
    }

    checkStartedGame(data = null) {
        if (this._isMounted) {
            let {im_in_game} = this.props;
            let {engine} = this.state;

            let coin = engine.coin;
            let amount = engine.amount;

            this.setState({gameStatus: "started"});

            if (data != null) {
                //console.log("check started, players:", data.players.length);
                if (data.players.length > 0) {
                    data.players.forEach((the_player, i) => {
                        if (the_player.uid === 4153751612) {
                            //this.setState({clicked: true});
                        }
                        //console.log("check started, uid:", the_player.uid, ' >>', this.state.clicked);
                    });
                }
            }

            if (im_in_game === true || this.state.clicked) {
                this.props.toggleSpecial(true);

                this.setState({
                    inputDisabled: true,
                    amountDisabled: false,
                    buttonType: "btn-bet-success-crash",
                    clicked: false,
                });

                let counter = 0;
                let self = this;
                this.state.buttonProgress = setInterval(function () {
                    //let calc = amount * (Game['current_amount'] - 1);
                    let current_amount = Game["current_amount"];
                    if (current_amount >= 1.1) {
                        //self.setState({inputDisabled: false});
                    }

                    let calc = amount * current_amount; //Dan
                    self.setState({
                        buttonText: "Cashout@" + current_amount + " = " + forceSatoshiFormat(calc) + " KES",
                    });
                    counter++;
                }, 50);
            }
        }
    }

    checkBustedGame() {
        if (this._isMounted) {
            this.setState({gameStatus: "busted", clicked: false});

            let {im_in_game} = this.props;

            if (!this.state.holding) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }

            if (im_in_game) {
                this.props.setMeToGame(false);
                clearInterval(this.state.buttonProgress);
                this.setDefaultButton();
            }
        }
    }

    isCorrectBet() {
        let {amount, payout, number_of_bets} = this.state;

        if (!isValidNumber(amount)) return false;

        if (amount < 10) {
            return false;
        }
        //todo - payout and number_of_bets check
        return true;
    }

    holdBet() {
        if (this._isMounted) {
            let {engine} = this.state;
            engine.isHolding = true;
            this.setState({holding: true});
            this.setOutButton();
        }
    }

    handleBet(e) {
        if (this._isMounted) {
            e.preventDefault();

            let {amount, payout, token, number_of_bets, engine} = this.state;
            let {im_in_game} = this.props;

            console.log("[temp] handle bet: " + amount + ' | co: ' + payout + ' | bets: ' + number_of_bets);

            // Check User
            if (!token) {
                return Event.emit("showAuthModal", true);
            }

            // Check is Correct Bet
            if (!this.isCorrectBet()) {
                return false;
            }

            //all good
            socket.emit('AUTO_SIMPLE', encode({
                token: token,
                amount: amount,
                payout: payout,
                type: 'auto_simple',
                number_of_bets: number_of_bets,
                coin: 'kes',
                version: APP_VERSION
            }));
        }
    }

    playedSimple(data) {
        let {status, payload, message} = data;
        if (status === 'success') {
            this.setState({autoRunning: true});
        }

        if (status === 'entry') {
            let {bets} = payload;
            this.setState({autoRunning: true, buttonText: "Bets: " + bets});
        }
        console.log('[temp] simple auto bet:', data);
    }

    setMax = (e) => {
        e.preventDefault();
        let max = this.props.credit;
        if (max === null) return;
        if (max === "NaN") return;

        max = Math.max(max, 0); //maxbet - 3000

        this.setState({amount: max});
        this.setTP(this.state.payout, max);
        storage.setKey("amount", max);
    };

    setMin = (e) => {
        e.preventDefault();
        this.setState({amount: "10.00"});
        this.setTP(this.state.payout, 10);
        storage.setKey("amount", 10.0);
    };

    multi = (e) => {
        let max = this.state.amount * 2;
        this.setState({amount: forceSatoshiFormat(max)});
        this.setTP(this.state.payout, max);
        storage.setKey("amount", max);
    };

    divide = (e) => {
        let max = this.state.amount / 2;
        max = Math.max(max, 10);
        this.setState({amount: forceSatoshiFormat(max)});
        this.setTP(this.state.payout, max);
        storage.setKey("amount", max);
    };

    checkAutoRunning = () => {
        if (this.state.autoRunning) {
            this.setWaitingButton();
            let amount = this.state.amount;
            let payout = this.state.payout;

            this.handleAuto({amount, payout});
        }
    };

    toggleOnWinReset(status) {
        this.setState({onWinResetDisabled: status});
    }

    toggleOnWinIncrease(status) {
        this.setState({onWinIncreaseDisabled: status});
    }

    ask() {
        Swal.fire({
            title: "Go All In",
            header: "Hey",
            text: "Are you sure you want to go all in? This will stake all the amount in your wallet.",
            showCancelButton: true,
            confirmButtonColor: "#1BB55C",
            showCloseButton: true,
            cancelButtonColor: "#263238!important",
            cancelButtonText: "No",
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.value) {
                this.setMax();
            }
        });
    }

    stake() {
        Swal.fire({
            title: "Stake",
            header: "Hey",
            text: "Stake means the amount you are betting from your wallet. The minimum amount is Ksh 100.",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.value) {
                this.setMax();
            }
        });
    }

    numberBets() {
        Swal.fire({
            title: "Number of Bets",
            header: "Hey",
            text: "This is the number of times you want classic auto to bet your amount. For example, if you put 10, it will bet 10 rounds automatically.",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.value) {
                this.setMax();
            }
        });
    }

    onWin() {
        Swal.fire({
            title: "On Win",
            header: "Hey",
            text: "This describes what should happen once you win one round in auto mode. For example, you could choose to increase your stake by 10% to increase your winning streak",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.value) {
                this.setMax();
            }
        });
    }

    onLose() {
        Swal.fire({
            title: "On Lose",
            header: "Hey",
            text: "This describes what should happen once you lose one round in auto mode. For example, you could choose to decrease your stake by 10% to increase your winning streak",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.value) {
                this.setMax();
            }
        });
    }

    stopProfit() {
        Swal.fire({
            title: "Stop On Profit",
            header: "Hey",
            text: "This defines when auto mode should stop when you have achieved a certain profit. For example, you could choose to stop auto betting if you have gotten a profit of Ksh 10,000",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.value) {
                this.setMax();
            }
        });
    }

    stopLoss() {
        Swal.fire({
            title: "Stop On Loss",
            header: "Hey",
            text: "This defines when auto mode should stop when you have gotten a certain loss. For example, you could choose to stop auto betting if you have lost Ksh 1000",
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
        }).then((result) => {
            if (result.value) {
                this.setMax();
            }
        });
    }

    winPopup(data) {
        Swal.fire({
            position: "top",
            customClass: "swal-height",
            toast: false,
            html:
                '<b style="font-size:15px" class="text-orange">Congratulations!</b> ' +
                '<lottie-player src="https://assets3.lottiefiles.com/packages/lf20_7z1gt2bj/Coin2/Coin.json" background="transparent" speed="2"  style="height: 150px;"   loop  autoplay></lottie-player>' +
                `<p style="font-size:10px"><span class="text-orange">You won </span>Ksh ${formatAmount(
                    data.won
                )}</p>`,
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: false,
            backdrop: false,
            timer: 4000,
            width: "100px",
            timerProgressBar: false,
        }).then((result) => {
            if (result.value) {
            }
        });
    }

    render() {
        let {
            amount,
            number_of_bets,
            inputDisabled,
            amountDisabled,
            payout,
            buttonType,
            buttonText,
            target_profit,
            probability,
            cashoutAmount,
            winning_chance,
            autoBody,
            autoColor,
            advancedOn,
        } = this.state;
        let {mobile, coin} = this.props;

        //initial render
        if (amount === "NaN") {
            amount = 10.0;
        }

        if (target_profit === "") {
            target_profit = parseFloat(amount * payout - amount).toFixed(2);
            if (target_profit === "NaN" || target_profit < "0") {
                target_profit = "~";
            } else {
                target_profit = toComma(target_profit);
            }
        }

        if (winning_chance === "") {
            winning_chance = parseFloat(99 / payout).toFixed(2);
            if (winning_chance === "NaN" || winning_chance === "Infinity") {
                winning_chance = "~";
            } else {
                winning_chance += "%";
            }
        }

        return (
            <div>
                <form
                    className="w-100 mt-1"
                    onSubmit={(e) => {
                        this.handleBet(e);
                    }}
                >
                    <table style={{width: "100%"}}>
                        <tr>
                            <td>
                                <div className={"form-group rev mt-0 mb-0"}>
                                    <Button
                                        style={{padding: "15px 20px"}}
                                        variant={"btn orange-btn btn-block " + buttonType}
                                        disabled={inputDisabled}
                                        type="submit"
                                    >
                                        <div>
                                            <h6 style={{color: "black", fontWeight: "600"}} className={"m-0"}>
                                                {buttonText}
                                                <span className={autoBody}>&nbsp;{autoBody === "text-danger" ? "" : ""}</span>
                                            </h6>
                                        </div>
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    </table>

                    {!advancedOn ? (
                        <div className={"row top-10 remove-margin"}>
                            <div className={"col-12 padding-0"}>
                                <div className={"form-group mb-1 bet-input payout"}>
                                    <p className="text-white margin-bottom-5">
                                        Stake&nbsp;
                                        <i
                                            className="fa fa-info-circle text-gray font-10"
                                            onClick={this.stake}
                                        />
                                    </p>
                                    <div
                                        className={`${
                                            amount < 10 ? "error-border" : ""
                                        } btn-group justify-between w-100 row margin-0 remove-margin`}
                                        role="group"
                                        aria-label="range group"
                                    >
                                        <input
                                            style={{
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                            ref={this.wrapperRef}
                                            disabled={amountDisabled}
                                            type="number"
                                            min={10}
                                            step="1"
                                            className="form-control bet-amount-input text-left col-6"
                                            id="amount"
                                            name="amount"
                                            placeholder="10.00"
                                            value={amount}
                                            autoComplete={"off"}
                                            onKeyUp={this.handleInputChange}
                                            onChange={this.handleInputChange}
                                        />
                                        <button
                                            disabled={amountDisabled}
                                            type={"button"}
                                            className={"btn btn-sm p-0 bet-container col-2"}
                                            onClick={(e) => this.divide(e)}
                                        >
                                            <div className="bet-amount-bg">1&frasl;2</div>
                                        </button>
                                        <button
                                            disabled={amountDisabled}
                                            type={"button"}
                                            className={"btn btn-sm p-0 bet-container col-2"}
                                            onClick={(e) => this.multi(e)}
                                        >
                                            <div className="bet-amount-bg">2x</div>
                                        </button>
                                        <button
                                            disabled={amountDisabled}
                                            type={"button"}
                                            className={"btn btn-sm p-0 pr-1 bet-container col-"}
                                            onClick={(e) => this.setMax(e)}
                                        >
                                            <div
                                                className="bet-amount-bg"
                                                style={{border: "none"}}
                                                onClick={this.ask}
                                            >
                                                All In
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div className={"form-group mb-1 bet-input payout"}>
                                    <p className="text-white margin-bottom-5">
                                        Number of bets&nbsp;
                                        <i
                                            className="fa fa-info-circle text-gray font-10"
                                            onClick={this.numberBets}
                                        />
                                    </p>
                                    <div
                                        className={"btn-group w-100 row margin-0 remove-margin"}
                                        role="group"
                                        aria-label="range group"
                                    >
                                        <input
                                            style={{
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                            ref={this.wrapperRef}
                                            type="number"
                                            min={2}
                                            step="1"
                                            className="form-control bet-amount-input text-left col-6"
                                            id="number_of_bets"
                                            name="number_of_bets"
                                            placeholder="10"
                                            autoComplete={"off"}
                                            value={number_of_bets}
                                            onKeyUp={this.handleInputChange}
                                            onChange={this.handleInputChange}
                                        />
                                        <button
                                            disabled={amountDisabled}
                                            type={"button"}
                                            className={"btn btn-sm p-0 bet-container col-2"}
                                        >
                                            <div className="bet-amount-bg">10</div>
                                        </button>
                                        <button
                                            disabled={amountDisabled}
                                            type={"button"}
                                            className={"btn btn-sm p-0 pr-1 bet-container col-"}
                                        >
                                            <div className="bet-amount-bg">100</div>
                                        </button>
                                        <button
                                            disabled={amountDisabled}
                                            type={"button"}
                                            className={"btn btn-sm p-0 bet-container col-2"}
                                        >
                                            <div
                                                className="bet-amount-bg font-14"
                                                style={{border: "none"}}
                                            >
                                                ∞
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className={"row top-10 remove-margin"}>
                                <div
                                    className={"col-12 top-10 padding-0"}
                                    style={{marginTop: "5px"}}
                                >
                                    <div
                                        className={"form-group mb-1 bet-input payout"}
                                        style={{display: "flex"}}
                                    >
                                        <div style={{width: '50%'}}>
                                            <p className="text-white margin-bottom-5 ">
                                                Multiplaya&nbsp;
                                                <i
                                                    className="fa fa-info-circle text-gray font-10"
                                                    onClick={this.autoMultiplaya}
                                                />
                                            </p>
                                            <div
                                                className={"btn-group w-100 row margin-0 remove-margin"}
                                                role="group"
                                                aria-label="range group"
                                            >
                                                <input
                                                    style={{borderRadius: "0.25rem"}}
                                                    disabled={amountDisabled}
                                                    type="number"
                                                    className="form-control w-90 bet-amount-input text-left"
                                                    id="payout"
                                                    name="payout"
                                                    max="99999"
                                                    step="0.01"
                                                    min="1.01"
                                                    placeholder="2"
                                                    value={payout}
                                                    autoComplete={"off"}
                                                    onKeyUp={this.handleInputChange}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                        </div>
                                        <div style={{width: "50%"}}>
                                            <p className="text-white margin-bottom-5">
                                                Win Chance (%)&nbsp;
                                                {/* <i className='fa fa-info-circle text-gray font-10' onClick={this.autoMultiplaya}/> */}
                                            </p>
                                            <div
                                                className={"btn-group w-100 row margin-0 remove-margin"}
                                                role="group"
                                                aria-label="range group"
                                            >
                                                <div className="d-flex w-100 justify-content-around">
                                                    <input
                                                        style={{borderRadius: "0.25rem"}}
                                                        disabled={amountDisabled}
                                                        type="number"
                                                        className="form-control w-90 bet-amount-input flex-end text-left"
                                                        id="probability"
                                                        name="probability"
                                                        max="98.00"
                                                        step="0.01"
                                                        min="0.01"
                                                        placeholder="2"
                                                        value={probability}
                                                        autoComplete={"off"}
                                                        onKeyUp={this.handleInputChange}
                                                        onChange={this.handleInputChange}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-white margin-bottom-5">
                                            Profit on win: &nbsp;
                                            <span
                                                className="w-90 text-left text-white text-success font-12"
                                                id="cashout"
                                            >
                        {target_profit}
                      </span>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className={"row top-10 remove-margin"}>
                            <div className={"col-12 padding-0"}>
                                <div className={"form-group mb-1 bet-input payout"}>
                                    <p className="text-white margin-bottom-5">
                                        Stake&nbsp;
                                        <i
                                            className="fa fa-info-circle text-gray font-10"
                                            onClick={this.stake}
                                        />
                                    </p>
                                    <div
                                        className={"btn-group w-100 row margin-0 remove-margin"}
                                        role="group"
                                        aria-label="range group"
                                    >
                                        <input
                                            style={{
                                                borderTopRightRadius: 0,
                                                borderBottomRightRadius: 0,
                                            }}
                                            ref={this.wrapperRef}
                                            disabled={amountDisabled}
                                            type="number"
                                            min={10}
                                            step="0.01"
                                            className="form-control bet-amount-input text-left col-6"
                                            id="amount"
                                            name="amount"
                                            placeholder="10.00"
                                            value={amount}
                                            autoComplete={"off"}
                                            onKeyUp={this.handleInputChange}
                                            onChange={this.handleInputChange}
                                        />
                                        <button
                                            disabled={amountDisabled}
                                            type={"button"}
                                            className={"btn btn-sm p-0 bet-container col-2"}
                                            onClick={(e) => this.divide(e)}
                                        >
                                            <div className="bet-amount-bg">1&frasl;2</div>
                                        </button>
                                        <button
                                            disabled={amountDisabled}
                                            type={"button"}
                                            className={"btn btn-sm p-0 bet-container col-2"}
                                            onClick={(e) => this.multi(e)}
                                        >
                                            <div className="bet-amount-bg">2x</div>
                                        </button>
                                        <button
                                            disabled={amountDisabled}
                                            type={"button"}
                                            className={"btn btn-sm p-0 pr-1 bet-container col-"}
                                            onClick={(e) => this.setMax(e)}
                                        >
                                            <div
                                                className="bet-amount-bg"
                                                style={{border: "none"}}
                                                onClick={this.ask}
                                            >
                                                All In
                                            </div>
                                        </button>
                                    </div>
                                </div>
                                <div
                                    className={"form-group mb-1 bet-input payout"}
                                    style={{display: "flex"}}
                                >
                                    <div>
                                        <p className="text-white margin-0 ">
                                            Multiplaya&nbsp;
                                            <i
                                                className="fa fa-info-circle text-gray font-10"
                                                onClick={this.autoMultiplaya}
                                            />
                                        </p>
                                        <div
                                            className={"btn-group w-100 row margin-0 remove-margin"}
                                            role="group"
                                            aria-label="range group"
                                        >
                                            <input
                                                style={{borderRadius: "0.25rem"}}
                                                disabled={amountDisabled}
                                                type="number"
                                                className="form-control w-90 bet-amount-input text-left"
                                                id="payout"
                                                name="payout"
                                                max="99999"
                                                step="0.01"
                                                placeholder="2"
                                                value={payout}
                                                autoComplete={"off"}
                                                onKeyUp={this.handleInputChange}
                                                onChange={this.handleInputChange}
                                            />
                                        </div>
                                    </div>

                                    <div>
                                        <p className="text-white margin-0 ">
                                            Cashout&nbsp;
                                            {/* <i className='fa fa-info-circle text-gray font-10' onClick={this.autoMultiplaya}/> */}
                                        </p>
                                        <div
                                            className={"btn-group w-100"}
                                            role="group"
                                            aria-label="range group"
                                        >
                                            <div className="d-flex w-100 justify-content-around">
                                                <input
                                                    style={{borderRadius: "0.25rem"}}
                                                    disabled={amountDisabled}
                                                    type="number"
                                                    className="form-control w-90 bet-amount-input text-left"
                                                    id="cashout"
                                                    name="cashout"
                                                    max="99999"
                                                    step="0.01"
                                                    placeholder="2"
                                                    value={cashoutAmount}
                                                    autoComplete={"off"}
                                                    onKeyUp={this.handleInputChange}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <p className="text-white margin-0 ">
                                            Win Chance (%)&nbsp;
                                            {/* <i className='fa fa-info-circle text-gray font-10' onClick={this.autoMultiplaya}/> */}
                                        </p>
                                        <div
                                            className={"btn-group w-100"}
                                            role="group"
                                            aria-label="range group"
                                        >
                                            <div className="d-flex w-100 justify-content-around">
                                                <input
                                                    style={{borderRadius: "0.25rem"}}
                                                    disabled={amountDisabled}
                                                    type="number"
                                                    className="form-control w-90 bet-amount-input flex-end text-left"
                                                    id="probability"
                                                    name="probability"
                                                    max="99999"
                                                    step="0.01"
                                                    placeholder="2"
                                                    value={probability}
                                                    autoComplete={"off"}
                                                    onKeyUp={this.handleInputChange}
                                                    onChange={this.handleInputChange}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={"form-group mb-1 bet-input  payout"}>
                                    <p className="text-white margin-0 top-10">
                                        On Win&nbsp;
                                        <i
                                            className="fa fa-info-circle text-gray font-10"
                                            onClick={this.onWin}
                                        />
                                    </p>
                                    <div
                                        className={
                                            "btn-group w-100 bet-amount-input row remove-margin"
                                        }
                                        role="group"
                                        aria-label="range group"
                                    >
                                        <div
                                            disabled={amountDisabled}
                                            step="0.01"
                                            className="form-control transparent-bg text-left col-8"
                                        >
                                            <Tabs
                                                fill
                                                defaultActiveKey="Reset"
                                                id="uncontrolled-tab-bet"
                                                className="tabs-container classic-tabs"
                                            >
                                                <Tab
                                                    tabClassName={"w-100 classic-child-tab"}
                                                    eventKey="Reset"
                                                    title="Reset"
                                                ></Tab>
                                                <Tab
                                                    tabClassName={"w-100"}
                                                    eventKey="Increase"
                                                    title="Increase By"
                                                ></Tab>
                                            </Tabs>
                                        </div>
                                        <input
                                            ref={this.wrapperRef}
                                            disabled={amountDisabled}
                                            type="number"
                                            min={10}
                                            step="0.01"
                                            className="bet-container-input text-left col-3 padding-15"
                                            id="onWin"
                                            name="onWin"
                                            placeholder="26%"
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                                <div className={"form-group mb-1 bet-input  payout"}>
                                    <p className="text-white margin-0 top-10">
                                        On Loss&nbsp;
                                        <i
                                            className="fa fa-info-circle text-gray font-10"
                                            onClick={this.onLose}
                                        />
                                    </p>
                                    <div
                                        className={
                                            "btn-group w-100 bet-amount-input row remove-margin"
                                        }
                                        role="group"
                                        aria-label="range group"
                                    >
                                        <div
                                            disabled={amountDisabled}
                                            step="0.01"
                                            className="form-control transparent-bg text-left col-8"
                                        >
                                            <Tabs
                                                fill
                                                defaultActiveKey="Reset"
                                                id="classic"
                                                className="tabs-container classic-tabs "
                                            >
                                                <Tab
                                                    tabClassName={"w-100 classic-child-tab "}
                                                    eventKey="Reset"
                                                    title="Reset"
                                                ></Tab>
                                                <Tab
                                                    tabClassName={"w-100"}
                                                    eventKey="Increase"
                                                    title="Increase By"
                                                ></Tab>
                                            </Tabs>
                                        </div>
                                        <input
                                            ref={this.wrapperRef}
                                            disabled={amountDisabled}
                                            type="number"
                                            min={10}
                                            step="0.01"
                                            className="bet-container-input text-left col-3 padding-15"
                                            id="onWin"
                                            name="onWin"
                                            placeholder="26%"
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                                <div className={"form-group mb-1 bet-input payout"}>
                                    <p className="text-white margin-0 padding-2rem">
                                        Stop On Profit&nbsp;
                                        <i
                                            className="fa fa-info-circle text-gray font-10"
                                            onClick={this.stopProfit}
                                        />
                                    </p>
                                    <div
                                        className={"btn-group w-100"}
                                        role="group"
                                        aria-label="range group"
                                    >
                                        <input
                                            ref={this.wrapperRef}
                                            disabled={amountDisabled}
                                            type="number"
                                            min={10}
                                            step="0.01"
                                            className="form-control bet-amount-input text-left"
                                            id="amount"
                                            name="amount"
                                            placeholder="10.00"
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                                <div className={"form-group mb-1 bet-input payout"}>
                                    <p className="text-white margin-0 padding-2rem">
                                        Stop On Loss&nbsp;
                                        <i
                                            className="fa fa-info-circle text-gray font-10"
                                            onClick={this.stopLoss}
                                        />
                                    </p>
                                    <div
                                        className={"btn-group w-100"}
                                        role="group"
                                        aria-label="range group"
                                    >
                                        <input
                                            ref={this.wrapperRef}
                                            disabled={amountDisabled}
                                            type="number"
                                            min={10}
                                            step="0.01"
                                            className="form-control bet-amount-input text-left"
                                            id="amount"
                                            name="amount"
                                            placeholder="10.00"
                                            autoComplete={"off"}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    <div className="switch-bg margin-10">
                        <Switch onChange={this.toggleAdvanced}/>{" "}
                        {advancedOn ? "Advanced Autobet" : "Simple Autobet"}
                    </div>
                </form>
            </div>
        );
    }
}

ClassicAutoBet.propTypes = {
    coin: PropTypes.string,
    im_in_game: PropTypes.bool,
    credit: PropTypes.string,
};

const mapStateToProps = (state) => ({
    coin: state.items.coin,
    im_in_game: state.items.im_in_game,
    credit: state.items.credit,
});

export default connect(mapStateToProps, {
    gameCoin,
    setWinnerText,
    setMeToGame,
    setWallet,
})(ClassicAutoBet);
