import React, {Component} from 'react'
import {Modal, Row, Col, Tabs, Tab} from "react-bootstrap";
import storage from "../../../../Storage";
import {sendNotfication, Event, isMobile,wait} from "../../../../Helper";
import Wallet from "../Wallet";
import {NavLink} from "react-router-dom";
import CancelIcon from '../../../../Static/images/cancel.svg'
import UserAvatar from '../../../../Static/images/Avatar/User-60.svg'
import General from "../../User/Setting/General";
import Security from "../../User/Setting/Security";
import LinearProgress from '@mui/material/LinearProgress';
import { ChatClick, NotifClicker, StatsClick, UpdateHomeIcon, VipClick, WalletClick } from '../ClickService';
import Grid from '@mui/material/Grid';
import { showWarningToast } from '../../../../toastNotifications';


class VerifyAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            general: true,
            username: storage.getKey('name')
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount(){
        Event.on('show_user_account', this.handleShow);
    }

    handleClose() {
        this.setState({show: false, effect: 'delayAn zoomOut'});
        wait(500).then(() => {
            this.setState({general: true})
        })
    }

    handleShow() {
        if (storage.getKey('token') === null) {
            showWarningToast('Please Login to view your details');
             return;
        }
        
        this.setState({show: true, effect: 'delayAn pulse'});
        NotifClicker();
        StatsClick();
        WalletClick();
        ChatClick();
        VipClick();
        UpdateHomeIcon();
    }

    changePage = (e) => {
        this.setState({general: !this.state.general})
    };

    render() {
        let {t} = this.props;
        return (
            <>
                <button className='grey-btn top-1rem'>Send Verification Code</button>
                <label className="top-10" htmlFor="password">Verify Account via phone number</label>
                <div className="input-group">
                    <input 
                        type="text"
                        id="password"
                        autoComplete={'off'}
                        className="form-control input-box settings-input"
                        placeholder="Enter verification Code"
                    />
                    <div className="input-group-append">
                        <button style={{width: '100%'}} type="button"
                            className="btn settings-btn orange-bg no-shadow btn-clipboard settings-input" >
                                <i className="fa fa-send text-grey"/>
                        </button>
                    </div>
                </div>
                        
                <label className="top-10" htmlFor="email">Verify Account via email</label>
                <div className="input-group">
                    <input
                        type="email"
                        id="email"
                        autoComplete={'off'}
                        className="form-control input-box settings-input"
                        placeholder="Enter verification Code"
                    />
                    <div className="input-group-append">
                        <button style={{width: '100%'}} type="button"
                            className="btn settings-btn orange-bg no-shadow btn-clipboard settings-input" >
                                <i className="fa fa-send text-grey"/>
                        </button>
                    </div>
                </div>
            </>
        );
    }
}

export default VerifyAccount;