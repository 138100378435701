import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import storage from "../../../../Storage";
import { sendNotfication, Event, isMobile } from "../../../../Helper";
import Wallet from "../Wallet";
import { NavLink } from "react-router-dom";
import CancelIcon from "../../../../Static/images/cancel.svg";
import NotifIcon from "../../../../Static/images/Avatar/notif.svg";
import RainIcon from "../../../../Static/images/rain-icon.svg";
import Rain from "../../../Pages/Parts/Chat/Rain";
import RulesIcon from "../../../../Static/images/guide.svg";
import ReactModal from "@mui/material/Modal";
import { showWarningToast } from "../../../../toastNotifications";

class RulesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initState: 0,
      show: this.props.show ? this.props.show : false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    Event.on("show_rules", this.handleShow);
  }

  handleClose() {
    this.setState({ show: false, effect: "zoomOut" });
  }

  handleShow() {
    if (storage.getKey("token") === null) {
      showWarningToast("Please Login to use this option.");
      return;
    }

    this.setState({ show: true, effect: "pulse" });
  }

  render() {
    return (
      <>
        {!this.props.show && (
          <NavLink
            to={"#"}
            className="nav-link bottom-nav-link chat-options"
            onClick={(e) => this.handleShow(e)}
          >
            <div
              className={
                "row d-flex flex-column justify-content-center align-items-center bottom-menu gray-text"
              }
            >
              <img src={RulesIcon} style={{ width: "20px" }} alt="rain" />
            </div>
          </NavLink>
        )}
        <ReactModal open={this.state.show} onClose={this.handleClose}>
          <div className="custom-modal-container">
            <div className="font-14">
              <b>Chat Guidelines</b>
              <button
                type="button"
                className="close "
                onClick={this.handleClose}
              >
                <i className={"fa fa-close font-16 font-300 text-orange"} />
              </button>
            </div>

            <hr className="notif-hr hr-margin margin-10" />
            <div>
              <h6 className="text-gray">Chat Features</h6>
              <ol>
                <li className="font-12">Chat room</li>
                <li className="font-12">Tip</li>
                <li className="font-12">Rain</li>
              </ol>
            </div>
            <div>
              <h6 className="text-gray">Chat Rules</h6>
              <ol>
                <li className="font-12">
                  Do not spam, flood, swear or insult players.
                </li>
                <li className="font-12">
                  Do not ask for loans, rain or tip in the chat.
                </li>
                <li className="font-12">
                  Do not give or take a loan as this is against Multiplaya Terms
                  of Use. Also restrain from discussing such matters in the main
                  chat.
                </li>
                <li className="font-12">
                  Do not post referral, advertising and offensive links either
                  in chat or private messages.
                </li>
                <li className="font-12">
                  Use the designated language channels accordingly.
                </li>
                <li className="font-12">
                  Do not advertise or attract players to other sites.
                </li>
                <li className="font-12">
                  Do not write such things as 'scam' or other forms of
                  complaints about the service
                </li>
              </ol>
            </div>
          </div>
        </ReactModal>
      </>
    );
  }
}

export default RulesModal;
