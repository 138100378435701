import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import storage from "../../../../Storage";
import { Event } from "../../../../Helper";
import { NavLink } from "react-router-dom";
import {
  ChatClick,
  NotifClicker,
  SettingsClick,
  StatsClick,
  UpdateHomeIcon,
  UpdateHomeIconActive,
  WalletClick,
} from "../ClickService";
import Grid from "@mui/material/Grid";
import Avatar2 from "../../../../Static/images/avatars/avatar2.svg";
import LevelIcon from "../../../../Static/images/levels/diamond.svg";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { showWarningToast } from "../../../../toastNotifications";

class VipSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initState: 0,
      show: this.props.show ? this.props.show : false,
      classicDisabled: false,
      specialDisabled: false,
      jackpotDisabled: false,
      expanded: "bronze",
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.toggleClassic = this.toggleClassic.bind(this);
    this.toggleSpecial = this.toggleSpecial.bind(this);
    this.toggleJackpot = this.toggleJackpot.bind(this);
    this.toggleOpenAccordion = this.toggleOpenAccordion.bind(this);
  }

  componentDidMount() {
    Event.on("show_stats", this.handleShow);
  }

  handleClose() {
    this.setState({ show: false, effect: "zoomOut" });
    UpdateHomeIconActive();
  }

  handleShow() {
    if (storage.getKey("token") === null) {
      showWarningToast("Please Login to use this option.");
      return;
    }

    this.setState({ show: true, effect: "pulse" });
    NotifClicker();
    SettingsClick();
    ChatClick();
    WalletClick();
    StatsClick();
    UpdateHomeIcon();
  }

  toggleClassic(status) {
    this.setState({ classicDisabled: status });
  }

  toggleSpecial(status) {
    this.setState({ specialDisabled: status });
  }

  toggleJackpot(status) {
    this.setState({ jackpotDisabled: status });
  }

  toggleOpenAccordion(level) {
    this.setState({ expanded: level });
  }

  render() {
    let { expanded } = this.state;

    return (
      <>
        {!this.props.show && (
          <NavLink
            to={"#"}
            className="nav-link text-left header-color"
            onClick={(e) => this.handleShow(e)}
          >
            <i className={"fa fa-crown text-white"} />
            {" VIP"}
          </NavLink>
        )}

        {/* <ReactModal 
                    open={this.state.show}
                    onClose={this.handleClose}
                    className={'stats-container'}
                > */}
        <Modal
          size="md"
          centered={true}
          scrollable={false}
          show={this.state.show}
          onHide={this.handleClose}
          onExited={this.handleClose}
          aria-labelledby="wallet-md-modal"
          className={"animated " + this.state.effect}
        >
          {/* <div className="custom-modal-container stats-modal-container"> */}
          <div>
            <div className="font-14 modal-heading">
              <b>VIP</b>
              <button
                type="button"
                className="close"
                id="close-vip"
                onClick={this.handleClose}
              >
                <i className={"fa fa-close font-16 font-300 text-orange"} />
              </button>
            </div>

            <div className="padding-2rem ">
              <div className="user-card-level top-2rem">
                <Grid spacing={1} container className="top-10 align-c">
                  <Grid xs={3} item>
                    <img
                      src={Avatar2}
                      alt="user-icon"
                      height="60px"
                      width="60px"
                    />
                  </Grid>
                  <Grid xs={7} item>
                    <h5 className="text-white font-16 font-700">
                      {storage.getKey("name")}
                    </h5>
                    <p className="text-gray margin-0">User ID: 453245</p>
                  </Grid>
                  <Grid xs={2} className="flex-column-grid" item>
                    <img src={LevelIcon} alt="level-icon" />
                  </Grid>
                </Grid>
                <Grid spacing={1} container className="padding-2rem align-c">
                  <Grid xsitem>
                    <h5
                      className="margin-0 text-white font-16 font-700"
                      style={{ padding: "0px 20px" }}
                    >
                      Diamond
                    </h5>
                  </Grid>
                </Grid>
              </div>
              <div className="vip-card top-1rem">
                <h6 className="font-500">
                  <b>VIP - Exclusive Player Benefits</b>
                </h6>
                <p>
                  As a way of showing our gratitude and appreciation to our VIP
                  players,Multiplya invites you to join our VIP Club where there
                  is an abundance of gifts, giveaways, higher cash back and
                  unique features. Don't miss out on all the exciting and
                  amazing fun!
                </p>
                <h6 className="top-10">
                  <b>VIP Level System</b>
                </h6>
                <div>
                  <Accordion className="vip-level">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="text-gray" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h6>Bronze</h6>
                    </AccordionSummary>
                    <AccordionDetails>
                      <hr className="jackpot-hr hr-margin" />
                      <table className="w-100 vip-table">
                        <thead>
                          <tr className="text-gray font-11">
                            <th>Level</th>
                            <th>XP Amount</th>
                            <th>Progress</th>
                            <th>Star</th>
                          </tr>
                        </thead>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP 10%</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP 40%</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP 10%</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="vip-level">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="text-gray" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h6>Silver</h6>
                    </AccordionSummary>
                    <AccordionDetails>
                      <hr className="jackpot-hr hr-margin" />
                      <table className="w-100 vip-table">
                        <thead>
                          <tr className="text-gray">
                            <th>Level</th>
                            <th>XP Amount</th>
                            <th>Progress</th>
                            <th>Star</th>
                          </tr>
                        </thead>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="vip-level">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="text-gray" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h6>Gold</h6>
                    </AccordionSummary>
                    <AccordionDetails>
                      <hr className="jackpot-hr hr-margin" />
                      <table className="w-100 vip-table">
                        <thead>
                          <tr className="text-gray">
                            <th>Level</th>
                            <th>XP Amount</th>
                            <th>Progress</th>
                            <th>Star</th>
                          </tr>
                        </thead>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="vip-level">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="text-gray" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      className="vip-accord-content"
                    >
                      <h6>Platinum</h6>
                    </AccordionSummary>
                    <AccordionDetails>
                      <hr className="jackpot-hr hr-margin" />
                      <table className="w-100 vip-table">
                        <thead>
                          <tr className="text-gray">
                            <th>Level</th>
                            <th>XP Amount</th>
                            <th>Progress</th>
                            <th>Star</th>
                          </tr>
                        </thead>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion className="vip-level">
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon className="text-gray" />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <h6>Diamond</h6>
                    </AccordionSummary>
                    <AccordionDetails>
                      <hr className="jackpot-hr hr-margin" />
                      <table className="w-100 vip-table">
                        <thead>
                          <tr className="text-gray">
                            <th>Level</th>
                            <th>XP Amount</th>
                            <th>Progress</th>
                            <th>Star</th>
                          </tr>
                        </thead>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                        <tr className="text-gray">
                          <td>VIP 1</td>
                          <td>1</td>
                          <td>1XP</td>
                          <td>
                            <i className="fa fa-star font-11 text-orange" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                            <i className="fa fa-star font-11" />
                          </td>
                        </tr>
                      </table>
                    </AccordionDetails>
                  </Accordion>
                </div>
              </div>
            </div>
          </div>
        </Modal>
      </>
    );
  }
}

export default VipSection;
