import React, { Component, Suspense } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import md5 from "md5";
import { Col, Modal, Row, Card } from "react-bootstrap";
import { ReactNotifications } from 'react-notifications-component';
import i18next from "i18next";
import { I18nextProvider, useTranslation } from "react-i18next"
import Route from "./Router";
import socket from "./Socket";
import storage from "./Storage";
import { __, decode, encode, fixDate, forceSatoshiFormat, Event, wait, timeConvertor, chkd, DEVELOPMENT, COIN_TOKEN, crashColor } from "./Helper";
import Details from "./App/Components/User/Stat/Details";
import UserLink from "./App/Components/User/Stat/Modal";
import C from "./Constant";
import ReactModal from '@mui/material/Modal';
import Avatar from 'react-avatar';
import loading from './Static/images/loader.gif'
import { Keyboard } from 'react-native-web';
import { ToastContainer } from "react-toastify";
import { UpdateHomeIconActive } from "./App/Components/User/ClickService";

i18next.init({
    interpolation: { escapeValue: false }
});

const delay = DEVELOPMENT ? 300 : 4300;

// loading component for suspense fallback
const Loader = () => (
    <>
        <></>
    </>
);
function App() {

    return (
        <>
         <ToastContainer position="top-center"
            autoClose={6000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            draggable={false}
            pauseOnHover={false}
            theme="dark"/>
         <Suspense fallback={<Loader />}>
            <Page />
        </Suspense>
        </>
       
    );
}

function Page() {
    const { t } = useTranslation();
    return <Application t={t} />;
}

class Application extends React.Component {
    _isMounted = false;
    _initialZoomValue = document.body.style.zoom;
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            welcome: storage.getKey('welcome') ? true : false,
            effect: 'd-none'
        }
    }

    componentDidMount() {
        this._isMounted = true;
        this.keyboardDidShowListener = Keyboard.addListener('keyboardDidShow', this._keyboardDidShow);
        this.keyboardDidHideListener = Keyboard.addListener('keyboardDidHide', this._keyboardDidHide);

        //this.security();

        wait(700).then(() => {
            this.setState({ effect: 'pulse' })
        })

        wait(delay).then(() => {
            this.setState({ loading: false });
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
        document.body.style.zoom = this._initialZoomValue;
        this.keyboardDidShowListener.remove();
        this.keyboardDidHideListener.remove();
    }
    _keyboardDidShow() {
        document.body.style.zoom = "60%"
    }

    _keyboardDidHide() {
        document.body.style.zoom = this._initialZoomValue;
    }
    security = () => {
        chkd();
    }

    render() {
        const { t } = this.props;
        return (
            <I18nextProvider i18n={i18next}>
                {this.state.loading &&
                    <div className="loading">
                        <div className="loading-text">
                            <img src={loading} style={{ marginTop: '-10rem' }} width='250px' alt='loading' />
                            {/* <img width={"240px"} className={"img-fluid animated " + this.state.effect} src={PageLogo}/>
                            <br/>
                            <span className="loading-text-words">L</span>
                            <span className="loading-text-words">O</span>
                            <span className="loading-text-words">A</span>
                            <span className="loading-text-words">D</span>
                            <span className="loading-text-words">I</span>
                            <span className="loading-text-words">N</span>
                            <span className="loading-text-words">G</span> */}
                        </div>


                    </div>
                }
                {
                    this._isMounted &&
                    <>  
                        <ReactNotifications />
                        <UserModal t={t} />
                        <GameModal t={t} />
                        <SingleGameModal t={t} props={this.props} />
                    </>
                }
                <Route t={t} />
            </I18nextProvider>
        );
    }
}

class SingleGameModal extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            effect: null,
            details: [],
            clientName: storage.getKey('name'),
            token: storage.getKey('token'),
            country: storage.getKey('country') ? storage.getKey('country') : "Global",
            openGameModal: false
        };
    }

    componentDidMount() {
        this._isMounted = true;
        Event.on('single_game_modal', (result) => {
            this.setState({ details: result.data, show: true, openGameModal: true, effect: 'pulse' })
        });

        Event.on('single_game_modal_close', (result) => {
            this.handleClose();
        });
    }

    componentWillUnmount() {
        this._isMounted = false;

    }

    handleClose = () => {
        this.setState({ detail: true, tip: false, show: false, loading: true, effect: 'zoomOut', openGameModal: false });
    };

    validate = () => {
        let { hash } = this.state.details;
        Event.emit('game_verify', hash)
    }

    share = () => {
        let { hash } = this.state.details;
        if (storage.getKey('share') === hash) return;
        storage.setKey('share', hash);
        socket.emit(C.ADD_CHAT, encode({
            token: this.state.token,
            country: this.state.country,
            message: this.state.details
        }));
    }

    render() {
        let { name, amount, game, profit, coin, created, hash, gid, id, result, slot, direct, force, cashout } = this.state.details;
        let isLost = false;
        let listResult = false;
        let date = fixDate(created);

        if (direct) {
            date = timeConvertor(created);
        }

        // if is fake bot
        if (force) {
            date = "a few minutes ago"
        }

        profit = parseFloat(profit);

        if (profit === 0 || profit === 0.00000000) {
            isLost = true;
        }

        let flex = false;

        if (!__.isUndefined(cashout)) {
            listResult = cashout;
        }

        const makeStars = (num) => {
            let stars = 5;
            let userLevel = stars - parseFloat(num);
            var g = [], o = [], f = [];
            for (var i = 0; i < userLevel; i++) {
                g.push(<i className={'mdi mdi-star font-15 text-gray'} />);
            }
            for (var i = 0; i < num; i++) {
                o.push(<i className={'mdi mdi-star font-15 text-orange'} />);
            }
            f.push(o, g);
            return f;
        }

        const { t } = this.props;
        return (
            <>
                {this._isMounted &&
                    <>
                        <ReactModal
                            open={this.state.openGameModal}
                            onClose={this.handleClose}
                        >
                            <div className="custom-modal-container">
                                <div className=" text-right">
                                    <i className="fa fa-close font-16 font-300 text-orange" onClick={this.handleClose} />
                                </div>


                                <div className="user-detail text-center mb-1" style={{ display: 'flex', justifyContent: "space-between" }}>
                                    <Avatar
                                        name={name}
                                        size='40'
                                        textSizeRatio='2'
                                        round
                                        style={{ marginRight: "10px" }}
                                    />

                                    <div>
                                        <p className={'mb-0 text-white uname font-12 text-left'}>
                                            {name}
                                        </p>

                                    </div>

                                </div>

                                <Row className="text-center game-modal">
                                    <Col md={6} className="col-6 text-success font-12">
                                        <Card className={'mb-1 brd15 text-white'}>
                                            <Card.Body className="p-1 text-capitalize font-10 text-gray">
                                                {t('betting_id')} <br />
                                                <span className="font-11 text-white">{(gid !== undefined || !gid || gid !== "") ? gid : id}</span>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6} className="col-6 text-white font-12">
                                        <Card className={'mb-1 brd15 text-gray font-10'}>
                                            <Card.Body className="p-1 text-capitalize">
                                                Time & Date <br />
                                                <span className="font-11 text-white">{date}</span>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6} className="col-6">
                                        <Card className={'mb-1 brd15 text-gray font-10'}>
                                            <Card.Body className="p-1 text-capitalize">

                                                Bet {t('amount')} <br />
                                                <span className="font-11 text-white">KES {forceSatoshiFormat(amount)}</span>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    <Col md={6} className="col-6">
                                        <Card className={'mb-1 brd15 text-gray font-10'}>
                                            <Card.Body className="p-1 text-capitalize">

                                                {t('profit')} <br />

                                                <span className={!isLost ? 'num-style text-success font-11' : 'num-style text-danger font-11'}>KES {forceSatoshiFormat(profit)}</span>
                                            </Card.Body>
                                        </Card>
                                    </Col>
                                    {(listResult !== false && listResult !== null) &&
                                        <Col md={12} className="col-12">
                                            <Card className={'mb-1 brd15'}>
                                                <Card.Body className="p-1 text-white text-capitalize">
                                                    {game === 'crash' ? 'Payout' : 'Result'}
                                                    <div className={flex ? "mt-3 font-12 media" : "font-12 text-green"}>
                                                        {parseFloat(listResult).toFixed(2)}x
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    }
                                    <Col md={12} sm={12}>
                                        {
                                            (__.toString(hash).length > 0) &&
                                            <>
                                                <Card className={'mb-1 brd15'}>
                                                    <Card.Body className="p-1 text-white text-capitalize">
                                                        <div className="form-group mb-1">
                                                            <i className="mdi mdi-code-tags align-middle font-30" />
                                                            <label className="text-white mt-1">{t('game_hash')}</label>
                                                            <input type="text" className="form-control text-white brd15 mb-2" value={__.toString(hash)} style={{ background: "rgb(23 24 27)" }} readOnly={true} />
                                                        </div>
                                                        {(__.isUndefined(slot) || slot === null) &&
                                                            <>
                                                                <button onClick={this.validate} className="btn btn-success-2 btn-block btn-md btn-block no-shadow">
                                                                    <i className="mdi mdi-shield nts" /> Verify Result
                                                                </button>
                                                            </>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </>
                                        }
                                    </Col>
                                </Row>


                            </div>

                        </ReactModal>
                    </>
                }
            </>
        );
    }
}

class UserModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            detail: true,
            tip: false,
            notFound: false,
            data: [],
            coin: COIN_TOKEN,
            open: false,

        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.modal) {
            this.setState({ show: false });
        }
    }

    handleOpen() {
        this.setState({ open: true })
    }

    handleCloseModal = () => {
        this.setState({ open: false })
    };

    componentDidMount() {
        socket.on(C.USER_INFO, data => this.getUserInfo(decode(data)));

        Event.on('force_modal_user', () => {
            this.setState({ show: true, effect: 'pulse', haveData: 'no', data: [], open: true });
        });

        Event.on('force_modal_tip_close', () => {
            this.setState({ tip: false, detail: true });
        })
    }

    handleClose = () => {
        this.setState({ detail: true, tip: false, notFound: false, show: false, effect: 'zoomOut' });
    };

    getUserInfo = (data) => {
        if (data.status) {
            this.setState({ haveData: 'ok', data: data });
        } else {
            this.setState({ notFound: true });
        }
    };

    render() {
        let { chart_coin, t } = this.props;
        chart_coin = chart_coin ? chart_coin : this.state.coin;
        return (
            <>
                <ReactModal
                    open={this.state.open}
                    onClose={this.handleCloseModal}
                >
                    <div className="custom-modal-container">
                        <div className=" text-right">
                            <i className="fa fa-close font-16 font-300 text-orange" onClick={this.handleCloseModal} />
                        </div>
                        {
                            this.state.notFound ?
                                <>
                                    <div className="text-center text-yellow" style={{ minHeight: 500 }}>
                                        User Not Found
                                    </div>
                                </>
                                :
                                <>
                                    {this.state.detail &&
                                        <>
                                            <div className={(this.state.tip === true) ? 'container-fluid mduser animated fadeOut' : 'container-fluid mduser'}>
                                                <Details haveData={this.state.haveData} token={this.state.data.id} name={this.state.data.name} coin={chart_coin} data={this.state.data} />
                                            </div>
                                        </>
                                    }
                                </>
                        }
                    </div>

                </ReactModal>
            </>
        );
    }
}

class GameModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false,
            loading: true,
            gid: null,
            playerRow: [],
            numbers: [],
            busted: null,
            date: null,
            sha256: null,
            hash: null,
            userName: storage.getKey('name'),
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        if (nextProps.modal) {
            this.setState({ show: false });
        }
    }

    componentDidMount() {
        socket.on(C.GAME_DETAILS, data => this.gameInfo(decode(data)));

        Event.on('force_modal_game', () => {
            this.setState({ haveData: 'no', playerRow: [], busted: null, date: null, sha256: null, hash: null, gid: null, loading: true, show: true, effect: 'pulse', open: true });
        })
    }

    handleClose = () => {
        this.setState({ show: false, loading: true, effect: 'zoomOut' });
        UpdateHomeIconActive();
    };

    gameInfo(response) {
        if (!response.info) {
            this.setState({ show: false, loading: true, effect: 'zoomOut' });
            return;
        }
        this.setState({ loading: false, playerRow: [], haveData: 'ok' });

        let gameInfo = response.info;
        let busted;

        if (gameInfo.busted !== null && gameInfo.busted !== undefined && gameInfo.busted !== 'undefined' && gameInfo.busted !== "") {
            busted = gameInfo.busted;
        } else {
            busted = gameInfo.numbers;
        }

        this.setState({ busted: busted, sha256: md5(gameInfo.hash), hash: gameInfo.hash, date: gameInfo.date, gid: gameInfo.gid });

        let sort = sortByWinner(response.data);
        sort = __.xor(sort);
        __.reverse(sort).forEach((array, i) => {
            let row = <PlayerRow clicked={this.handleClose} array={array} key={i} />;
            this.setState(state => ({ playerRow: [row, ...state.playerRow] }));
        });
    }

    render() {
        const { t } = this.props;
        let { busted, date, sha256, hash, playerRow, gid, loading } = this.state;
        let heading = 'h1';
        let color = crashColor(busted);
        let arr;

        return (
            <Modal
                size="md"
                centered={true}
                backdrop={'static'}
                show={this.state.show}
                onHide={this.handleClose}
                onExited={this.handleClose}
                aria-labelledby="game-md-modal"

                className={"animated " + this.state.effect}
            >
                <Modal.Header className="Header">
                    {t('game_stats')}
                    <button type="button" className="close p-2" onClick={this.handleClose}>
                        <i className={'mdi mdi-close text-orange'} />
                    </button>
                </Modal.Header>
                {
                    playerRow &&
                    <Modal.Body>
                        {loading ?
                            <>
                                <div className="text-center" style={{ minHeight: 200 }}>
                                    <div class="text-orange my-1 user-loader" role="status" />
                                </div>
                            </>
                            :
                            <>
                                <Row className="text-darker pt-0 mb-1 user-detail">
                                    <Col md={12} sm={12}>
                                        <div className="review-box margin-10 text-center align-item-center">
                                            {heading === 'h1' ?
                                                <>
                                                    <h1 className={"my-0 py-0 text-" + color}>
                                                        {busted}
                                                        <span className={"font-"}>x</span>
                                                    </h1>
                                                    <h5 className={"mt-1 pt-0 text-" + color}>{t('busted')}</h5>
                                                </>
                                                :
                                                <h2 className={"my-0 py-0 text-" + color}>{arr}</h2>
                                            }
                                            <Row className="my-3">
                                                <Col md={6} sm={6} className="text-gray">
                                                    {t('betting_id')}: {gid}
                                                </Col>
                                                <Col md={6} sm={6} className="text-gray">
                                                    {t('date')}: {fixDate(date)}
                                                </Col>
                                            </Row>

                                        </div>
                                    </Col>
                                    <Col md={12} sm={12} className="text-center">
                                        <div className="form-group">
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text h-40 history-span">HASH</span>
                                                </div>
                                                <input type="text" className="form-control no-radius pointer mb-2"
                                                    value={__.toString(hash)} readOnly={true} />
                                            </div>
                                            <div className="input-group">
                                                <div className="input-group-append">
                                                    <span className="input-group-text h-40 history-span">MD5</span>
                                                </div>
                                                <input type="text" className="form-control no-radius pointer"
                                                    value={__.toString(sha256)} readOnly={true} />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                        }
                        {loading ?
                            <>
                                <div className="text-center" style={{ minHeight: 200 }}>
                                    <div class="spinner-border text-orange my-2 user-loader" role="status" />
                                </div>
                            </>
                            :
                            <>
                                <h5 className={"mb-3 mt-0 text-orange"}>Your Bets</h5>
                                <div className="table-responsive game-stats" style={{ height: '25vh', overflowY: 'scroll' }}>
                                    <table className="table table-stats">
                                        <thead className="table-header sticky-top table-head">
                                            <tr>
                                                <th className="text-white font-12">{t('player')}</th>
                                                <th className="text-white font-12">{t('bet')}</th>
                                                <th className="text-white font-12">{t('profit')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {/* show only the users game history */}
                                            {/* if lost */}
                                            <tr className="text-danger">
                                                <td>{this.state.userName}</td>
                                                <td>Ksh 300</td>
                                                <td>-Ksh20</td>
                                            </tr>
                                            {/* if won */}
                                            <tr className="text-green">
                                                <td>{this.state.userName}</td>
                                                <td>Ksh 300</td>
                                                <td>+Ksh20</td>
                                            </tr>
                                            {/* {playerRow} */}
                                        </tbody>
                                    </table>
                                    {playerRow.length === 0 &&
                                        <p className="text-center text-muted">{t('no_one_playing_on_this_round')}</p>
                                    }
                                </div>
                            </>
                        }
                    </Modal.Body>
                }
            </Modal>

        );
    }
}

class PlayerRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        const { array, key, clicked } = this.props;

        let color = 'text-success';
        let profit = __.toNumber(array.profit);
        let coin = __.upperCase(array.coin);

        if (profit === 0 || profit === 0.00000000 || profit === '0.00000000') {
            profit = '-' + array.amount;
            color = 'text-gray';
        }

        return (
            <>
                <tr className={color} key={key}>
                    <td>
                        <UserLink clicked={clicked} username={array.name} isWinner={color} />
                    </td>
                    <td className="num-style">
                        {forceSatoshiFormat(array.amount)} {coin}
                    </td>
                    <td className="num-style">
                        {forceSatoshiFormat(profit)} {coin}
                    </td>
                </tr>
            </>
        );
    }
}

function sortByWinner(input) {
    function r(c) {
        return c.profit ? -c.profit : null;
    }

    return __.sortBy(input, r);
}

App.propTypes = {
    coin: PropTypes.string,
    chart_coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin,
    chart_coin: state.items.chart_coin
});

export default connect(mapStateToProps, {})(App);