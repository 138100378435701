import React, {Component} from "react";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {__, decode, encode, forceSatoshiFormat, fixDate} from "../../../../Helper";
import C from "../../../../Constant";
import Lottie from "lottie-react";
import notFound from '../../../../Static/Lottie/not-found.json'

const C_ = React.createElement;

class TransactionHistory extends React.Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            loading: true,
            deposits: []
        };
    }

    componentDidMount(){
        this._isMounted = true;
        socket.emit(C.WALLET_HISTORY, encode({ token: this.state.token}));
        socket.on(C.WALLET_HISTORY, data => this.makeHistory(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    makeHistory(data){
        if(this._isMounted)
        {
            let array = data.deposit;
            this.setState({ loading: false });
            if(array) {
                array.forEach((deposit, i) => {
                    let block = <DepositTable data={deposit} key={i}/>;
                    this.setState(state => ({deposits: [block, ...state.deposits]}));
                });
            }
        }
    }

    render() {
        let { loading } = this.state;
        return(
            <>
                {loading ?
                    <div className="text-center">
                           <div className="spinner-border text-orange my-3" role="status" />
                    </div>
                :
                    <>
                        {!this.state.deposits.length === 0 ?
                            
                            <div className="table-responsive mt-2">
                                <table className="table table-hover deposit">
                                    <thead>
                                    <tr>
                                        <th className="table-head" scope="col">Date</th>
                                        <th className="table-head" scope="col">Amount</th>
                                        <th className="table-head" scope="col">Type</th>
                                        <th className="table-head" scope="col">Status</th>
                                        <th className="table-head" scope="col">Transaction</th>
                                    </tr>
                                    </thead>
                                    {this.state.deposits.length > 0 &&
                                        this.state.deposits
                                    }
                                </table>
                                
                                {/* <div className="text-center">
                                    <button onClick={this.props.clicked} className="btn bg-cs2 btn-sm text-center font-13 cpt">
                                        <i className={'mdi mdi-arrow-left-bold-circle'} /> Back
                                    </button>
                                </div> */}
                            </div>
                        :
                            <div className="mt-2 w-100 font-12 text-center text-upper text-white" style={{textTransform:"none"}}>
                                <Lottie animationData={notFound} loop={true} style={{height:'130px',marginTop:"-1rem"}} />
                                You haven't made any transactions yet
                            </div>
                        }
                    </>
                }
            </>
        );
    }
}

function DepositTable(props){
    const { amount, status, date, txtid, coin } = props.data;
    return(
            <tr>
                <td>{fixDate(date)}</td>
                <td>{forceSatoshiFormat(amount) + ' ' + __.upperCase(coin)}</td>
                <td>{status}</td>
                <td>
                    <input className="form-control" readOnly={true} value={txtid} />
                </td>
            </tr>
    );
}

export default TransactionHistory;