import React from 'react'
import {Row, Col, Card, Tab, Tabs} from "react-bootstrap";
import {Helmet} from "react-helmet";
import storage from "../../../Storage";
import {SITE} from "../../../Helper";
import List from "./List";
import C from "../../../Constant";
import socket from "../../../Socket";
import Withdraw from "./Withdraw";
import formatCurrency from "format-currency";
import Earnings from "./Earnings";

export default class Affiliate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token') ? storage.getKey('token') : null,
            user_id: storage.getKey('user_id') ? storage.getKey('user_id') : null,
            logged: false,
            earnings: 0,
            referral_count: 0,
            copySuccess: ''
        };
    }
    componentDidMount() {
        if (this.state.token !== null) {
            this.setState({logged: true});
        }
        socket.on(C.MY_REFERRAL, data => this.setReferralDetails(data));
    }

    setReferralDetails = (data) => {
        this.setState({earnings: data.earnings, referral_count: data.referral_count});
    }

    copyToClipboard = (e) => {
        navigator.clipboard.writeText(SITE + '/aff/' + this.state.user_id).then(r => {
            this.setState({copySuccess: 'Link copied to clipboard!'});
        });
    };

    render() {
        let {logged, earnings, referral_count} = this.state;

        return (
            <>
                <Helmet>
                    <title>Multiplaya - Referral</title>
                </Helmet>
                <div style={{backgroundSize: 'cover'}}
                     className={'wheel-content pt-2'}> {/* background: 'rgb(108 108 229) url(/assets/images/mountainXX_todo.png)'*/}
                    <Row>
                        <Col style={{background: 'url(/assets/images/referrals1.png)', backgroundSize: 'cover'}} sm={3}>
                        </Col>
                        <Col sm={6} xl={6} md={6} className={'m-auto'}>
                            <Card>
                                <div className={'text-center'} style={{background: "#520474"}}>
                                    <h2 className={'text-center text-warning text-upper font-weight-bold'}>Refer & Earn</h2>
                                    <p className={'text-center text-success-2 font-14'}>
                                        Your referred members will earn you <strong className={'text-success font-14'}>2%</strong> on every deposit they make.*
                                    </p>
                                    {logged ?
                                        <div>
                                            <label className="text-white">Let your friends send <strong
                                                className={'text-success font-14'}>{this.state.user_id}</strong> to <strong
                                                className={'text-success font-14'}>77977</strong>
                                                <p>Or Share Your Referral Link below</p>
                                            </label>
                                            <div className="row">
                                                <div className={'col-12'}>
                                                    <div onClick={this.copyToClipboard} className={"mb-2 text-warning"} style={{height: '44px', borderRadius: '20px', backgroundColor: '#45025F', padding: '0px 16px', display: 'flex', alignItems: 'center', cursor: 'pointer'}}>
                                                        <span style={{flex: '0 1 90%', whiteSpace: 'nowrap'}}>{SITE + '/aff/' + this.state.user_id}</span>
                                                        <i style={{fontSize: '1.5em'}} className={"fa fa-copy"}/>
                                                    </div>
                                                    <span className={"text-success"}>{this.state.copySuccess}</span>
                                                </div>
                                                <br/>
                                                <hr className={'text-success'}/>

                                                <div className={'col-12'}>
                                                    <Card className={"no-shadow mb-1 h-bet"} style={{height: '98%'}}>
                                                        <Card.Body className="bet-form p-0 no-shadow">
                                                            <>
                                                                <Tabs fill defaultActiveKey="stats" id="uncontrolled-tab-bet">
                                                                    <Tab eventKey="stats" title="Performance">
                                                                        <div className={"row"}>
                                                                            <div className="col-6 col-lg-6 col-md-6">
                                                                                <div style={{borderRadius: '5px', backgroundColor: '#520474', border: '1px solid #3B0054'}} className="mb-1 card">
                                                                                    <div className="p-1 card-body">Referrals<br/><b className="num-style text-success">{referral_count}</b></div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-6 col-lg-6 col-md-6">
                                                                                <div style={{borderRadius: '5px', backgroundColor: '#520474', border: '1px solid #3B0054'}} className="mb-1 card">
                                                                                    <div className="p-1 card-body">Earnings<br/>{/*<img className="mini-coin-9" src="/assets/images/kes.png"/>*/}<b className="num-style text-success">KES {formatCurrency(parseFloat(earnings).toFixed(2))}</b></div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </Tab>
                                                                    <Tab eventKey="withdraw" title="Withdraw">
                                                                        <div className={"text-left m-1"} style={{borderRadius: '5px', backgroundColor: '#520474', border: '1px solid #3B0054'}}>
                                                                            <div className="p-1 card-body">
                                                                                <p style={{fontSize:'0.8em'}} className={"text-warning"}>Withdraw <strong className={"text-success"}>KES {formatCurrency(parseFloat(earnings).toFixed(2))}</strong> to Wallet or M-PESA:</p>
                                                                                <Withdraw earnings={earnings}/>
                                                                            </div>
                                                                        </div>
                                                                    </Tab>
                                                                </Tabs>
                                                            </>
                                                        </Card.Body>
                                                    </Card>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <span className={'text-warning'}>Please login to see your link.</span>
                                    }
                                </div>
                            </Card>
                        </Col>
                        <Col style={{background: 'url(/assets/images/referrals1.png)', backgroundSize: 'cover', transform: 'scaleX(-1)'}} sm={3}>
                        </Col>
                    </Row>

                    <Row>
                        <Col sm={12} xl={12} md={12} className={'mx-auto mt-3'}>
                            <Card>
                                <Card.Body style={{padding:'4px'}}>
                                    {!logged ?
                                        <div className="text-center">[ Login to see your stats ]</div>
                                        :
                                        <>
                                            <Tabs fill defaultActiveKey="referrals" id="uncontrolled-tab-bet">
                                                <Tab eventKey="referrals" title="Latest Referrals">
                                                    <div className={"text-left"} style={{borderRadius: '0px 0px 5px 5px', marginTop:'13px', backgroundColor: '#520474', border: '1px solid #3B0054'}}>
                                                        <div className="p-1 card-body">
                                                            <List/>
                                                        </div>
                                                    </div>
                                                </Tab>
                                                <Tab eventKey="ref_earnings" title="Latest Earnings">
                                                    <div className={"text-left"} style={{borderRadius: '0px 0px 5px 5px', marginTop:'13px', backgroundColor: '#520474', border: '1px solid #3B0054'}}>
                                                        <div className="p-1 card-body">
                                                            <Earnings/>
                                                        </div>
                                                    </div>
                                                </Tab>
                                            </Tabs>
                                        </>
                                    }
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}