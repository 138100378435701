import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import storage from "../../../../Storage";
import { sendNotfication, Event, isMobile } from "../../../../Helper";
import Wallet from "../Wallet";
import { NavLink } from "react-router-dom";
import CancelIcon from "../../../../Static/images/cancel.svg";
import NotifIcon from "../../../../Static/images/Avatar/notif.svg";
import RainIcon from "../../../../Static/images/rain.svg";
import EmojiPicker from "emoji-picker-react";
import { Theme } from "emoji-picker-react";
import { showWarningToast } from "../../../../toastNotifications";
import { UpdateHomeIconActive } from "../../../Components/User/ClickService";

class EmojiModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initState: 0,
      show: this.props.show ? this.props.show : false,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    Event.on("show_notification", this.handleShow);
  }

  handleClose() {
    this.setState({ show: false, effect: "zoomOut" });
    UpdateHomeIconActive();
  }

  handleShow() {
    if (storage.getKey("token") === null) {
      showWarningToast("Please Login to use this option.");
      return;
    }

    this.setState({ show: true, effect: "pulse" });
  }

  render() {
    return (
      <>
        {!this.props.show && (
          <NavLink
            to={"#"}
            className="nav-link bottom-nav-link"
            onClick={(e) => this.handleShow(e)}
          >
            <div
              className={
                "row d-flex flex-column justify-content-center align-items-center bottom-menu gray-text"
              }
            >
              <i className={"fa fa-smile bottom-menu-icon"} />
            </div>
          </NavLink>
        )}
        <Modal
          size="md"
          backdrop={"static"}
          centered={true}
          scrollable={false}
          show={this.state.show}
          onHide={this.handleClose}
          onExited={this.handleClose}
          aria-labelledby="wallet-md-modal"
          className={"animated " + this.state.effect}
        >
          <Modal.Header>
            <button
              type="button"
              className="close p-2"
              onClick={this.handleClose}
            >
              <img className="width-20" src={CancelIcon} alt="cancel icon" />
            </button>
          </Modal.Header>
          <Modal.Body className="notif-body">
            <hr className="notif-hr hr-margin" />
            <EmojiPicker width="300px" height="300px" theme="dark" />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default EmojiModal;
