import React from "react";
import {Dropdown} from "react-bootstrap";
import storage from "../../../../Storage";
import coins from "../../../coins";
import socket from "../../../../Socket";
import {__, isMobile, encode, decode, getElement, wait, forceSatoshiFormat, Event, COIN_TOKEN} from "../../../../Helper";
import DepositArchive from "./DepositArchive";
import C from "../../../../Constant";
import Cookies from "js-cookie";
class Deposit extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            //uid: getUID(),
            uid: Cookies.get('uid'),
            currentCoin: COIN_TOKEN,
            loading: true,
            credits: {},
            list: [],
            content: [],
            wallet: 'Please Wait...',
            deposit: true,
            slide: false,
            height: 573,
            margin: 'mt-1',
            phone: storage.getKey('phone'),
            name: storage.getKey('name')
        };

        this.handleButtonAmount = this.handleButtonAmount.bind(this)
    }

    componentDidMount() {
        this._isMounted = true;
        socket.emit(C.CREDIT, encode({token: this.state.token, coin: this.state.currentCoin}));
        socket.on(C.CREDIT, data => this.getCreditAndCoins(decode(data)));
        socket.on(C.UPDATE_PAYMENT_STATUS, (data) => this.paymentUpdate(decode(data)));
        socket.on(C.UPDATE_CREDIT, data => this.getCreditAndCoins(decode(data)));

        wait(400).then(() => {
            this.loadAddress(true, this.state.currentCoin);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    paymentUpdate(data) {
        if (this._isMounted) {
            let {token, uid} = this.state;

            if (uid !== null) {
                if (parseFloat(data.uid) === parseFloat(uid)) {
                    Event.emit('update_payment');
                    this.setState({submitted: false});
                }
            }

            wait(100).then(() => {
                if (token !== null)
                    socket.emit(C.CREDIT, encode({token: token, coin: data.coin}));
            })
        }
    }

    getCreditAndCoins = (data) => {
        if (this._isMounted) {
            let {credit} = data;
            this.setState({credits: credit, loading: false});
            this.setUpCoins();
        }
    };

    getFee = (coin) => {
        coin = __.upperCase(coin);

        let min;
        coins.forEach((item, key) => {
            if (item.preffix === coin)
                min = item.min;
        })

        return min;
    }

    setUpCoins = () => {
        if (isMobile()) {
            this.setState({height: 685, margin: 'mt-1'});
        }

        coins.forEach((item, key) => {
            let list = <Dropdown.Item as={'button'} className={"animated fadeIn"} onClick={(e) => this.loadAddress(item.active, item.preffix)}>
                <span><img src={"/assets/images/" + item.image} className={"img-fluid mini-coin mr-1"} alt="Coin"/> {item.preffix}</span>
            </Dropdown.Item>;
            this.setState(state => ({list: [list, ...state.list]}));
        });
    };

    showArchive = e => {
        this.setState({slide: !this.state.slide});
        wait(500).then(() => {
            this.setState({deposit: !this.state.deposit});
        })
        wait(500).then(() => {
            this.loadAddress(true, this.state.currentCoin);
        })
    };

    loadAddress(active, coin) {
        if (this._isMounted) {
            if (active)
                socket.emit(C.GET_ADDRESS, encode({coin: this.state.currentCoin, token: this.state.token}));
            else {
                getElement('#' + __.upperCase(coin)).value = `${coin} not Supported yet`;
            }
        }
    }

    submit = (e) => {
        e.preventDefault();

        socket.emit(C.DEPOSIT, encode({
            phone: this.state.phone,
            amount: this.state.amount,
        }));
        
        wait(1000).then(() => {
            this.setState({submitted: true});
        })
    }

    handleChangeAmount = (e) => {
        let val = e.target.value;
        this.setState({amount: val});
    }

    handleButtonAmount(el){
        console.log("button value",el.target.value)
        this.setState({amount:el.target.value})
    }

    render() {
        let {height, margin, loading} = this.state;
        let style;
        let size = 'w-25';
        let demo_credit = parseFloat(this.state.credits['demo']).toFixed(2);
        let bonus_credit = parseFloat(this.state.credits['bonus']).toFixed(2);

        if (isMobile()) {
            style = "col-2";
            size = 'w-50'
        }

        return (
            <>
                {
                    this.state.deposit ?
                        <div className={this.state.slide ? 'animated slideOutLeft ' : ' font-14'}>
                            {this.state.loading ?
                                <div className="ycenter text-center">
                                    <div className="spinner-border text-orange" role="status"/>
                                </div>
                                :
                                <>
                                    {/* <div className="lrow text-center text-white p-1 ycenter">
                                        <div className="border-right">
                                            <img src={"/assets/images/" + this.state.currentCoin + '.png'} className={"img-fluid mini-coin-12 mr-2"} alt="Coin"/> <span className={'text-success'}>Balance</span><br/>
                                            <span className="text-white font-15">{forceSatoshiFormat(this.state.credits[this.state.currentCoin])}</span>
                                        </div>
                                        <div className="text-center mt-1">
                                            <span className={'text-success'}>Bonus</span>
                                            <p className="text-white font-15">{bonus_credit}</p>
                                        </div>
                                        <div className="text-center mt-1">
                                            <span className={'text-success'}>Demo</span>
                                            <p className="text-white font-15">{demo_credit}</p>
                                        </div>
                                    </div> */}
                                    <div>
                                        <p className="row margin-0 text-white">Select amount to deposit</p>
                                        <div className="row padding-10" style={{display:"flex",justifyContent: 'space-between',padding:"10px 0"}}>
                                            <div className="padding-0">
                                                <button className="deposit-amount-btn font-10" value={'100'} onClick={this.handleButtonAmount}>Ksh 100</button>
                                            </div>
                                            <div className="padding-0 ">
                                                <button className="deposit-amount-btn font-10" value={'1000'} onClick={this.handleButtonAmount}>Ksh 1000</button>
                                            </div>
                                            <div className="padding-0">
                                                <button className="deposit-amount-btn font-10" value={'1500'} onClick={this.handleButtonAmount}>Ksh 1500</button>
                                            </div>
                                            <div className=" padding-0">
                                                <button className="deposit-amount-btn font-10" value={'2000'} onClick={this.handleButtonAmount}>Ksh 2000</button>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={"row "}>
                                        <div className={"col-sm-12 padding-0"}>
                                            <div className="form-group text-center">
                                                
                                                {
                                                    this.state.submitted ?
                                                        <span className="text-white">
                                                            <div className="spinner-border spinner-border-sm text-orange" role="status"/>
                                                                <br/>
                                                                Please enter your PIN in the prompt on your phone
                                                        </span>
                                                        :
                                                        <form onSubmit={(e) => {
                                                            this.submit(e)
                                                        }}>
                                                            <input type="hidden" value={this.state.phone} name="phone"/>
                                                            <div className="form-group text-left padding-10" style={{paddingTop:"10px"}}>
                                                                <input
                                                                    type="number"
                                                                    name={'amount'}
                                                                    className={'deposit-input w-100 '}
                                                                    min={10}
                                                                    placeholder="Amount"
                                                                    value={this.state.amount}
                                                                    onChange={this.handleChangeAmount}
                                                                    required={true}
                                                                    autoComplete={'off'}
                                                                />
                                                            </div>
                                                            <button type="submit" className="btn btn-block deposit-btn bg-cs2 orange-btn w-100">Deposit from MPESA</button>
                                                        </form>
                                                }
                                                <br/>
                                                {/* <div onClick={this.showArchive} className={'text-record font-12'}>Previous Deposits</div> */}
                                            </div>
                                        </div>
                                        {/* <div className={"col-sm-6 border-left"}>
                                                <span style={{textAlign: "left", color: 'white', fontSize:'11px'}}>
                                                    <span className={"yellow"}>OR</span><br/>
                                                    &#10004; Go to <b>M-PESA Menu</b><br/>
                                                    &#10004; Select <b> Pay Bill</b><br/>
                                                    &#10004; Enter Business Number - <b> 779779</b><br/>
                                                    &#10004; Enter Account Number - <b>{this.state.phone.replace('254', '0')}</b><br/>
                                                    &#10004; Enter Amount - <span id="wallet_amount">e.g. 10,000</span><br/>
                                                    &#10004; Enter your <b>M-PESA PIN and Click Send</b>
                                                </span>
                                        </div> */}
                                    </div>
                                </>
                            }
                        </div>
                        :
                        <>
                            <div className={this.state.slide == false ? 'animated slideOutLeft' : ''}>
                                <DepositArchive clicked={this.showArchive}/>
                            </div>
                        </>
                }
            </>
        );
    }
}

export default Deposit;