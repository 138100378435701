import React from "react";
import storage from "../../../../Storage";
import Engine from "../Engine";
import Avatar from "react-avatar";
import LinearProgress from "@mui/material/LinearProgress";
import socket from "../../../../Socket";
import {decode, encode, fixDate} from "../../../../Helper";

class JackpotMine extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            games: [],
            name: storage.getKey('name'),
            token: storage.getKey('token'),
            engine: Engine,
            jackpot_id: 1, //todo
            loading: true,
            my_entries: []
        };
    }

    componentDidMount() {
        let {engine} = this.state;

        socket.emit('JACKPOT_MINE', encode({token: this.state.token, jackpot_id : this.state.jackpot_id}));
        socket.on('JACKPOT_MINE', data => this.jackpotMine(data));
    }

    jackpotMine(data) {
        console.log('[temp] jackpot mine:', data);
        this.setState({loading: false, my_entries: (data)});
    }

    render() {
        const list = this.state.my_entries.map((entry, i) =>
            <Entry key={i} number={i + 1} entry={entry}/>
        );

        return (
            <>
                {
                    this.state.loading ?
                        <div className="text-center">
                            <div className="spinner-grow text-white my-3" role="status"/>
                        </div>
                        :
                        <table className="table margin-10 jackpot-winner-table font-12">
                            <tr>
                                <th className="text-left header-color" colSpan="3">My Jackpot Stats</th>
                            </tr>
                            <tr className="header-row text-white">
                                <th style={{width: '15%'}}>Rank</th>
                                <th>Date/Time</th>
                                <th>Crash Point</th>
                            </tr>
                            {list}
                        </table>
                }
            </>
        );
    }
}

class Entry extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {entry, number} = this.props;

        let created = fixDate(entry.created);
        let cashout = entry.cashout;

        return (
            <tr className="content-row">
                <td><Avatar name={number + 'Holder'} size='18' textSizeRatio='2' round style={{marginRight: "5px"}}/></td>
                <td className="header-color text-weight-300 font-11">
                    {created}
                </td>
                <td>{cashout}</td>
            </tr>
        );
    }
}

export default JackpotMine;