import React, {Component} from 'react'
import {Event, __} from "../../../../Helper";
import {NavLink} from "react-router-dom";
import Chat from '../../../Pages/Parts/Chat'
import { NotifClicker, WalletClick ,StatsClick , SettingsClick, VipClick} from '../ClickService';
import ReactModal from '@mui/material/Modal'
import Fade from '@mui/material/Fade';
import socket from '../../../../Socket';
import C from "../../../../Constant";



class ChatModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initState: 0,
            show: this.props.show ? this.props.show : false,
            modalIsOpen: true,
            newCharts: 0 
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        Event.on('show_chat', this.handleShow);
        socket.on(C.ADD_CHAT, data => this.getNewChats());

    }
    getNewChats() {
        this.setState({newCharts : this.state.newCharts+1});
    }
    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    handleShow(e) {
        this.setState({show: true, effect: 'pulse'});
        NotifClicker();
        WalletClick();
        StatsClick();
        SettingsClick();
        VipClick();
        // close all other modals
    }
    resetChartCount(){
        this.setState({newCharts : 0});
    }
    render() {
        return (
            <>
                {!this.props.show &&
                    <NavLink to={'#'} className="nav-link bottom-nav-link" onClick={e =>{ this.resetChartCount(); return this.handleShow(e)}}>
                        <div className={"row d-flex flex-column justify-content-center bottom-menu align-items-center gray-text"}>
                            <i className={"fa fa-comment-dots bottom-menu-icon chat-nav"}><span class="badge">{this.state.newCharts}</span></i>
                            <div className={"bottom-tab-label font-9 font-9"}>Chat</div>
                        </div>
                    </NavLink>
                }

                <ReactModal 
                    open={this.state.show}
                    onClose={this.handleClose}
                    className={'chat-modal-container'}
                >
                    <Fade in={this.state.show}>
                        <div className="custom-modal-container chatroom-modal" style={{height:'100%'}}>
                            <div className='font-14 modal-heading'>
                                <i className={"fa fa-comment-dots bottom-menu-icon"}/>
                                <b>&nbsp;&nbsp;Chat</b>
                                <button type="button" id="close-chat" className="close " onClick={this.handleClose}>
                                    <i className="fa fa-close text-orange font-18 font-300" />
                                </button>
                            </div>
                            <Chat/>
                        </div>
                    </Fade>
                </ReactModal>
            </>
        );
    }
}

export default ChatModal;