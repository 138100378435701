import React from 'react'
import {Row} from "react-bootstrap";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import {isMobile, wait, Event, decode} from "../../../Helper";
import storage from "../../../Storage";
import Engine from "./Engine";
import Canvas from "./Graphic";
import MobileCanvas from "./Graphic/Mobile";
import HistoryList from "./includes/HistoryList";
import Bet from "./Bet";
import PlayersPanel from "./PlayersPanel";
import CrashIcon from '../../../Static/images/crash-icon.svg'
import Logo from '../../../Static/images/logo.svg'
import PlayaIcon from '../../../Static/images/playa1.svg'
import socket from '../../../Socket';


class Index extends React.Component {
    _Mounted = false;

    constructor(props) {
        super(props);
        this.state = {
            height: null,
            mobile: false,
            isLogged: false,
            token: false,
            padding: "p-1",
            onlineUsers: 5, //todo
            network_class: 'network-status'
        };
        this.handleResize = this.handleResize.bind(this);
    }

    componentDidMount() {
        wait(600).then(() => {
            this.handleResize();
        });
        window.addEventListener('resize', this.handleResize);
        if (storage.getKey('token')) {
            this.setState({isLogged: true, token: storage.getKey('token')});
        }

        wait(500).then(() => {
            Engine.getStatus();
        });

        Event.on("connect", (data) => this.onlineStatus(data));
        Event.on("disconnect", (data) => this.offlineStatus(data));
    }

    componentWillMount() {
        wait(400).then(() => {
            this._Mounted = true;
        })
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
        this._Mounted = false;
    }

    handleResize() {
        if (this.getWidth() < 1540) {
            this.setState({col: 'col-xl-12'});
            Event.emit('hide_games');
        } else {
            this.setState({col: 'col-xl-9'});
            Event.emit('show_min_games');
        }

        if (isMobile()) {
            this.setState({mobile: true});
        } else {
            this.setState({mobile: false});
        }

        if (isMobile()) {
            this.setState({padding: 'p-0', ovh: 'ovh'});
        }
    }

    getWidth() {
        return document.documentElement.clientWidth || document.body.clientWidth;
    }

    onlineStatus = (data) => {
        console.log("[temp] socket online ...");
        this.setState({network_class: 'network-status'});
    }

    offlineStatus = (data) => {
        console.log("[temp] socket offline ...");
        this.setState({network_class: 'network-lost'});
    }

    render() {
        let token = storage.getKey('token');
        let {col, padding, mobile, ovh, network_class} = this.state;
        const {t} = this.props;

        let c_mobile = mobile ? '5.2rem' : '';

        return (
            <>
                <ReactCSSTransitionGroup
                    transitionAppear={true}
                    transitionAppearTimeout={250}
                    transitionEnterTimeout={200}
                    transitionLeaveTimeout={200}
                    transitionName={'SlideIn'}
                >
                    {this._Mounted ?
                        <Row className={"animated fadeIn"}>
                            <div style={{marginBottom: c_mobile}} className={"col-md-12 pr-md-1"}>
                            {mobile &&
                                <>
                                    <div className={" game-card row remove-margin "}>
                                        <div className={"col-md-8 pr-1 pr-md-0 pl-1 mb-1"}>
                                            <div className={"animated pulse padding-15"}>
                                                <HistoryList className="history-container" game={'crash'} t={this.props.t}/>
                                                {!mobile ?
                                                    <Canvas mobile={this.state.mobile}/>
                                                    :
                                                    <MobileCanvas mobile={this.state.mobile}/>
                                                }
                                                <div className={"row "}>
                                                    <div className={"col-6 text-left text-white font-11 pad-left-30"}>
                                                        <i className="fa fa-circle online-dot"/>&nbsp;&nbsp; Players: <span className="text-white" id={"online_players"}>{this.state.onlineUsers} </span> 
                                                    </div>
                                                    <div className={"col-6 text-left text-white font-11 pad-left-30 flex-end"}>
                                                        Network Status: &nbsp;&nbsp; <span id="network-indicator" className={network_class}> </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    
                                    <div className={"col-md-6 mb-1 top-10"} style={{paddingLeft:'0',paddingRight:'0'}}>
                                        {(storage.getKey('token') !== null) &&
                                            <Bet mobile={this.state.mobile} token={this.state.token} isLogged={this.state.isLogged}/>
                                        }
                                        {(storage.getKey('token') === null) &&
                                            // <p className='text-center remove-margin top-10'>Login to start playing!</p>
                                            <button className='orange-btn' onClick={this.showLogin}>
                                                Multiply Now
                                            </button>
                                        }
                                    </div>
                                </>
                                }
                                {!mobile &&
                                    <div className='justify-space'>
                                        <div className={"game-card pr-1 pr-md-0 pl-1 mb-1"} style={{width:'70%'}} >
                                            <div className={"animated pulse padding-15"}>
                                                <HistoryList className="history-container" game={'crash'} t={this.props.t}/>
                                                {!mobile ?
                                                    <Canvas mobile={this.state.mobile}/>
                                                    :
                                                    <MobileCanvas mobile={this.state.mobile}/>
                                                }
                                                <div className={"row "}>
                                                    <div className={"col-6 text-left text-white font-11 pad-left-30"}>
                                                        <i className="fa fa-circle online-dot"/>&nbsp;&nbsp; Players: <span className="text-white" id={"online_players"}>{this.state.onlineUsers} </span> 
                                                    </div>
                                                    <div className={"col-6 text-left text-white font-11 pad-left-30 flex-end"}>
                                                        Network Status: &nbsp;&nbsp; <span id="network-indicator" className={network_class}> </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className={"mb-1 game-card"}  style={{paddingLeft:'0',paddingRight:'0',width:'28%'}}>
                                            {(storage.getKey('token') !== null) &&
                                                <Bet mobile={this.state.mobile} token={this.state.token} isLogged={this.state.isLogged}/>
                                            }
                                            {(storage.getKey('token') === null) &&
                                                <button className='orange-btn' onClick={this.showLogin}>
                                                   Multiply Now
                                                </button>
                                            }
                                        </div>
                                    </div>
                                    

                                    }
                                {mobile && 
                                    <div className={"col-12 mb-1 top-10"} style={{paddingLeft:'0',paddingRight:'0'}}>                                    
                                        <div className={"dark-card-player pr-1 pl-1"}>
                                            <h6 className="text-left text-white" style={{padding:'0px 15px'}}>Multiplaya Stats</h6>
                                            <PlayersPanel/>
                                        </div>
                                    </div>
                                
                                }
                                {!mobile && 
                                    <div className={" mb-1 top-10"} style={{paddingLeft:'0',paddingRight:'0',width:"70%"}}>                                    
                                        <div className={"dark-card-player pr-1 pl-1"}>
                                            <h6 className="text-left text-white" style={{padding:'0px 15px'}}>Multiplaya Stats</h6>
                                            <PlayersPanel/>
                                        </div>
                                    </div>
                                }
                                <div className={"col-md-12 padding-0 margin-10 mb-1 padding-2rem margin-0"}>                                       
                                    <div className="dark-card row remove-margin">
                                        <div className='col-4'>
                                            <img src={CrashIcon} alt="crash game icon"/>
                                        </div>
                                        <div className='col-8'>
                                            <h4 className='padding-2rem text-white'>How to Play Crash</h4>
                                            <p></p>
                                        </div>
                                        <div className='col-12 top-10'>
                                            <p className='font-12'>Crash is a classic luck-based type of game that is easy to get started with, even for new players not familiar with casino games. <br/><br/>
                                                As this is purely a game of chance, winning strategies involve how a player bets. Players betting on low cashout values aim are for more consistent payouts whilst betting high cashout values aim for a higher return in a single round with more volatile gameplay.<br/><br/>
                                                The concept is simple: every 5 seconds after a round of Crash is played, players can make their bets on what their cashout value will be for the upcoming round.<br/><br/>
                                                This is the amount that that player will cashout before the rocket 'crashes'. If the player hits the cashout value during a round, they receive a payout based on that cashout amount. However, if the rocket crashes at a value lower than their cashout bet, the player loses the bet for that round.<br/><br/>
                                                One of the exciting aspects of Crash is that it is a real-time 
                                                multiplayer
                                                game and is considered a favourite amongst the Stake Community. Every round is the same for all active players playing Crash at that specific time - with a live leader board showcasing each player's bets for that round.<br/><br/>
                                                With the live community playing this game of chance, provably fair gameplay and a maximum cashout value of 1,000,000x, Crash will sure to be a casino game to try out for new players!<br/><br/>
                                                <span className='font-14 font-500'><b>Betting Options for Crash</b></span><br/>
                                                There are two types of betting for our Crash casino game: Manual Bet and Auto Bet.<br/><br/>
                                                Manual Bet allows the player set the bet amount for a round of crash, as well as the Cashout At value for when the player would like to cashout during the next round. Profit on Win displays the estimated profit a player would get if they were able to place a winning bet. <br/><br/>
                                                Hotkeys are also available which are best utilised to control your betting during the 5 seconds interval. Hotkeys for Crash include 's' to double bet amount, 'a' to halve a bet, 'd' to zero a bet, 'spacebar' to make a bet and 'q' to Cancel/cashout.<br/><br/>
                                                Auto Bet is available for players interested in using sophisticated betting strategies and who have a strong understanding of bankroll management. If enabled, this allows betting behaviour based on profit and loss performance for future rounds of Crash which can be set via Auto Bet options.<br/><br/>
                                                Beyond setting the bet amount and Cashout At values like a manual bet, Auto Bet provides advanced betting options such as the Number of Bets during Auto Bet, whether to Increase or Reset bet on Win or Loss, as well as the ability to Stop on Profit or Loss after hitting a certain win amount overall.<br/><br/>
                                                These betting options provide a variety of ways players can win big and grow their bankroll playing a fun game of chance like Crash on our casino platform.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className={"col-md-12 padding-0 margin-10 mb-1 padding-2rem margin-0"}>
                                    <div className='row dark-card remove-margin'>
                                        <div className='col-6'>
                                            <p className='font-13 text-white'>About Us</p>
                                            <ul className='footer-ul'>
                                                <li>Privacy Policy</li>
                                                <li>Licences</li>
                                                <li>Terms of Service</li>
                                                <li>Self Exclusion</li>
                                            </ul>
                                        </div>
                                        <div className='col-6'>
                                            <p className='font-13 text-white'>Community</p>
                                            <ul className='footer-ul'>
                                                <li>Facebook <i className='fa fa-link font-10'/></li>
                                                <li>Twitter <i className='fa fa-link font-10'/></li>
                                                <li>Instagram <i className='fa fa-link font-10'/></li>
                                                <li>Youtube <i className='fa fa-link font-10'/></li>
                                            </ul>
                                        </div>
                                        <div className='col-12 margin-10'>
                                            <img className='center-item' src={Logo} alt='logo'/>
                                            <p className='text-center'>© {new Date().getFullYear()} multiplaya.com | All Rights Reserved.</p>
                                        </div>
                                    </div>   
                                </div>
                            </div>
                        </Row>
                        :
                        <div className="game-loader text-center">
                            <img src={PlayaIcon} width='30px' className='rotate center-div' alt='loader'/>
                        </div>
                    }
                </ReactCSSTransitionGroup>
            </>
        );
    }

    showLogin() {
        Event.emit('show_login');
    }

    showRegister() {
        Event.emit('show_register');
    }
}

export default Index;