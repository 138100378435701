import React from "react";
import {Card, Tab, Tabs} from "react-bootstrap";
import Classic from './includes/Classic';
import Special from "./includes/Special";
import {isMobile, getUID} from "../../../Helper";
import Engine from "./Engine";
import Jackpots from "./includes/Jackpots"

class Bet extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            bet: 'manual',
            uid: getUID,
            classicDisabled: false,
            specialDisabled: false,
            jackpotDisabled: false,
            playing_now: 0
        };

        this.toggleClassic = this.toggleClassic.bind(this);
        this.toggleSpecial = this.toggleSpecial.bind(this);
        this.toggleJackpot = this.toggleJackpot.bind(this);
    }

    componentDidMount() {
        const engine = Engine;

        if (isMobile()) {
            this.setState({padding: 'p-1', margin: 'mt-1'})
        }
    }

    toggleClassic(status) {
        this.setState({classicDisabled: status});
    }

    toggleSpecial(status) {
        this.setState({specialDisabled: status});
    }

    toggleJackpot(status) {
        this.setState({jackpotDisabled: status});
    }

    setUsersPlaying(data) {
        this.setState({playing_now: data});
    }

    render() {
        let {token, isLogged, mobile} = this.props;
        let {classicDisabled, specialDisabled, playing_now} = this.state;

        return (
            <Card className={"no-shadow mb-1 h-bet dark-card"}>
                <Card.Body className="bet-form p-0 no-shadow" style={{marginTop: "-15px"}}>
                    <>
                        <div className="top-10">
                            <Tabs fill defaultActiveKey="manual" id="uncontrolled-tab-bet" className="tabs-container ">
                                <Tab tabClassName={'w-100'} disabled={classicDisabled} eventKey="manual" title="Classic">
                                    <Classic toggleClassic={this.toggleClassic} toggleSpecial={this.toggleSpecial} token={token} isLogged={isLogged} mobile={mobile}/>
                                </Tab>
                                <Tab tabClassName={'w-100'} disabled={specialDisabled} eventKey="special" title="Colours">
                                    <Special toggleClassic={this.toggleClassic} toggleSpecial={this.toggleSpecial} token={token} isLogged={isLogged} mobile={mobile}/>
                                </Tab>
                                <Tab tabClassName={'w-100'} disabled={specialDisabled} eventKey="jackpot" title="Jackpot">
                                    <Jackpots toggleJackpot={this.toggleClassic} toggleSpecial={this.toggleSpecial} token={token} isLogged={isLogged} mobile={mobile}/>
                                </Tab>
                            </Tabs>
                        </div>

                    </>
                </Card.Body>
            </Card>
        );
    }
}

export default Bet;