import React from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";

import Index from "./App/Pages";
import NotFound from "./App/Pages/404";
import Support from "./App/Pages/Support";
import Affiliate from "./App/Pages/Affiliate";
import Bonus from "./App/Pages/Affiliate/Bonus";

import UserStat from "./App/Components/User/Stat";
import GameStat from "./App/Components/Game/Stat";
import UserSetting from "./App/Components/User/Setting";
import Leaderboard from "./App/Pages/Leaderboard";
import Register from "./App/Pages/Auth/Register";
import Login from "./App/Pages/Auth/Login";

import Notifications from "./App/Components/User/Notifications";

import Crash from "./App/Games/Crash";
import ReferralEarners from "./App/Pages/ReferralEarners";
import HappyHour from "./App/Pages/Bonus/HappyHour";

function Router(props) {

    let location = useLocation();
    const navigate = useNavigate();
    let background = location.state && location.state.background;

    const { t } = props;

    return (
        <>
            <Routes location={background || location}>
                <Route exact path="/" element={<Index navigate={navigate} t={t} location={location} content={<Crash t={t} />} />} />
                <Route path="/register" element={<Index navigate={navigate} t={t} content={<Register show={true} t={t} />} />} />
                <Route path="/login" element={<Index navigate={navigate} t={t} content={<Login show={true} t={t} />} />} />
                <Route path="/notifications" element={<Index navigate={navigate} t={t} content={<Login show={true} t={t} />} />} />
                <Route path="/leaderboard" element={<Index navigate={navigate} t={t} content={<Leaderboard t={t} />} />} />
                <Route path="/earners" element={<Index navigate={navigate} t={t} content={<ReferralEarners t={t} />} />} />
                <Route path="/support" element={<Index navigate={navigate} t={t} content={<Support t={t} />} />} />
                <Route path="/affiliate" element={<Index navigate={navigate} t={t} content={<Affiliate t={t}/>} />} />
                <Route path="/happyhour" element={<Index navigate={navigate} t={t} content={<HappyHour t={t} />} />} />
                <Route path="/aff/:id" element={<Index navigate={navigate} t={t} content={<Bonus params={location} navigate={navigate} />} />} />
                <Route path="/setting" element={<Index navigate={navigate} t={t} content={<UserSetting t={t} />} />} />
                <Route path="/user/:id" element={<Index navigate={navigate} t={t} content={<UserStat t={t} params={location} />} />} />
                <Route path="/game/:id" element={<Index navigate={navigate} t={t} content={<GameStat t={t} params={location} />} />} />
                <Route path="*" element={<NotFound/>}/>
            </Routes>
        </>
    );
}

export default Router;