export function NotifClicker(){
    var notif_btn = document.getElementById('close-btn');

    if(notif_btn!==null){
        notif_btn.click();
    }
    
}

export function ChatClick(){
    var btn = document.getElementById('close-chat');

    if(btn!==null){
        btn.click();
    }
    
}

export function WalletClick(){
    var btn = document.getElementById('close-wallet');

    if(btn!==null){
        btn.click();
    }
    
}

export function SettingsClick(){
    var btn = document.getElementById('close-settings');

    if(btn!==null){
        btn.click();
    }
    
}

export function StatsClick(){
    var btn = document.getElementById('close-stats');

    if(btn!==null){
        btn.click();
    }  
}

export function LoginClick(){
    var btn = document.getElementById('login-close');

    if(btn!==null){
        btn.click();
    }  
}

export function RegisterClick(){
    var btn = document.getElementById('register-close');

    if(btn!==null){
        btn.click();
    }  
}

export function VipClick(){
    var btn = document.getElementById('close-vip');

    if(btn!==null){
        btn.click();
    }
}

export function UpdateHomeIcon(){
    var icon = document.getElementById('home-icon')

    if(icon!==null){
        console.log('found me!')
        icon.classList.remove('fill-svg-orange')
        icon.classList.add('fill-gray')
    }
}

export function UpdateHomeIconActive(){
    var icon = document.getElementById('home-icon')

    if(icon!==null){
        icon.classList.remove('fill-gray')
        icon.classList.add('fill-svg-orange')
    }
}

