import React from 'react'
import User from "./../User";
import {isMobile, Event} from "../../../../Helper";
import Wallet from "../../../Components/User/Wallet";
import {Nav, NavItem} from "reactstrap";
import {NavLink} from "react-router-dom";
import Logout from "../../Auth/Logout";
import Credit from "../Credit";
import Setting from "../../../Components/User/Setting";
import RealTime from "../RealTime";
import PageLogo from '../../../../Static/images/logo.svg'
import storage from "../../../../Storage";
import UserAccount from '../../../Components/User/UserAccount';
import Avatar1 from '../../../../Static/images/avatars/avatar1.svg'
import Avatar2 from '../../../../Static/images/avatars/avatar2.svg'
import Avatar3 from '../../../../Static/images/avatars/avatar3.svg'
import Avatar4 from '../../../../Static/images/avatars/avatar4.svg'
import Avatar5 from '../../../../Static/images/avatars/avatar5.svg'
import useSound from 'use-sound'
import sound from '../../../../Static/sounds/sound.wav'
import { FullScreen } from '@chiragrupani/fullscreen-react';

const images =[
    Avatar1,Avatar2,Avatar3,Avatar4,Avatar5
]

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            header: null,
            show: true,
            name: storage.getKey('name'),
            blurWallet:true,
            currentImageindex: Math.floor(Math.random() * images.length),
            playSound: '',
            isFullScreen:false
        }
        this.handleResize = this.handleResize.bind(this);
        this.hideWallet = this.hideWallet.bind(this);
    }

    componentDidMount() {
        this.handleResize();
        this.chooseAvatar();
        
        window.addEventListener('resize', this.handleResize);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResize);
    }

    handleResize() {
        if (!isMobile()) {
            this.setState({header: this.desktop()});
        } else {
            this.setState({header: this.mobile()});
        }
    }

    playAudio = () => {
        new Audio(sound).play();
      }

    collapse = () => {
        this.setState({show: !this.state.show})
        Event.emit('toggle_sidebar', this.state.show);
    }

    chooseAvatar = () =>{
        const randomNumber = Math.floor(Math.random() * images.length);
        this.setState({
            currentImageIndex: randomNumber
        });
    }

    showVerifyMpesa() {
        Event.emit('show_verify_mpesa');
    }

    showSettings(){
        Event.emit('show_user_account');
    }

    hideWallet(){
        this.setState({blurWallet: !this.state.blurWallet});
    }

    desktop = () => {
        const {t} = this.props;

        //margin
        let logo_margin = '4rem';
        if (window.innerWidth <= 320) {
            logo_margin = '0.5rem';
        } else if (window.innerWidth > 320 && window.innerWidth < 425) {
            logo_margin = '2rem';
        }

        return (
            <nav id={"topbar"} className="p-0 navbar navbar-expand-md navbar-light desktop-topbar sticky-top" role="navigation">
                <div className="container-fluid pl-0 pr-0" >
                    <RealTime t={t}/>
                    <a className="navbar-brand" href="/">
                        <span><img src={PageLogo} className="logo-sm"/></span>
                    </a>
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center', background: '#1C252C',borderRadius: '8px',padding:'5px'}} >
                        <div onClick={this.hideWallet}>
                            <i className={`${!this.state.blurWallet ? 'fa-eye-slash' : 'fa-eye'} fa text-orange font-13`} />
                        </div>

                        <Wallet t={t}/>
                        <div className={`${!this.state.blurWallet ? 'blur-content' : ''}`}>
                            <Credit t={t}/>
                        </div>
                    </div>
                   
                    <div className='text-white font-700' style={{display:"flex",alignItems:"center", justifyContent:'space-evenly'}} onClick={this.showSettings} >
                        <img src={Avatar2} style={{background:'#27343E',marginRight:'10px'}} className='avatar-nav' alt="avatar"/> 
                        <div className=''>
                            <h6 className='font-10 margin-0' style={{marginTop:'0'}} >{this.state.name} </h6>  
                            <p className='margin-0 font-10'>Level 2</p> 
                        </div>
                        
                    </div>
                </div>
            </nav>
        );
    }

    mobile = () => {
        const {t} = this.props;

        //margin
        let logo_margin = '4rem';
        if (window.innerWidth <= 320) {
            logo_margin = '0.5rem';
        } else if (window.innerWidth > 320 && window.innerWidth < 425) {
            logo_margin = '2rem';
        }

        return (           
            <div className={"p-0 navbar navbar-expand-md navbar-light sticky-top mobile-topbar "} style={{display:'flex',alignItems:"center",justifyContent: 'space-between'}}>
                <div style={{justifyContent:"flex-start",width:"33%"}} >
                    <RealTime t={t}/>
                    <a style={{marginRight: logo_margin}} className={"navbar-brand"} href={"/"}>
                        <span><img src={PageLogo} className="logo-mini" alt='logo'/></span>
                    </a>
                </div>

                <div style={{display:'flex',alignItems:'center',justifyContent:'center', background: '#1C252C',borderRadius: '8px'}} >
                    <div onClick={this.hideWallet}>
                        <i style={{paddingLeft:'5px'}} className={`${!this.state.blurWallet ? 'fa-eye-slash' : 'fa-eye'} fa text-orange font-13`} />
                    </div>

                    <Wallet t={t}/>
                    <div className={`${!this.state.blurWallet ? 'blur-content' : ''}`}>
                        <Credit t={t}/>
                    </div>
                </div>
                
                <div className='text-white font-700' style={{width:"24%", display:"flex",alignItems:"center", justifyContent:'space-evenly'}} onClick={this.showSettings} >
                    <img src={Avatar2} style={{background:'#27343E'}} className='avatar-nav' alt="avatar"/> 
                    <div className=''>
                        <h6 className='font-10 margin-0' style={{marginTop:'0'}} >{this.state.name} </h6>  
                        <p className='margin-0 font-10'>Level 2</p> 
                    </div>
                     
                </div>
                
            </div>
        );
    }

    render() {
        return this.state.header;
    }
}

export default Header;