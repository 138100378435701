import React from 'react'
import {Helmet} from 'react-helmet'
import {Table} from "react-bootstrap";
import socket from "../../../Socket";
import {encode, formatAmount} from "../../../Helper";
import UserModal from "../../Components/User/Stat/Modal";
import {Link} from "react-router-dom";
import storage from "../../../Storage";
import formatCurrency from "format-currency";
import WithdrawBonus from "./WithdrawBonus";
import Engine from "../../Games/Crash/Engine";
import C from "../../../Constant";

class HappyHour extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token') ? storage.getKey('token') : null,
            loading: true,
            datalist: [],
            logged: false,
            earnings: 0,
            earnings_today: 0,
            ending_time: '',
            bonus: '0',
        }
    }

    componentDidMount() {
        if (this.state.token !== null) {
            this.setState({logged: true});
            socket.emit(C.CREDIT, encode({token: this.state.token, coin: 'kes'}));
        }
        socket.emit('HAPPY_HOUR_TODAY', encode({token: this.state.token}));
        socket.on('HAPPY_HOUR_TODAY', data => this.setHappyHourDetails(data));

        socket.emit('HAPPY_HOUR_EARNINGS', encode({token: this.state.token}));
        socket.on('HAPPY_HOUR_EARNINGS', data => this.makeList(data));

        Engine.trigger.on("update_bonus", (data) => this.updateBonus(data));
    }

    makeList = (data) => {
        this.setState({loading: false, datalist: (data)})
    };

    setHappyHourDetails = (data) => {
        this.setState({earnings: data.earnings, earnings_today: data.earnings_today, ending_time: data.ending_time});
    }

    extractTime(ending_time) {
        let unix = Math.floor(new Date(ending_time).getTime() / 1000);
        const milliseconds = unix * 1000;
        const dateObject = new Date(milliseconds);
        return dateObject.toLocaleTimeString();
    }

    updateBonus(data) {
        this.setState({bonus: data});
    }

    render() {
        let {logged, earnings, earnings_today, ending_time, bonus} = this.state;

        const list = this.state.datalist.map((dlist, i) =>
            <DataList key={i} index={i + 1} dlist={dlist}/>
        );

        return (
            <>
                <Helmet>
                    <title>Top Happy Hour Earners</title>
                </Helmet>
                <div>
                    {
                        this.state.loading ?
                            <div className="text-center">
                                <div className="spinner-grow text-white my-3" role="status"/>
                            </div>
                            :
                            <>
                                <div className={"text-left p-1"} style={{borderRadius: '5px', marginTop: '7px', backgroundColor: '#520474', border: '1px solid #3B0054'}}>
                                    <div className={'row mb-1'}>
                                        <div className={'col-10 pr-0'}>
                                            <h5 className={'text-warning font-weight-bold'}>Happy Hour</h5>
                                            <p className={'text-success-2 font-10 mb-0'}>&#10004; Earn <strong className={'text-warning font-13'}>5%</strong> of all Your Bets today before <strong className={'text-danger font-13'}>{this.extractTime(ending_time)}</strong></p>
                                            <p className={'text-success-2 font-10 mb-0'}>&#10004; Offer valid daily for the First Hour you start Betting</p>
                                        </div>
                                        <div className={'col-2 text-center p-0'}>
                                            <img className={'mt-2'} style={{width: '50px'}} src={'/assets/images/prize.png'} alt='CUP'/>
                                        </div>
                                    </div>

                                    <hr style={{borderColor: '#3B0054'}} className={"mb-1 mt-2"}/>

                                    <div className={"row text-center font-12 mb-2"}>
                                        <div className={"col-4 p-0"}>
                                            Today's Earnings<br/>
                                            <b className="num-style text-success">KES {formatCurrency(parseFloat(earnings_today).toFixed(2))}</b>
                                        </div>
                                        <div className={"col-4 p-0"} style={{borderLeft: 'solid 1px #3B0054'}}>
                                            Total Earnings<br/>
                                            <b className="num-style text-success">KES {formatCurrency(parseFloat(earnings).toFixed(2))}</b>
                                        </div>
                                        <div className={"col-4 p-0"} style={{borderLeft: 'solid 1px #3B0054'}}>
                                            Bonus Balance<br/>
                                            <b className="num-style text-success">KES {formatCurrency(parseFloat(bonus).toFixed(2))}</b>
                                        </div>
                                    </div>

                                    <WithdrawBonus earnings={earnings}/>

                                    <hr style={{borderColor: '#3B0054'}} className={"mb-1 mt-2"}/>

                                    <h6 className={'text-warning'}>Top <strong>Happy Hour</strong> Earners <Link className={'btn-sm btn-warning active'} style={{float: 'right', lineHeight: '10px', marginTop: '-2px'}} to={'/'}>Play Now!</Link></h6>
                                    <div style={{minHeight:'240px', maxHeight:'330px'}} className={"dan-scrollbar"}>
                                        <Table className={"mb-2"}>
                                            <thead>
                                            <tr>
                                                <th>
                                                    #
                                                </th>
                                                <th>
                                                    [REF]
                                                </th>
                                                <th>
                                                    Player
                                                </th>
                                                <th>
                                                    Earnings
                                                </th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {list}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </>
        );
    }
}

class DataList extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {dlist, index} = this.props;

        let username = dlist.name;
        let user_id = dlist.user_id;
        let total_earnings = formatAmount(dlist.total_earnings);

        return (
            <tr className={'q-crash2'}>
                <td>
                    {index}
                </td>
                <td>
                    {user_id}
                </td>
                <td>
                    <UserModal username={username} isWinner={false}/>
                </td>
                <td className={'num-style text-success text-bold'}>
                    <img src={'/assets/images/kes.png'} className={'mini-coin-2 hidden-sm'} alt='KES'/>
                    KES {total_earnings}
                </td>
            </tr>
        );
    }
}

export default HappyHour;