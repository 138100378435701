import { toast } from "react-toastify";

export function showSuccessToast(message, options = {}) {
  toast.success(message, {
    toastId: "success",
    ...options,
  });
}
export function showWarningToast(message, options = {}) {
  toast.warning(<p>{message}</p>, {
    toastId: "warning",
    ...options,
  });
}
export function showInfoToast(message, options = {}) {
  toast.info(<p>{message}</p>, {
    toastId: "info",
    ...options,
  });
}
export function showErrorToast(message, options = {}) {
  toast.error(<p>{message}</p>, {
    toastId: "error",
    ...options,
  });
}
export function showLoadingToast(message, options = {}) {
  toast.loading(<p>{message}</p>, options);
}
