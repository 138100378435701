import React from "react";
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Deposit from "./Deposit";
import DepositArchive from "./DepositArchive";
import WithdrawlArchive from "./WithdrawlArchive";
import Confirm from "./Confirm";
import Withdrawl from "./Withdrawl";
import History from "./History";
import TransactionHistory from "./TransactionHistory";
import ReferTab from "./Refer";

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box p={1}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
};

function a11yProps(index) {
    return {
        id: `full-width-tab-${index}`, 'aria-controls': `full-width-tabpanel-${index}`,
    };
}

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
}));

function Wallet(initState = 0) {
    const classes = useStyles();
    const theme = useTheme();
    const [value, setValue] = React.useState(initState.initState);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };

    return (
        <div className={classes.root}>
            <AppBar position="static" color="transparent" backgroun="transparent">
                <Tabs value={value} onChange={handleChange} indicatorColor="primary" variant="primary" aria-label="full width tabs">
                    <Tab label="Deposit" {...a11yProps(0)} />
                    {/* <Tab label="Verify" {...a11yProps(1)} /> */}
                    <Tab label="Withdraw" {...a11yProps(1)} />
                    <Tab label="Refer" {...a11yProps(2)} />
                    <Tab label="History" {...a11yProps(3)} />
                </Tabs>
            </AppBar>
            <SwipeableViews axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'} index={value} onChangeIndex={handleChangeIndex}>
                <TabPanel value={value} index={0} dir={theme.direction}>
                    <Deposit/>
                </TabPanel>
                {/* <TabPanel value={value} index={1} dir={theme.direction}>
                    <Confirm/>
                </TabPanel> */}
                <TabPanel value={value} index={1} dir={theme.direction}>
                    <Withdrawl/>
                </TabPanel>
                <TabPanel value={value} index={2} dir={theme.direction}>
                    <ReferTab/>
                </TabPanel>
                <TabPanel value={value} index={3} dir={theme.direction}>
                    <TransactionHistory/>
                </TabPanel>
            </SwipeableViews>
        </div>
    );
}

export default Wallet;