import React, {Component} from 'react'
import storage from "../../../../Storage";
import {NavLink} from "react-router-dom";
import RainIcon from '../../../../Static/images/rain-icon.svg'
import Rain from '../../../Pages/Parts/Chat/Rain';
import ReactModal from '@mui/material/Modal'
import PropTypes from "prop-types";
import {Row, Col, Dropdown} from "react-bootstrap";
import coins from "../../../coins";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import {__, wait, decode, encode, forceSatoshiFormat, isValidNumber, COIN_TOKEN} from "../../../../Helper";
import { showInfoToast, showWarningToast } from '../../../../toastNotifications';

class RainModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initState: 0,
            loader: true,
            loading: false,
            show: this.props.show ? this.props.show : false,
            disabled: false,
            coins: [],
            players: 2,
            amount: forceSatoshiFormat(100),
            coin: COIN_TOKEN,
            token: storage.getKey('token'),
            clientCountry: storage.getKey('country') ? storage.getKey('country'): "GLOBAL",
            currentCoin: COIN_TOKEN,
            total:0,
            open:false
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;

        var activeCoins = storage.getKey('active_coins');
        let ac = __.split(activeCoins, ",");

        coins.forEach((item, i) => {

            // Just Show Activated Coins
            let check = ac.some( c => parseFloat(c) === parseFloat(item.id));
            if(!check) return;

            if(item.preffix === "NC") return;

            let list = <Dropdown.Item key={__.toString(i)} as={'button'} className={"animated fadeIn"} onClick={ (e) => this.selectCoin(e, item.preffix) }>
                            <span><img src={"/assets/images/" + item.image} className={"img-fluid mini-coin mr-1"} alt="Coin" /> {item.preffix}</span>
                        </Dropdown.Item>;

            this.setState(state => ({ coins: [list, ...state.coins] }));
        });
        socket.on(C.RAIN, data => this.rain(decode(data)));
    }

    handleClose() {
        this.setState({show: false, open:false, effect: 'zoomOut'});
    }

    componentWillUnmount(){
        this._isMounted = false;
        this.setState({open:false})
    }

    selectCoin = (e, coin) => {
        e.preventDefault();
        this.setState({ currentCoin: coin })
    }

    handleShow() {
        if (storage.getKey('token') === null) {
             showWarningToast('Please Login to use this option.');
             return;
        }

        this.setState({show: true, open:true, effect: 'pulse'});
        wait(200).then(() => {
            this.setState({ loader: false });
        })
    }

    rain(data){
        if(this._isMounted){
            let { message } = data;
            this.setState({ loading: false, disabled: false });
            showInfoToast(message)
            socket.emit(C.CREDIT, encode({ token: this.state.token }));
        }
    }

    handleInputChange = (e) => {
        let target = e.target;
        let value = target.value;
        let name = target.name;

        if(name === 'amount'){
            let toNumber = __.toNumber(value);
            if(toNumber < 50e-8) {
                this.setState({ amount: '0.00000050' });
                return;
            }
            if(value >= 999999999) return;
            if(value.length > 10) return;
            if(!isValidNumber(value)) return;
        }

        if(name === 'players'){
            if(value > 4)
                return;
            if(value < 2)
                return;
        }

        this.setState({ [name]: value });
    };

    submit = e => {
        if(this._isMounted){
            e.preventDefault();
            this.setState({ loading: true, disabled: true });

            var c = this.state.clientCountry;

            wait(500).then(() => {
                socket.emit(C.RAIN, encode({
                    token: this.state.token,
                    amount: this.state.amount,
                    coin: this.state.currentCoin,
                    players: parseInt(this.state.players),
                    room: c
                }));
            })
        }
    };

    render() {
        let totalAmount = getTotal(this.state.amount,this.state.players)

        return (
            <>
                {!this.props.show &&
                    <NavLink to={'#'} className="nav-link bottom-nav-link chat-options" onClick={e => this.handleShow(e)}>
                        <div className={"row d-flex flex-column justify-content-center align-items-center bottom-menu gray-text"}>
                            <img src={RainIcon} style={{width: '18px'}} alt='rain'/>
                        </div>
                    </NavLink>
                }

                <ReactModal 
                    open={this.state.open}
                    onClose={this.handleClose}
                >
                    <div className="custom-modal-container">
                        <div className='font-14'>
                            <b>Rain</b>
                            <button type="button" className="close " onClick={this.handleClose}>
                                <i className={"fa fa-close font-16 font-300 text-orange"}/>
                            </button>
                        </div>
                        
                        <hr className='notif-hr hr-margin margin-10'/>
                        <p>Rain sends coins to randomly selected players in the chat.</p>
                        
                        { this.state.loader ?
                            <>
                                <div className="text-center">
                                    <div className="spinner-border text-orange" role="status" />
                                </div>
                            </>
                            :
                            <div className='stats-dark-card margin-10'>
                                
                                <form onSubmit={ e => this.submit(e)}>
                                    <Row>
                                        <Col sm={12} md={5} xl={5}>
                                            <label htmlFor="amount">Amount</label>
                                            <input 
                                                type="text" 
                                                name='amount' 
                                                className={'form-control deposit-input'} 
                                                onChange={this.handleInputChange} 
                                                value={this.state.amount}
                                            />
                                        </Col>
                                        <Col sm={12} md={3} xl={3}>
                                            <label htmlFor="players">Number of Players</label>
                                            <input required={true} 
                                                type="number" min={2} max={4} name={'players'} 
                                                className={'form-control deposit-input'} 
                                                onChange={this.handleInputChange} 
                                                value={this.state.players} 
                                            />
                                        </Col>
                                        <Col sm={12} md={3} xl={3}>
                                            <label htmlFor="total-amount">Total Amount</label>
                                            <input required={true} disabled
                                                type="number" name={'total-amount'} 
                                                className={'form-control deposit-input'} 
                                                value={totalAmount} 
                                            />
                                        </Col>
                                        <Col sm={12} md={3} xl={3}>
                                            <label htmlFor="comment">Add Comment(optional)</label>
                                            <input
                                                type="text" name='comment'
                                                className={'form-control deposit-input'} 
                                                placeholder="Hey everyone, raining on yall!" 
                                            />
                                        </Col>
                                        <Col sm={12} md={12} xl={12}>
                                            <button disabled={this.state.disabled} 
                                                type={'submit'} className={'btn btn-block orange-btn mt-2 no-shadow'}
                                            >
                                                { this.state.loading ?
                                                    <><div className="spinner-border spinner-border-sm mr-1" role="status"/></>
                                                    :
                                                    <i className={'mdi mdi-parachute mr-1'} />
                                                }
                                                Send Rain
                                            </button>
                                        </Col>
                                    </Row>
                                </form>
                            </div>
                        }
                    </div>                  
                </ReactModal>
            </>
        );
    }
}

function getTotal(amount,players){
    return amount * players
}

Rain.propTypes = {
    country: PropTypes.func
};

const mapStateToProps = state => ({
    country: state.items.country
});

export default RainModal;