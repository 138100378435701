import React from "react";
import {Row, Col} from "react-bootstrap";
import storage from "../../../../Storage";
import socket from "../../../../Socket";
import {decode, wait} from "../../../../Helper";
import ReactTooltip from "react-tooltip";
import C from "../../../../Constant";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../../toastNotifications";

class Parent extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            firstUserName: storage.getKey('name'),
            firstPhone: (storage.getKey('phone') !== 'false') ? storage.getKey('phone') : '',
            token: storage.getKey('token'),
            phone: (storage.getKey('phone') !== 'false') ? storage.getKey('phone') : '',
            username: storage.getKey('name'),
            password:'********',
            email:'',
            loadingPhone: false,
            loadingUsername: false,
            loadingPrivacy: false,
            loading2Fa: false,
            checkbox: false,
            checkbox2: false,
            phoneDisabled:true,
            usernameDisabled:true,
            passwordDisabled:true,
            emailDisabled:true,
        };
        this.togglePhone = this.togglePhone.bind(this);
        this.toggleUsername = this.toggleUsername.bind(this);
        this.togglePassword = this.togglePassword.bind(this);
        this.toggleEmail = this.toggleEmail.bind(this);
        
    }

    togglePhone() {
        this.setState( {phoneDisabled: !this.state.phoneDisabled} )
    }

    toggleUsername() {
        this.setState( {usernameDisabled: !this.state.usernameDisabled} )
    }

    togglePassword() {
        this.setState( {passwordDisabled: !this.state.passwordDisabled} )
    }

    toggleEmail() {
        this.setState( {emailDisabled: !this.state.emailDisabled} )
    }

    componentDidMount() {
        this._isMounted = true;
        socket.on(C.EDIT_ACCOUNT, data => this.updateProfile(decode(data)));
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    updateProfile(data) {
        if (this._isMounted) {
            this.setState({loadingUsername: false, loadingPhone: false});
            if (data.status) {
                storage.setKey('name', this.state.username);
                storage.setKey('phone', this.state.phone);
                showSuccessToast('Your Account Username was Updated!');
                window.location.reload();
            } else {
                 showErrorToast(data.error);
                 return;
            }
        }
    }

    save(input) {
        if (this._isMounted) {
            let {token, phone, username, firstUserName, firstPhone} = this.state;

            // if (input === 'username') {
            //     return sendNotfication('Username change disabled!', 'warning', 'top-center'); //temp    

            //     if (username === firstUserName) {
            //         return sendNotfication('Please Enter New Username!', 'info', 'top-center');
            //     }

            //     if (!username) {
            //         document.getElementById('username').classList.add('border-danger');
            //         return;
            //     }

            //     if (username.trim() === "") {
            //         document.getElementById('username').classList.add('border-danger');
            //         return;
            //     }

            //     this.setState({loadingUsername: true});

            //     wait(500).then(() => {
            //         socket.emit(C.EDIT_ACCOUNT, encode({
            //             token: token,
            //             username: username
            //         }));
            //     })
            // }
            // if (input === 'phone') {
            //     this.togglePhone()
            //     // return sendNotfication('Phone change disabled!', 'warning', 'top-center'); //temp 
            // }
        }
    }

    handleCheckBox = (e) => {
        this.setState({checkbox: true, loadingPrivacy: true});
        wait(1200).then(() => {
            this.setState({loadingPrivacy: false, checkbox: false});
             showWarningToast('This feature can\'t be active in your account!');
             return;
        })
    }

    render() {
        return (
            <>
                <ReactTooltip/>
                <Row >
                    <Col sm={6}>
                                                
                        {/* <label htmlFor="phone">Phone Number</label>
                        <div className="input-group">
                            <input 
                                disabled= {(this.state.phoneDisabled) ? "disabled" : ""}
                                type="phone"
                                id="phone"
                                autoComplete={'off'}
                                className="form-control input-box settings-input"
                                placeholder="Change Phone"
                                value={this.state.phone}
                                required={true}
                                onChange={e => this.setState({phone: e.target.value})}
                            />
                            <div className="input-group-append">
                                <button style={{width: '100%'}} onClick={this.togglePhone.bind(this)} type="button"
                                        className="btn settings-btn orange-bg  no-shadow btn-clipboard settings-input" >
                                        {this.state.loadingPhone ?
                                            <div className="spinner-border spinner-border-sm" role="status"/>
                                            : 
                                            <>
                                            {(this.state.phoneDisabled) ? 
                                                <>
                                                    <span>Edit &nbsp;</span><i className="fa fa-pencil-square-o"/>
                                                </> 
                                                :
                                                <>
                                                    <span>Save &nbsp;</span><i className="fa fa-save"/>
                                                </>
                                            }
                                            </>
                                        }
                                </button>
                            </div>
                        </div> */}                        

                        <label className="top-10" htmlFor="password">Password</label>
                        <div className="input-group">
                            <input 
                                disabled= {(this.state.passwordDisabled) ? "disabled" : ""}
                                type="password"
                                id="password"
                                autoComplete={'off'}
                                className="form-control input-box settings-input"
                                placeholder="************"
                                defaultValue={this.state.password}
                                required={true}                                
                            />
                            <div className="input-group-append">
                                <button style={{width: '100%'}} onClick={this.togglePassword.bind(this)} type="button"
                                        className="btn settings-btn orange-bg no-shadow btn-clipboard settings-input" >
                                        <>
                                            {(this.state.passwordDisabled) ? 
                                                <>
                                                    <span>Edit &nbsp;</span><i className="fa fa-pencil-square-o"/>
                                                </> 
                                                :
                                                <>
                                                    <span>Save &nbsp;</span><i className="fa fa-save"/>
                                                </>
                                            }
                                        </>
                                </button>
                            </div>
                        </div>
                        
                        <label className="top-10" htmlFor="email">Email Address</label>
                        <div className="input-group">
                            <input disabled= {(this.state.emailDisabled) ? "disabled" : ""}
                                type="email"
                                id="email"
                                autoComplete={'off'}
                                className="form-control input-box settings-input"
                                placeholder=""
                                defaultValue={this.state.email}
                                required={true}
                            />
                            <div className="input-group-append">
                                <button style={{width: '100%'}} onClick={this.toggleEmail.bind(this)} type="button"
                                        className="btn settings-btn orange-bg no-shadow btn-clipboard settings-input" >
                                        <>
                                            {(this.state.emailDisabled) ? 
                                                <>
                                                    <span className="text-orange">Add &nbsp;</span><i className="fa fa-plus-circle text-orange"/>
                                                </> 
                                                :
                                                <>
                                                    <span>Save &nbsp;</span><i className="fa fa-save"/>
                                                </>
                                            }
                                        </>
                                </button>
                            </div>
                        </div>
                    </Col>
                    
                </Row>
            </>
        );
    }
}

export default Parent;