import React, { Component } from "react";
import storage from "../../../../Storage";
import md5 from "md5";
import socket from "../../../../Socket";
import UserModal from "../../../Components/User/Stat/Modal";
import C from "../../../../Constant";
import {
  __,
  fixDate,
  Event,
  decode,
  encode,
  formatAmount,
  timeConvertor,
} from "../../../../Helper";
import Lottie from "lottie-react";
import notFound from "../../../../Static/Lottie/not-found.json";
import { StatsClick } from "../ClickService";
import { showWarningToast } from "../../../../toastNotifications";

class MyBetsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      initState: 0,
      show: this.props.show ? this.props.show : false,
      loading: true,
      bets: [],
      game: "crash",
      token: storage.getKey("token"),
      name: storage.getKey("name"),
      gameCode: md5("crash"),
      myAllTimeProfit: 0,
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  componentDidMount() {
    socket.emit(
      C.MY_BETS,
      encode({ token: this.state.token, game: this.state.game })
    );
    socket.on(C.MY_BETS, (data) => this.makeList(decode(data)));
    socket.on(C.ADD_BET, (data) => this.addList(decode(data)));
  }

  addList(bet) {
    let row = <OwnBets forMy={true} bet={bet} />;
    this.setState((state) => ({ bets: [row, ...state.bets] }));
  }

  makeList(arr) {
    if (typeof arr.history === "undefined") return;
    this.setState({ loading: false });
    let profit = 0;
    const rows = [];
    arr.history.forEach((bet, i) => {
      let MyBet = { name: this.state.name, ...bet };
      let row = <OwnBets key={i} forMy={true} bet={MyBet} />;
      rows.push(row);
      profit += parseInt(bet.profit);
    });
    this.setState({ bets: rows });

    this.setState({
      myAllTimeProfit: profit,
    });
  }

  handleClose() {
    this.setState({ show: false, effect: "zoomOut" });
  }

  handleShow() {
    if (storage.getKey("token") === null) {
      showWarningToast("Please Login to use this option.");
      return;
    }

    this.setState({ show: true, effect: "pulse" });
  }

  closeModal() {
    StatsClick();
  }

  render() {
    return (
      <>
        {this.state.loading ? (
          <>
            <div className="text-center">
              <div className="spinner-grow text-white my-3" role="status" />
            </div>
          </>
        ) : (
          <div>
            <div className="row margin-10 stats-dark-card remove-margin">
              <div className="col-6" style={{ padding: "0 9px 0 0px" }}>
                <div>
                  <p className="font-11 margin-0  text-white">
                    My all time profit
                  </p>
                  <p className="font-15 text-white margin-0">
                    KES :{" "}
                    <span className="text-green font-15">
                      {formatAmount(this.state.myAllTimeProfit)}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-6" style={{ padding: "0 9px 0 0px" }}>
                <div>
                  <p className="font-11 margin-0  text-white">
                    Total Bets Made
                  </p>
                  <p className="font-15 text-white margin-0">
                    <span className="text-green font-15">
                      {this.state.bets.length}
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <div className="stats-dark-card margin-10">
              <div className="darker-card">
                <div className="row">
                  <p className="text-orange col-8 margin-0">
                    Gain more profit!
                  </p>
                  <div className="col-4">
                    <button
                      className="orange-btn play-btn"
                      onClick={this.closeModal}
                    >
                      Play Now
                    </button>
                  </div>
                </div>
              </div>

              <div
                className="table-responsive mt-2"
                style={{ height: "25vh", overflowY: "scroll" }}
              >
                <table className="table table-hover fix-table table-stats deposit">
                  <thead className="sticky-top">
                    <tr className="table-head">
                      <th className="table-head" scope="col">
                        Amount
                      </th>
                      <th className="table-head" scope="col">
                        Game
                      </th>
                      <th className="table-head" scope="col">
                        Crash
                      </th>
                      <th className="table-head" scope="col">
                        Cashout
                      </th>
                      <th className="table-head" scope="col">
                        Profit
                      </th>
                      <th className="table-head" scope="col">
                        Date/Time
                      </th>
                    </tr>
                  </thead>

                  <tbody>{this.state.bets}</tbody>
                </table>
              </div>

              {this.state.bets.length === 0 && (
                <>
                  <div
                    className="mt-2 w-100 font-12 text-center text-upper text-white"
                    style={{ textTransform: "none" }}
                  >
                    <Lottie
                      animationData={notFound}
                      loop={true}
                      style={{ height: "130px", marginTop: "-1rem" }}
                    />
                    You haven't played yet
                  </div>
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

class OwnBets extends React.Component {
  gameDetail = () => {
    Event.emit("single_game_modal", { data: this.props.bet });
  };

  render() {
    let {
      name,
      amount,
      cashout,
      result,
      profit,
      coin,
      created,
      gid,
      direct,
      forMy,
      type,
      option,
    } = this.props.bet;
    let isWinner = false;
    let date = fixDate(created);
    type = type === "s" ? "Colours-" + option : "Classic";

    if (direct) {
      date = timeConvertor(created);
    }

    if (__.toNumber(profit) !== 0.0) {
      isWinner = true;
    }

    if (cashout === "" || cashout == null) {
      cashout = "Busted";
    } else {
      cashout = "@" + cashout;
    }

    return (
      <tr>
        {/* <td onClick={this.gameDetail}>
                    {gid}
                </td> */}
        {forMy === false && (
          <td>
            <UserModal username={name} isWinner={isWinner} />
          </td>
        )}
        <td
          onClick={this.gameDetail}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          KES {formatAmount(amount, 0)}
        </td>
        <td
          onClick={this.gameDetail}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          {type}
        </td>
        <td
          onClick={this.gameDetail}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          {result}x
        </td>
        <td
          onClick={this.gameDetail}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          <small>{cashout}</small>
        </td>
        <td
          onClick={this.gameDetail}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          KES {formatAmount(profit, 0)}
        </td>
        <td
          onClick={this.gameDetail}
          className={
            isWinner === true ? "text-success-2 num-style" : "num-style"
          }
        >
          {date}
        </td>
      </tr>
    );
  }
}

function sortByAmount(input) {
  function r(c) {
    return c.amount ? -c.amount : null;
  }

  return __.sortBy(input, r);
}

function sortByCashout(input) {
  function r(c) {
    return c.current ? -c.current : null;
  }

  return __.sortBy(input, r);
}

export default MyBetsTab;
