import React from 'react'
import {Helmet} from 'react-helmet'
import {Table} from "react-bootstrap";
import socket from "../../Socket";
import {formatAmount} from "../../Helper";
import C from "../../Constant";
import UserModal from "../Components/User/Stat/Modal";
import {Link} from "react-router-dom";

class ReferralEarners extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            players: []
        }
    }

    componentDidMount() {
        socket.emit(C.TOP_REFERRERS);
        socket.on(C.TOP_REFERRERS, data => this.makeList(data));
    }

    makeList = (data) => {
        //data = data.filter((item, pos, self) => self.findIndex(v => v.name === item.name) === pos);
        this.setState({loading: false, players: (data)})
    };

    render() {
        const list = this.state.players.map((player, i) =>
            <Players key={i} index={i + 1} player={player}/>
        );

        return (
            <>
                <Helmet>
                    <title>Top Referrers</title>
                </Helmet>
                <div>
                    {
                        this.state.loading ?
                            <div className="text-center">
                                <div className="spinner-grow text-white my-3" role="status"/>
                            </div>
                            :
                            <>
                                <div className={"text-left p-1"} style={{borderRadius: '5px', marginTop: '7px', backgroundColor: '#520474', border: '1px solid #3B0054'}}>
                                    <div className={'row'}>
                                        <div className={'col-9'}>
                                            <h5 className={'text-warning font-weight-bold'}>Refer & Win 100k</h5>
                                            <p className={'text-success-2 font-10 mb-0'}>&#10004; You earn <strong className={'text-warning font-12'}>2%</strong> from every deposit your referred members make.*</p>
                                            <p className={'text-success-2 font-10 mb-0'}>&#10004; You stand a chance of winning <strong className={'text-warning font-12'}>KES 100,000</strong> every Friday afternoon</p>
                                        </div>
                                        <div className={'col-3 text-center'}>
                                            <img className={'mt-2'} style={{width: '70px'}} src={'/assets/images/prize.png'} alt='CUP'/>
                                        </div>
                                    </div>

                                    <hr/>

                                    <h6 className={'text-warning'}>This week's Top Referrers <Link className={'btn-sm btn-warning active'} style={{float: 'right', lineHeight:'10px', marginTop:'-2px'}} to={'/affiliate'}>Refer Now!</Link></h6>
                                    <Table className={"mb-2"}>
                                        <thead>
                                        <tr>
                                            <th>
                                                #
                                            </th>
                                            <th>
                                                [REF]
                                            </th>
                                            <th>
                                                Player
                                            </th>
                                            <th>
                                                Week Earnings
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {list}
                                        </tbody>
                                    </Table>
                                </div>
                            </>
                    }
                </div>
            </>
        );
    }
}

class Players extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        let {player, index} = this.props;

        let username = player.name;
        let user_id = player.user_id;
        let total_earnings = formatAmount(player.total_earnings);

        return (
            <tr className={'q-crash2'}>
                <td>
                    {index}
                </td>
                <td>
                    {user_id}
                </td>
                <td>
                    <UserModal username={username} isWinner={false}/>
                </td>
                <td className={'num-style text-success text-bold'}>
                    <img src={'/assets/images/kes.png'} className={'mini-coin-2 hidden-sm'} alt='KES'/>
                    KES {total_earnings}
                </td>
            </tr>
        );
    }
}

export default ReferralEarners;