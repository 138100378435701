import React, {Component} from 'react'
import storage from "../../../Storage";
import ReactModal from '@mui/material/Modal'
import {__, wait,forceSatoshiFormat, COIN_TOKEN} from "../../../Helper";
import History from './History'
import HistoryIcon from '../../../Static/images/history.svg'

class HistoryModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            initState: 0,
            loader: true,
            loading: false,
            show: this.props.show ? this.props.show : false,
            disabled: false,
            coins: [],
            players: 2,
            amount: forceSatoshiFormat(100),
            coin: COIN_TOKEN,
            token: storage.getKey('token'),
            clientCountry: storage.getKey('country') ? storage.getKey('country'): "GLOBAL",
            currentCoin: COIN_TOKEN,
            total:0
        };
        this.handleShow = this.handleShow.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        
    }

    handleClose() {
        this.setState({show: false, effect: 'zoomOut'});
    }

    componentWillUnmount(){
        this._isMounted = false;
    }

    handleShow() {
       

        this.setState({show: true, effect: 'pulse'});

        wait(200).then(() => {
            this.setState({ loader: false });
        })
    }

    

    render() {

        return (
            <>
                {!this.props.show &&
                    <div className="text-main" onClick={e => this.handleShow(e)}>
                        <img src={HistoryIcon} alt='history icon'/>
                    </div>                    
                }

                <ReactModal 
                    open={this.state.show}
                    onClose={this.handleClose}
                >
                    <div className="custom-modal-container">
                        <div className='font-14'>
                            <b>Crash History</b>
                            <button type="button" className="close " onClick={this.handleClose}>
                                <i className={"fa fa-close font-16 font-300 text-orange"}/>
                            </button>
                        </div>
                        
                        <hr className='notif-hr hr-margin margin-10'/>
                        
                        { this.state.loader ?
                            <>
                                <div className="text-center">
                                    <div className="spinner-border text-orange" role="status" />
                                </div>
                            </>
                            :
                            <History/>
                        }
                    </div>                  
                </ReactModal>
            </>
        );
    }
}

export default HistoryModal;