import React from "react";
import ReactDOM from "react-dom/client";
import {BrowserRouter as Router} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { Provider } from 'react-redux';
import store from './store';

// Styles
import "./Static/css/bootstrap.min.css";
import "./Static/css/animate.css";
import "./Static/css/icons.css";
import 'react-notifications-component/dist/theme.css';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import "./Static/css/metisMenu.min.css";
import "./Static/css/extra.css";
import "./Static/css/app.css";
import "./Static/css/uiComponents.css"
import "./Static/css/toast.css"
import { injectStyle } from "react-toastify/dist/inject-style";

import './i18n';

// CALL IT ONCE IN YOUR APP
injectStyle();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <Router>
            <App />
        </Router>
    </Provider>
);


serviceWorker.unregister();