import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import storage from "../../../../Storage";
import {
  Event,
  encode,
  decode,
  formatAmount,
  forceSatoshiFormat,
  __,
} from "../../../../Helper";
import Wallet from "./Wallet";
import {
  ChatClick,
  NotifClicker,
  SettingsClick,
  StatsClick,
  UpdateHomeIcon,
  UpdateHomeIconActive,
  VipClick,
} from "../ClickService";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import { showWarningToast } from "../../../../toastNotifications";
import Engine from "../../../Games/Crash/Engine";
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      initState: 0,
      credit: storage.getKey("credit")
        ? storage.getKey("credit")
        : {
            bonus: 0.0,
            demo: 0.0,
            kes: 0.0,
          },
    };
    this.handleShow = this.handleShow.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }
  updateBonuses(bonus, bet_bonus, bonus_end_time) {
    Engine.trigger.emit("update_bonus", bonus);

    //banner
    let unix_now = Math.floor(new Date().getTime() / 1000);
    let unix_bonus_end =
      bonus_end_time === 0
        ? unix_now + 3600
        : Math.floor(new Date(bonus_end_time).getTime() / 1000);

    if (unix_now <= unix_bonus_end) {
      //bonus running
      let remaining = Math.floor((unix_bonus_end - unix_now) / 60);
      Engine.trigger.emit(
        "HAPPY_HOUR_BANNER",
        remaining +
          " Minutes of Happy Hour! Earned: " +
          bonus +
          " | Bet Bonus: " +
          bet_bonus
      );
    } else {
      Engine.trigger.emit("HAPPY_HOUR_BANNER", "");
    }
  }
  getCredit(data) {
    let { credit } = data;
    this.setState({ credit: credit });
    storage.setKey("credit", credit);
    //Set for Redux
  }
  updateCredit(data) {
    let credit = data.value;
    let bonus = data.bonus;
    let bet_bonus = data.bet_bonus;
    let bonus_end_time = data.bonus_end_time;
    this.updateBonuses(bonus, bet_bonus, bonus_end_time);
    this.setState({ credit: forceSatoshiFormat(credit) });
    storage.setKey("credit", credit);
  }

  componentDidMount() {
    socket.emit(C.CREDIT, encode({ token: this.state.token }));
    socket.on(C.CREDIT, (data) => this.getCredit(decode(data)));
    socket.on(C.UPDATE_CREDIT, (data) => this.updateCredit(decode(data)));

    Event.on("other_coins", () => {
      this.setState({ show: false });
    });
    Event.on("update_payment", () => {
      this.setState({ show: false });
    });
    Event.on("show_deposit", () => {
      this.setState({ initState: 0 });
      this.handleShow();
    });
    Event.on("show_verify_mpesa", () => {
      this.setState({ initState: 1 });
      this.handleShow();
    });
  }

  handleClose() {
    this.setState({ show: false, effect: "zoomOut" });
    UpdateHomeIconActive();
  }

  handleShow() {
    if (storage.getKey("token") === null) {
      showWarningToast("Please Login to use this option.");
      return;
    }

    this.setState({ show: true, effect: "pulse" });
    NotifClicker();
    ChatClick();
    StatsClick();
    SettingsClick();
    VipClick();
    UpdateHomeIcon();
  }

  render() {
    return (
      <>
        <Modal
          size="md"
          backdrop={"static"}
          centered={true}
          scrollable={false}
          show={this.state.show}
          onHide={this.handleClose}
          onExited={this.handleClose}
          aria-labelledby="wallet-md-modal"
          className={"animated " + this.state.effect}
        >
          <Modal.Header className="modal-heading">
            <i
              className={"fa fa-wallet bottom-menu-icon"}
              style={{ fontSize: "17px" }}
            />
            &nbsp;&nbsp;Wallet
            <button
              type="button"
              id="close-wallet"
              className="close p-2"
              onClick={this.handleClose}
            >
              {/* <img className='width-20' src={CancelIcon} alt="cancel icon"/> */}
              <i className="fa fa-close text-orange font-18 font-300" />
            </button>
          </Modal.Header>
          <div
            className="row remove-margin text-left margin-30"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="col-6 padding-0">
              <div className="wallet-card" style={{ marginRight: "15px" }}>
                <p className="font-12">Amount in wallet</p>
                <p className="font-14 text-white">
                  KES :{" "}
                  <span className="text-orange font-14">
                    {formatAmount(this.state.credit.kes)}
                  </span>
                </p>
              </div>
            </div>

            <div className="col-6 padding-0">
              <div className="wallet-card">
                <p className="font-12">Withdrawable Amount</p>
                <p className="font-13 text-white">
                  KES :{" "}
                  <span className="text-green font-13">
                    {formatAmount(this.state.credit.kes)}
                  </span>
                </p>
              </div>
            </div>
          </div>

          <Modal.Body className={"wallet-modal"}>
            <Wallet initState={this.state.initState} />
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default Main;
