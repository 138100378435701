import React, {Component} from "react";
import {Button} from "react-bootstrap";
import socket from "../../../../Socket";
import {__, encode} from "../../../../Helper";
import storage from "../../../../Storage";
import C from "../../../../Constant";
import { SITE } from "../../../../Helper";

//_isMounted can prevent from double socket response

class ReferTab extends Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token') ? storage.getKey('token') : null,
            user_id: storage.getKey('user_id') ? storage.getKey('user_id') : null,
            logged: false,
            earnings: 0,
            referral_count: 0,
            copySuccess: ''
        };
    }

    componentDidMount() {
        if (this.state.token !== null) {
            this.setState({logged: true});
            socket.emit(C.MY_REFERRAL,  encode({ token: this.state.token }));
        }
        socket.on(C.MY_REFERRAL, data => this.setReferralDetails(data));
    }

    setReferralDetails = (data) => {
        console.log(data)
        this.setState({earnings: data.earnings, referral_count: data.referral_count});
    }


    copyToClipboard = (e) => {
        navigator.clipboard.writeText(SITE + '/aff/' + this.state.user_id).then(r => {
            this.setState({copySuccess: 'Link copied to clipboard!'});
        });
    };

    render() {
        let {logged, earnings, referral_count, loader} = this.state;

        return (
            <>
                <div className={this.state.slide ? 'animated slideOutLeft mt-1' : ' mt-1'}>                            
                    <div className={"m-auto wallet"}>
                        <form className="w-100" onSubmit={(e) => {
                            this.submitForm(e)
                        }}>
                            {loader ?
                                <>
                                </>
                                :
                                <>
                                    <label htmlFor="targetAmount" className="text-white">Refer & Earn</label>
                                    <div className="input-group">
                                        <div type="text" className="withdraw-input form-control">
                                            {SITE + '/aff/' + this.state.user_id}
                                        </div>

                                        <div onClick={this.copyToClipboard}  className="input-group-append">
                                            <Button style={{width: '100%',borderRadius:"0px 5px 5px 0px"}} variant="btn no-shadow btn-sm btn-clipboard refer-copy-btn">
                                                <i className="fa fa-copy text-orange"/>
                                            </Button>
                                        </div>                                        
                                    </div>
                                    <div>
                                        <span className={"text-white top-10 font-12"}>{this.state.copySuccess}</span>
                                    </div>
                                    <div className="form-group mt-2 mb-0 row remove-margin">
                                        <div className='col-6' style={{padding: '0 9px 0 0px'}}>
                                            <div className='refer-card-bg margin-10'>
                                                <div>
                                                    <p className='font-12 margin-0 modal-text-head'>My Referalls</p>
                                                    <p className='font-14 text-white margin-0'> <span className='text-orange font-14'>{this.state.referral_count}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-6' style={{padding: '0 9px 0 0px'}}>
                                            <div className='refer-card-bg margin-10'>
                                                <div>
                                                    <p className='font-11 margin-0 modal-text-head'>Earned Amount</p>
                                                    <p className='font-14 text-white margin-0'>KES : <span className='text-green font-14'>{this.state.earnings}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </form>
                    </div>
                </div>
            </>
        );
    }
}

export default ReferTab;