import React from "react";
import {Card, Col, Dropdown, Row} from "react-bootstrap";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {setChartCoin} from "../../../../actions/gameCoin";
import {__, encode, forceSatoshiFormat, wait, COIN_TOKEN} from "../../../../Helper";
import AddToFriend from "../Friend";
import Chart from "../Chart";
import coins from "../../../coins";
import socket from "../../../../Socket";
import C from "../../../../Constant";
import ReactTooltip from "react-tooltip";
import Avatar from 'react-avatar';
import {ReactComponent as Level1Inactive} from '../../../../Static/images/badges/medal_18.svg'
import {ReactComponent as Level2Inactive} from '../../../../Static/images/badges/trophy.svg'
import {ReactComponent as Level3Inactive} from '../../../../Static/images/badges/trophy_4.svg'
import {ReactComponent as Level4Inactive} from '../../../../Static/images/badges/trophy_7.svg'
import Level2Active from '../../../../Static/images/badges/colored/trophy.svg'
import Level1Active from '../../../../Static/images/badges/colored/medal_18.svg'
import Level3Active from '../../../../Static/images/badges/colored/trophy_4.svg'
import Level4Active from '../../../../Static/images/badges/colored/trophy_7.svg'
import Level5Active from '../../../../Static/images/badges/colored/podium.svg'


class Details extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: true,
            showChart: false,
            openedChart: false,
            changeCoinLoader: false,
            medalView: false,
            coins: [],
            currentCoin: COIN_TOKEN,
        };
    }

    componentWillMount() {
        coins.forEach((item, key) => {
            let coin = <Dropdown.Item key={key} onClick={e => this.selectCoin(item.preffix)}
                                      className={'animated slideInUp'}>
                <span key={key} className={'dropdown-item'}>
                    <img src={'/assets/images/' + item.image} alt="coin" className={'mini-coin-8'}/>
                    {item.preffix}
                </span>
            </Dropdown.Item>;
            this.setState(state => ({coins: [coin, ...state.coins]}));
        });
    }

    componentWillReceiveProps(nextProps, nextContext) {
        wait(100).then(() => {
            if (nextProps.haveData === 'no') {
                this.setState({loader: true})
            } else {
                var props = nextProps;
                var dd = props.data;
                let chart = <Chart token={props.token} name={props.name}/>;
                let since = sinceConvert(dd.created);
                let color = dd.profit < 0 ? 'text-danger' : 'text-success';
                let coin = dd.coin;
                let level = dd.level;
                let name = props.name;
                let highProfit = dd.high_profit;
                let lowProfit = dd.low_profit;

                this.setState({
                    changeCoinLoader: false,
                    since: since, lowProfit: lowProfit, highProfit: highProfit,
                    name: name, coin: coin, currentCoin: coin, level: level,
                    color: color, chart: chart, avatar: dd.avatar, profit: dd.profit,
                    date: dd.created, played: dd.played, wined: dd.wined, medal: dd.medal, _token: props.token
                })

                wait(200).then(() => {
                    if (!__.isEmpty(name) || !__.isUndefined(name))
                        this.setState({loader: false})
                })
            }
        })
    }

    componentDidMount() {
        if (this.props.haveData === 'no') {
            this.setState({loader: true})
        } else {
            var props = this.props;
            var dd = props.data;
            let chart = <Chart token={props.token} name={props.name}/>;
            let since = sinceConvert(dd.created);
            let color = dd.profit < 0 ? 'text-danger' : 'text-success';
            let coin = dd.coin;
            let level = dd.level;
            let name = props.name;
            let highProfit = dd.high_profit;
            let lowProfit = dd.low_profit;

            this.setState({
                since: since,
                lowProfit: lowProfit,
                highProfit: highProfit,
                currentCoin: coin,
                name: name,
                coin: coin,
                level: level,
                color: color,
                chart: chart,
                avatar: dd.avatar,
                profit: dd.profit,
                date: dd.created,
                played: dd.played,
                wined: dd.wined,
                medal: dd.medal,
                _token: props.token
            })

            wait(200).then(() => {
                if (!__.isEmpty(name) || !__.isUndefined(name))
                    this.setState({loader: false})
            })
        }
    }

    componentWillUnmount() {
        this.props.setChartCoin(this.state.currentCoin);
    }

    selectCoin(coin) {
        //Set For Redux
        this.props.setChartCoin(coin);
        this.setState({currentCoin: coin, changeCoinLoader: true});
        socket.emit(C.USER_INFO, encode({name: this.props.name, coin: coin, page: 1}));
    }

    getChart(game) {
        wait(100).then(() => {
            if (document.querySelector("#userChart") !== null)
                document.querySelector("#userChart").innerText = "[ Please Wait... ]";
        })

        socket.emit(C.USER_CHART, encode({name: this.props.name, coin: this.state.currentCoin, game: game}));
        this.setState({openedChart: true});
    }

    back = e => {
        this.setState({openedChart: false});
    }

    showChart = e => {
        this.setState({showChart: !this.state.showChart});
        this.getChart('crash');
    }

    toggleMedalView = () => {
        this.setState({medalView: !this.state.medalView});
    }

    render() {
        let {
            currentCoin,
            lowProfit,
            highProfit,
            name,
            coin,
            color,
            chart,
            since,
            avatar,
            profit,
            date,
            _token,
            level,
            played,
            wined,
            medal
        } = this.state;

        if (!__.isUndefined(date))
            date = date.substr(0, 10);

        // if(!__.isUndefined(medal))
        //     return; //For security old version

        const makeStars = (num) => {
            let stars = 5;
            let userLevel = stars - parseFloat(num);
            var g = [], o = [], f = [];
            for (var i = 0; i < userLevel; i++) {
                g.push(<i className={'mdi mdi-star font-15 text-gray'}/>);
            }
            for (var i = 0; i < num; i++) {
                o.push(<i className={'mdi mdi-star font-15 text-orange'}/>);
            }
            f.push(o, g);
            return f;
        }

        played = played ? played : null;
        wined = wined ? wined : 0;

        medal = parseInt(medal);

        return (
            <>
                {this.state.loader ?
                    <>
                        <div className="text-center" style={{minHeight: 500}}>
                            <div className="spinner-border text-orange my-5 user-loader" role="status"/>
                        </div>
                    </>
                    :
                    <>
                        {
                            this.state.medalView ?
                                <div className={"animated fadeIn medals-view"}>

                                    <div className="media mb-2 mcard">
                                        <img src={Level1Active} alt='level1' height="25" className="mr-3"/>
                                        <div className="media-body align-self-center font-11 text-gray">
                                            
                                            Required Minimum 10,000 KES Deposit
                                        </div>
                                    </div>

                                    <div className="media mb-2 mcard">
                                        <img src={Level2Active} alt='level 2' height="60" className="mr-3"/>
                                        <div className="media-body align-self-center font-11 text-gray">
                                            
                                            Required Minimum 50,000 KES Deposit
                                        </div>
                                    </div>

                                    <div className="media mb-2 mcard">
                                        <img src={Level3Active} alt="level 3" height="45" className="mr-3"/>
                                        <div className="media-body align-self-center font-11 text-gray">
                                            
                                            Required Minimum 200,000 KES Deposit
                                        </div>
                                    </div>

                                    <div className="media mb-2 mcard">
                                        <img src={Level4Active} alt='level 4' height="60" className="mr-3"/>
                                        <div className="media-body align-self-center font-11 text-gray">
                                            
                                            Required 10 Times Rain
                                        </div>
                                    </div>

                                    <div className="media my-2 mcard">
                                        <img src={Level5Active} alt='level 5' height="60" className="mr-3"/>
                                        <div className="media-body align-self-center font-11 text-gray">
                                            
                                            Required 250,000 KES Profit
                                        </div>
                                    </div>

                                    <div className="text-center">
                                        <button onClick={this.toggleMedalView}
                                                className={'btn btn-sm btn-cs-6 text-white my-1'}>
                                            Back
                                        </button>
                                    </div>

                                </div>
                                :
                                <div className={"animated fadeIn "} >
                                    <div className="user-detail text-center mb-1" style={{display:'flex',justifyContent:"space-between"}}>
                                        <Avatar 
                                            name={name} 
                                            size='40' 
                                            textSizeRatio='2'
                                            round 
                                            style={{marginRight:"10px"}}
                                        />

                                        <div>
                                            <p className={'mb-0 text-white uname font-12 text-left'}>
                                                {name}
                                            </p>
                                            <div>
                                                {makeStars(level)}
                                            </div>
                                        </div>
                                        

                                        <div className={'button-items text-center mt-1'}>
                                            <AddToFriend onModal={true} name={name} id={_token}/>
                                        </div>
                                    </div>

                                    <Row className="top-10">
                                        <Col sm="12" md="12">
                                            <Card className="mt-2 mb-1 margin-10">
                                                <Card.Body onClick={this.toggleMedalView} className="p-1 medals text-center cpt row space-between">
                                                    <p className="mb-3  header-title font-12 text-white margin-0">
                                                        Medals
                                                    </p>
                                                    <div className="img-group text-center">
                                                        <ReactTooltip/>
                                                        <img style={{fill:"red"}} data-tip="BGame User" className="img-fluid mx-1 active"
                                                             src={Level1Active} alt="first medal"/>
                                                        
                                                        {medal === 1 ?
                                                            <img data-tip={"Deposited Below 10,000 KES"}
                                                                 className={"img-fluid mx-1 active"}
                                                                 src={Level1Active} alt="first medal"/>
                                                            :
                                                            <Level1Inactive fill="gray" data-tip="BGame User" className="img-fluid mx-1 width-25"/>
                                                        }

                                                        {medal === 2 ?
                                                            <img data-tip={"Deposited Below 50,000 KES"}
                                                                 className={"img-fluid mx-1 active"}
                                                                 src={Level2Active} alt="second medal"/>
                                                            :
                                                            <Level2Inactive fill="gray" data-tip="BGame User" className="img-fluid mx-1 width-25"/>
                                                        }
                                                        {medal === 3 ?
                                                            <img data-tip={"Deposited Below 200,000 KES"}
                                                                 className={"img-fluid mx-1 active"}
                                                                 src={Level3Active} alt="third medal"/>
                                                            :
                                                            <Level3Inactive fill="gray" data-tip="BGame User" className="img-fluid mx-1 width-25"/>
                                                        }
                                                                <Level4Inactive fill="gray" data-tip="BGame User" className="img-fluid mx-1 width-25"/>
                                                                {/* <Level5Inactive fill="gray" data-tip="BGame User" className="img-fluid mx-1 width-25" /> */}
                                                    </div>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>

                                    <div className="details-parent top-10">

                                        <Row>
                                            <Col lg="6" md="6" className="col-6">
                                                <Card className={'mb-1 brd15'}>
                                                    <Card.Body className="p-1 text-left font-10 text-gray">
                                                        Joined <br/>
                                                        <span className="font-11 top-10 text-white">{since}</span>
                                                    </Card.Body>
                                                </Card>
                                            </Col>

                                            <Col lg="6" md="6" className="col-6">
                                                <Card className={'mb-1 brd15'}>
                                                    <Card.Body className="p-1 text-left font-10 text-gray">
                                                        All Time Profit<br/>
                                                        {this.state.changeCoinLoader ?
                                                            <div className="text-center">
                                                                <div className="spinner-border spinner-border-sm"
                                                                     role="status"/>
                                                            </div>
                                                            :
                                                            <>
                                                                {currentCoin}<span className={'num-style text-green font-11'}> {forceSatoshiFormat(highProfit)} </span></>
                                                        }
                                                    </Card.Body>
                                                </Card>
                                            </Col>
                                        </Row>

                                        <Row className="top-10">
                                            <Col lg="6" md="6" className="col-6">
                                                {
                                                    played !== null &&
                                                    <>
                                                        <Card className={'mb-1 brd15'}>
                                                            <Card.Body className="p-1 text-left font-10 text-gray">
                                                                Total Wins<br/>
                                                                <span className="font-12 text-green">{wined}</span>
                                                            </Card.Body>
                                                        </Card>
                                                    </>
                                                }
                                            </Col>
                                            <Col lg="6" md="6" className="col-6">
                                                {
                                                    played !== null &&
                                                    <>
                                                        <Card className={'mb-1 brd15 text-left'}>
                                                            <Card.Body className="p-1 text-left font-10 text-gray">
                                                                Total Bets<br/>
                                                                <span className="font-11 text-green">{played}</span>
                                                            </Card.Body>
                                                        </Card>
                                                    </>
                                                }
                                            </Col>
                                        </Row>

                                    </div>

                                    
                                </div>
                        }
                    </>
                }
            </>
        );
    }
}

function sinceConvert(time, lang) {
    if (time === undefined) return;
    lang = lang || {
        postfixes: {
            '<': ' ago',
            '>': ' from now'
        },
        1000: {
            singular: 'a few moments',
            plural: 'a few moments'
        },
        60000: {
            singular: 'about a minute',
            plural: '# minutes'
        },
        3600000: {
            singular: 'about an hour',
            plural: '# hours'
        },
        86400000: {
            singular: 'a day',
            plural: '# days'
        },
        31540000000: {
            singular: 'a year',
            plural: '# years'
        }
    };

    var timespans = [1000, 60000, 3600000, 86400000, 31540000000];
    var parsedTime = Date.parse(time.replace(/\-00:?00$/, ''));

    if (parsedTime && Date.now) {
        var timeAgo = parsedTime - Date.now();
        var diff = Math.abs(timeAgo);
        var postfix = lang.postfixes[(timeAgo < 0) ? '<' : '>'];
        var timespan = timespans[0];

        for (var i = 1; i < timespans.length; i++) {
            if (diff > timespans[i]) {
                timespan = timespans[i];
            }
        }

        var n = Math.round(diff / timespan);

        return lang[timespan][n > 1 ? 'plural' : 'singular']
            .replace('#', n) + postfix;
    }
}

Details.propTypes = {
    coin: PropTypes.string
};

const mapStateToProps = state => ({
    coin: state.items.coin
});

export default connect(mapStateToProps, {setChartCoin})(Details);