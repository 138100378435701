import React from "react";
import {Dropdown} from "react-bootstrap";
import storage from "../../../../Storage";
import coins from "../../../coins";
import socket from "../../../../Socket";
import {__, isMobile, encode, decode, getElement, wait, COIN_TOKEN} from "../../../../Helper";
import DepositArchive from "./DepositArchive";
import C from "../../../../Constant";
import Cookies from "js-cookie";
import { showSuccessToast } from "../../../../toastNotifications";

class Confirm extends React.Component {
    _isMounted = false;

    constructor(props) {
        super(props);
        this.state = {
            token: storage.getKey('token'),
            uid: Cookies.get('uid'),
            currentCoin: COIN_TOKEN,
            loading: true,
            list: [],
            content: [],
            wallet: 'Please Wait...',
            deposit: true,
            slide: false,
            height: 573,
            margin: 'mt-1',
            phone: storage.getKey('phone'),
            name: storage.getKey('name')
        };
    }

    componentDidMount() {
        this._isMounted = true;
        socket.emit(C.CREDIT, encode({token: this.state.token, coin: this.state.currentCoin}));
        socket.on(C.CREDIT, data => this.getCreditAndCoins(decode(data)));
        socket.on(C.UPDATE_CREDIT, data => this.getCreditAndCoins(decode(data)));
        socket.on(C.UPDATE_PAYMENT_STATUS, (data) => this.paymentUpdate(decode(data)));

        wait(400).then(() => {
            this.loadAddress(true, this.state.currentCoin);
        })
    }

    componentWillUnmount() {
        this._isMounted = false;
    }


    paymentUpdate(data) {
        if (this._isMounted) {
            let {token, uid} = this.state;

            if (uid !== null) {
                if (parseFloat(data.uid) === parseFloat(uid)) {
                    showSuccessToast(data.message)
                    this.setState({submitted: false});
                }
            }
            wait(100).then(() => {
                if (token !== null)
                    socket.emit(C.CREDIT, encode({token: token, coin: data.coin}));
            })
        }
    }

    getCreditAndCoins = (data) => {
        if (this._isMounted) {
            let {credit} = data;
            this.setState({credits: credit, loading: false});
            this.setUpCoins();
        }
    };

    setUpCoins = () => {
        if (isMobile()) {
            this.setState({height: 685, margin: 'mt-1'});
        }

        coins.forEach((item, key) => {
            let list = <Dropdown.Item as={'button'} className={"animated fadeIn"} onClick={(e) => this.loadAddress(item.active, item.preffix)}>
                <span><img src={"/assets/images/" + item.image} className={"img-fluid mini-coin mr-1"} alt="Coin"/> {item.preffix}</span>
            </Dropdown.Item>;
            this.setState(state => ({list: [list, ...state.list]}));
        });
    };

    showArchive = e => {
        this.setState({slide: !this.state.slide});
        wait(500).then(() => {
            this.setState({deposit: !this.state.deposit});
        })
        wait(500).then(() => {
            this.loadAddress(true, this.state.currentCoin);
        })
    };

    loadAddress(active, coin) {
        if (this._isMounted) {
            if (active)
                socket.emit(C.GET_ADDRESS, encode({coin: this.state.currentCoin, token: this.state.token}));
            else {
                getElement('#' + __.upperCase(coin)).value = `${coin} not Supported yet`;
            }
        }
    }

    submit = (e) => {
        e.preventDefault();

        socket.emit(C.CONFIRM, encode({
            phone: this.state.phone,
            mpesa_code: this.state.mpesa_code,
        }));

        wait(600).then(() => {
            this.setState({submitted: true});
        })
    }

    handleChangeConfirm = (e) => {
        let val = e.target.value;
        this.setState({mpesa_code: val});
    }

    render() {
        let {height, margin, loading} = this.state;
        let style;
        let size = 'w-25';

        if (isMobile()) {
            style = "col-2";
            size = 'w-50'
        }

        return (
            <>
                {
                    this.state.deposit ?
                        <div className={this.state.slide ? 'animated slideOutLeft ' : ' font-14'}>
                            {this.state.loading ?
                                <div className="ycenter text-center">
                                    <div className={"spinner-border text-orange"} role="status"/>
                                </div>
                                :
                                <>
                                    <div className={"row"}>
                                        <div className={"col-sm-12"}>
                                            <div className="form-group text-center">
                                                <p className="text-white text-left">
                                                    Enter M-PESA code e.g PLE14C7KCO
                                                </p>
                                                {
                                                    this.state.submitted ?
                                                        <span className={"text-white"}><div className={"spinner-border spinner-border-sm text-white"} role={"status"}/><br/>Please wait as we fetch your deposit...</span>
                                                        :
                                                        <form onSubmit={(e) => {this.submit(e)}}>
                                                            <input type={"hidden"} value={this.state.phone} name={"phone"}/>
                                                            <div className="form-group text-left">
                                                                <input type={"text"} name={'mpesa_code'} className={'form-control'} style={{background: "white", color: 'black'}} onChange={this.handleChangeConfirm} required={false} autoComplete={'off'}/>
                                                            </div>
                                                            <button type={"submit"} className={"btn btn-block bg-cs2"}>Verify Deposit</button>
                                                        </form>
                                                }
                                                <br/>
                                                <div onClick={this.showArchive} className={'text-record font-12'}>Previous Deposits</div>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                        :
                        <>
                            <div className={this.state.slide == false ? 'animated slideOutLeft' : ''}>
                                <DepositArchive clicked={this.showArchive}/>
                            </div>
                        </>
                }
            </>
        );
    }
}

export default Confirm;