import io from 'socket.io-client';
import {SOCKET, Event} from "./Helper";

/**
 * Socket URL
 */
const ws = SOCKET;

/**
 * Initialize Socket Connection
 */

const socket = io.connect(ws, {
    withCredentials: true,
    extraHeaders: {
        "my-custom-header": "abcd"
    }
});

socket.on('connect', () => {
    Event.emit('connect');
});

socket.on("reconnect", () => {
    //Event.emit("disconnect");
});

socket.on("connection", () => {
    //Event.emit("disconnect");
});

socket.on('disconnect', () => {
    Event.emit('disconnect');
});

export default socket;